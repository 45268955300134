import React from 'react';
import s from './Alert.module.scss'
import warning from './image/warning.png'
import classNames from 'classnames'

const Alert = ({ error, className }) => {
    return (
        <div className={classNames(s.main, className)}>
            <div className={s.content} >
                <div className={s.imageContainer}>
                    <img src={warning} alt="" />
                </div>
                <div className={s.textContainer} >
                    <p className={s.text}>
                        {error}
                    </p>
                </div>
            </div>
            <div className={s.bubble}>
            </div>
        </div>
    );
};


Alert.defaultProps = {
    error: ''
};

export default Alert;