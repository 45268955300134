import { AuthApi } from 'api';
import { envObject } from 'api/envSwithcer';

class EvApi extends AuthApi {
    baseUrl = envObject.react_app_ev_api_url;
    addTimeZone = false;

    getEV() {
        return this.get('ev/');
    }

    saveEV(body: any) {
        return this.post('ev/', body);
    }

    removeEv() {
        return this.delete('ev/');
    }
}

const evApi = new EvApi();
export default evApi;
