import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children, modalRoot }) => {
    const el = document.createElement('div');

    useEffect(() => {
        modalRoot.appendChild(el);
        return () => modalRoot.removeChild(el);
    }, [el, modalRoot]);

    return createPortal(children, el);
};
