import { useTreatments } from '@splitsoftware/splitio-react';

function useTreatment(itemsArray) {
    const treatments = useTreatments(itemsArray);
    return Object.keys(treatments).map((item) => {
        return treatments[item].treatment === 'on' ? true : false;
    });
}

export default useTreatment;
