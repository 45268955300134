import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import logoAlpha from 'assets/images/logo_alpha.png'
import s from './Logo.module.scss'
import {
    root
} from 'constants/routes'

const Logo = ({ className }) => {
    return (
        <Link className={classNames(s.main, className)} to={root} >
            <img className={s.img} src={logoAlpha} alt="" />
        </Link>
    )
}

export default Logo