import React from 'react'
import s from './Tab.module.scss'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export default function Tab({
    title,
    pathname,
    tab
}) {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParams = urlParams.get('tab')
    const isActiveTab = tab === tabParams

    return (
        <Link
            to={{
                pathname,
                search: `?tab=${tab}`,
            }}
            className={classNames(s.main, { [s.active]: isActiveTab })}
        >
            <span>
                {title}
            </span>
        </Link >
    )
}
