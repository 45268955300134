import React from 'react';
import { Link } from 'react-router-dom'
import s from './AddButton.module.scss'
import plus from 'assets/images/plus.svg'
import { useTranslation } from 'react-i18next';

const AddButton = ({
    to,
    onClick
}) => {
    const { t } = useTranslation("misc")
    return (
        <Link className={s.main} to={to} onClick={() => onClick && onClick()} >
            <img src={plus} alt="add" />
            <span>
                {t('add', "Add")}
            </span>
        </Link>
    );
};

export default AddButton;