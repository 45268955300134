import React, { useState } from 'react';
import classNames from 'classnames';
import s from './DeleteChargerModal.module.scss';
import { Button, Checkbox, Input } from 'components/atoms';
import { WarningMessage } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import PortingChargerModal from './PortingChargerModal';

export const otherID = '4';

export default function DeleteChargerModal({
    hide,
    id,
    onDeleteCharger,
    chargerName
}) {
    const [isConfirm, setIsConfirm] = useState(false);

    const { t } = useTranslation('profile');
    const radioArrays = [
        {
            id: '0',
            title: t('radio.1', 'I no longer own an EV')
        },

        {
            id: '1',
            title: t('radio.2', 'I did not save money')
        },
        {
            id: '2',
            title: t('radio.3', 'Technical faults')
        },
        {
            id: '3',
            title: t('radio.4', 'Missing features')
        },
        {
            id: '4',
            title: t('radio.5', 'Other')
        }
    ];
    const formSchema = yup.object().shape(
        {
            reason: yup
                .string()
                .required(t('pleaseSelectReason', 'Select an option')),
            comments: yup
                .string()
                .test(
                    'comment-test',
                    t('pleaseWriteReason', 'Please write reason'),
                    function (value) {
                        const { reason } = this.parent;
                        return reason !== otherID || value?.length > 0;
                    }
                )
        },
        ['reason', 'comments']
    );

    const formik = useFormik({
        initialValues: {
            reason: '',
            comments: ''
        },
        onSubmit: (data) => {
            Modal.create(PortingChargerModal, { chargerName });
            onDeleteCharger({ id, hide, radioArrays, ...data });
        },
        validationSchema: formSchema,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false
    });

    const {
        errors,
        setFieldValue,
        values,
        handleChange,
        handleSubmit,
        submitForm,
        validateForm
    } = formik;
    const errorsArray = Object.keys(errors);

    const onChange = (e) => {
        setFieldValue('reason', e.target.value);
    };

    const handleSubmitButton = async () => {
        const validObject = await validateForm();
        if (!Object.keys(validObject).length) {
            isConfirm ? submitForm() : setIsConfirm(true);
        }
    };

    const handleCancel = () => {
        hide();
    };

    return (
        <div className={classNames(s.backGround, {})}>
            <div className={s.content}>
                <form className={s.container} onSubmit={handleSubmit}>
                    <section className={s.section}>
                        <header className={s.header}>
                            <h3>
                                {t('portChargerBack', 'Port charger back?')}
                            </h3>
                        </header>
                        <div>
                            {isConfirm ? (
                                <div
                                    className={classNames(
                                        s.textContainer,
                                        s.textContainerConfirm
                                    )}
                                >
                                    <p
                                        className={classNames(
                                            s.text,
                                            s.confirmText
                                        )}
                                    >
                                        {t(
                                            'byConfirmingYourCharger',
                                            "By confirming your charger will be ported back to the manufacturer's app. All future charging will be controlled by their app."
                                        )}
                                    </p>
                                </div>
                            ) : (
                                <div className={s.textContainer}>
                                    <p className={s.text}>
                                        {t(
                                            'weAreSad',
                                            'We’re sad to see you leave charging on Electric Miles.'
                                        )}
                                        <span role="img" aria-label="">
                                            😥
                                        </span>
                                        <span className={s.br} />

                                        {t(
                                            'letUsKnowWhyBefore',
                                            'Let us know why before we reset your charger back to factory settings:*'
                                        )}
                                    </p>
                                </div>
                            )}
                            <div className={isConfirm ? s.hidden : ''}>
                                <div>
                                    <div className={s.alertContainer}>
                                        <div className={s.wrapper}>
                                            {!!errors[errorsArray[0]] && (
                                                <WarningMessage
                                                    className={s.warning}
                                                    error={
                                                        errors[errorsArray[0]]
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {radioArrays.map((item) => {
                                        return (
                                            <label
                                                className={s.radio}
                                                htmlFor={item}
                                                key={item.id}
                                            >
                                                <Checkbox
                                                    onChange={onChange}
                                                    checked={
                                                        values.reason ===
                                                        item.id
                                                    }
                                                    value={item.id}
                                                    type="radio"
                                                    name={'reason'}
                                                    id={item.id}
                                                />
                                                <p className={s.text}>
                                                    {item.title}
                                                </p>
                                            </label>
                                        );
                                    })}
                                </div>
                                <Input
                                    type="text"
                                    placeholder={t(
                                        'tellUsAnything',
                                        'Tell us anything else'
                                    )}
                                    className={
                                        values.reason === otherID
                                            ? ''
                                            : s.hidden
                                    }
                                    name="comments"
                                    value={values.comments}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <footer className={s.footer}>
                            <Button
                                outline
                                type="button"
                                className={s.submitButton}
                                onClick={handleCancel}
                            >
                                {t('cancel', 'Cancel')}
                            </Button>
                            <Button
                                type="button"
                                className={s.submitButton}
                                onClick={handleSubmitButton}
                            >
                                {isConfirm
                                    ? t('confirm', 'Confirm')
                                    : t('submit', 'Submit')}
                            </Button>
                        </footer>
                    </section>
                </form>
            </div>
        </div>
    );
}
