export const supportKilometers = 'km';
export const supportEuro = 'euro';
export const demoValues = 'demoValues';
export const earlyAccess = 'earlyAccess';
export const automatedCharging = 'automated-charging';
export const evApi = 'evApi';
export const exportHistory = 'exportHistory';
export const dailyMilesOption = 'dailyMiles';
export const historyGraph = 'historyGraph';
export const costSavings = 'historyCostSavings';
export const maximumCharge = 'maximumCharge';
export const chargeReserve = 'chargeReserve';
export const syncEmailAccount = 'sync-email-account';
export const sliderOnboarding = 'sliderOnboarding';
export const homeChargeNowV2 = 'homeChargeNowV2';
export const appTour = 'appTour';
