import { AuthApi } from 'api'

class SocialAttachApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}social-network/${path}`;
    }

    attachGoogle(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('attach/google?' + urlParams)
    }

    detachGoogle() {
        return this.delete('detach/google')
    }

    detachOutlook() {
        return this.delete('detach/outlook')
    }

    attachOutlook(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('attach/outlook?' + urlParams)
    }
}

const socialAttachApi = new SocialAttachApi()
export default socialAttachApi;