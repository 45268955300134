export const envkeys = {
    test:{
        label:"Test",
        value:"TEST"
    },
    prod:{
        label:"Prod",
        value:"PROD"
    },
    stageOne:{
        label:"Stage 1",
        value:"STAGE_ONE"
    },
    stageTwo:{
        label:"Stage 2",
        value:"STAGE_TWO"
    },
    stageThree:{
        label:"Stage 3",
        value:"STAGE_THREE"
    }
}
class envClass {
    react_app_api_url = '';
    react_app_split_api_key = '';
    react_app_userpool_id = '';
    react_app_cognito_client_id = '';
    react_app_mixpanel_token = '';
    react_app_ev_api_app_id = '';
    react_app_ev_api_url = '';
    react_app_environment = process.env.react_app_environment || '';
    react_app_google_api_key = process.env.react_app_google_api_key || '';
    react_app_version = process.env.react_app_version || '';
    react_app_aws_region = process.env.react_app_aws_region || '';
    react_app_mixpanel_url = process.env.react_app_mixpanel_url || '';
    react_app_sentry_dsn = process.env.react_app_sentry_dsn || '';
    react_app_firebase_api_key = process.env.react_app_firebase_api_key_prod || '';
    react_app_firebase_auth_domain =  process.env.react_app_firebase_auth_domain_prod || '';
    react_app_firebase_project_id = process.env.react_app_firebase_project_id_prod || '';
    react_app_firebase_storage_bucket = process.env.react_app_firebase_project_id_prod || '';
    react_app_firebase_messaging_senderId = process.env.react_app_firebase_storage_bucket_prod || '';
    react_app_firebase_app_id = process.env.react_app_firebase_app_id_prod || '';
    react_app_firebase_measurement_id = process.env.react_app_firebase_measurement_id_prod || '';
    react_app_firebase_app_version_key = process.env.react_app_firebase_app_version_key_prod || '';

    constructor() {
        const data = localStorage.getItem('ENV_VARIABLE') || '';
        if (!data) {
            localStorage.setItem(
                'ENV_VARIABLE',
                JSON.stringify({
                    react_app_api_url: process.env.react_app_api_url_prod || '',
                    react_app_split_api_key:
                        process.env.react_app_split_api_key_prod || '',
                    react_app_userpool_id:
                        process.env.react_app_userpool_id_prod || '',
                    react_app_cognito_client_id:
                        process.env.react_app_cognito_client_id_prod || '',
                    react_app_mixpanel_token:
                        process.env.react_app_mixpanel_token_prod || '',
                    react_app_ev_api_app_id:
                        process.env.react_app_ev_api_app_id_prod || '',
                    react_app_ev_api_url:
                        process.env.react_app_ev_api_url_prod || '',
                    react_app_environment:
                        process.env.react_app_environment || '',
                    react_app_firebase_api_key:
                        process.env.react_app_firebase_api_key_prod || '',
                    react_app_firebase_auth_domain:
                        process.env.react_app_firebase_auth_domain_prod || '',
                    react_app_firebase_project_id:
                        process.env.react_app_firebase_project_id_prod || '',
                    react_app_firebase_storage_bucket:
                        process.env.react_app_firebase_storage_bucket_prod ||
                        '',
                    react_app_firebase_messaging_senderId:
                        process.env
                            .react_app_firebase_messaging_senderId_prod || '',
                    react_app_firebase_app_id:
                        process.env.react_app_firebase_app_id_prod || '',
                    react_app_firebase_measurement_id:
                        process.env.react_app_firebase_measurement_id_prod || '',
                        react_app_firebase_app_version_key:
                        process.env.react_app_firebase_app_version_key_prod || ''
                })
            );
            this.setProdKeys();
        } else {
            const keys = JSON.parse(data);
            this.react_app_api_url = keys.react_app_api_url;
            this.react_app_split_api_key = keys.react_app_split_api_key;
            this.react_app_userpool_id = keys.react_app_userpool_id;
            this.react_app_cognito_client_id = keys.react_app_cognito_client_id;
            this.react_app_mixpanel_token = keys.react_app_mixpanel_token;
            this.react_app_ev_api_app_id = keys.react_app_ev_api_app_id;
            this.react_app_ev_api_url = keys.react_app_ev_api_url;
            this.react_app_environment = keys.react_app_environment;
            this.react_app_firebase_api_key = keys.react_app_firebase_api_key;
            this.react_app_firebase_auth_domain =
                keys.react_app_firebase_auth_domain;
            this.react_app_firebase_project_id =
                keys.react_app_firebase_project_id || "electric-miles-cab06";
            this.react_app_firebase_storage_bucket =
                keys.react_app_firebase_storage_bucket || "electric-miles-cab06.appspot.com";
            this.react_app_firebase_messaging_senderId =
                keys.react_app_firebase_messaging_senderId || "627904776661";
            this.react_app_firebase_app_id = keys.react_app_firebase_app_id || "1:627904776661:web:d1e265bf7369d21535d55c";
            this.react_app_firebase_measurement_id =
                keys.react_app_firebase_measurement_id || "G-METH5N6VGX";
            this.react_app_firebase_app_version_key =
                keys.react_app_firebase_app_version_key || "prod_app_version";
        }
    }

    defaultKeys() {
        localStorage.setItem(
            'ENV_VARIABLE',
            JSON.stringify({
                react_app_api_url: this.react_app_api_url,
                react_app_split_api_key: this.react_app_split_api_key,
                react_app_userpool_id: this.react_app_userpool_id,
                react_app_cognito_client_id: this.react_app_cognito_client_id,
                react_app_mixpanel_token: this.react_app_mixpanel_token,
                react_app_ev_api_app_id: this.react_app_ev_api_app_id,
                react_app_ev_api_url: this.react_app_ev_api_url,
                react_app_environment: this.react_app_environment,
                react_app_firebase_api_key: this.react_app_firebase_api_key,
                react_app_firebase_auth_domain:
                    this.react_app_firebase_auth_domain,
                react_app_firebase_project_id:
                    this.react_app_firebase_project_id,
                react_app_firebase_storage_bucket:
                    this.react_app_firebase_storage_bucket,
                react_app_firebase_messaging_senderId:
                    process.env.react_app_firebase_messaging_senderId_prod,
                react_app_firebase_app_id: this.react_app_firebase_app_id,
                react_app_firebase_measurement_id:
                    this.react_app_firebase_measurement_id,
                react_app_firebase_app_version_key:
                    this.react_app_firebase_app_version_key
            })
        );
    }

    changeKeys(envString: string) {
        localStorage.removeItem('ENV_VARIABLE');
        switch (envString) {
            case envkeys.prod.value:
                this.setProdKeys();
                break;
            case envkeys.test.value:
                this.setTestKeys();
                break;
            case envkeys.stageOne.value:
                this.setStageOneKeys();
                break;
            case envkeys.stageTwo.value:
                this.setStageTwoKeys();
                break;
            case envkeys.stageThree.value:
                this.setStageThreeKeys();
                break;
            default:
                this.setProdKeys();
                break;
        }
        this.defaultKeys();
    }

    setProdKeys() {
        this.react_app_api_url = process.env.react_app_api_url_prod || '';
        this.react_app_split_api_key =
            process.env.react_app_split_api_key_prod || '';
        this.react_app_userpool_id =
            process.env.react_app_userpool_id_prod || '';
        this.react_app_cognito_client_id =
            process.env.react_app_cognito_client_id_prod || '';
        this.react_app_mixpanel_token =
            process.env.react_app_mixpanel_token_prod || '';
        this.react_app_ev_api_app_id =
            process.env.react_app_ev_api_app_id_prod || '';
        this.react_app_ev_api_url = process.env.react_app_ev_api_url_prod || '';
        this.react_app_environment =  envkeys.prod.value;
        this.react_app_firebase_api_key =
            process.env.react_app_firebase_api_key_prod || '';
        this.react_app_firebase_auth_domain =
            process.env.react_app_firebase_auth_domain_prod || '';
        this.react_app_firebase_project_id =
            process.env.react_app_firebase_project_id_prod || '';
        this.react_app_firebase_storage_bucket =
            process.env.react_app_firebase_storage_bucket_prod || '';
        this.react_app_firebase_messaging_senderId =
            process.env.react_app_firebase_messaging_senderId_prod || '';
        this.react_app_firebase_app_id =
            process.env.react_app_firebase_app_id_prod || '';
        this.react_app_firebase_measurement_id =
            process.env.react_app_firebase_measurement_id_prod || '';
        this.react_app_firebase_app_version_key =
            process.env.react_app_firebase_app_version_key_prod || '';
    }

    setTestKeys() {
        this.react_app_api_url = process.env.react_app_api_url_test || '';
        this.react_app_split_api_key =
            process.env.react_app_split_api_key_test || '';
        this.react_app_userpool_id =
            process.env.react_app_userpool_id_test || '';
        this.react_app_cognito_client_id =
            process.env.react_app_cognito_client_id_test || '';
        this.react_app_mixpanel_token =
            process.env.react_app_mixpanel_token_test || '';
        this.react_app_ev_api_app_id =
            process.env.react_app_ev_api_app_id_test || '';
        this.react_app_ev_api_url = process.env.react_app_ev_api_url_test || '';
        this.react_app_environment =  envkeys.test.value;
        this.react_app_firebase_api_key =
            process.env.react_app_firebase_api_key_test || '';
        this.react_app_firebase_auth_domain =
            process.env.react_app_firebase_auth_domain_test || '';
        this.react_app_firebase_project_id =
            process.env.react_app_firebase_project_id_test || '';
        this.react_app_firebase_storage_bucket =
            process.env.react_app_firebase_storage_bucket_test || '';
        this.react_app_firebase_messaging_senderId =
            process.env.react_app_firebase_messaging_senderId_test || '';
        this.react_app_firebase_app_id =
            process.env.react_app_firebase_app_id_test || '';
        this.react_app_firebase_app_version_key =
            process.env.react_app_firebase_app_version_key_test || '';
    }

    setStageOneKeys() {
        this.react_app_api_url = process.env.react_app_api_url_stage_one || '';
        this.react_app_split_api_key =
            process.env.react_app_split_api_key_stage_one || '';
        this.react_app_userpool_id =
            process.env.react_app_userpool_id_stage_one || '';
        this.react_app_cognito_client_id =
            process.env.react_app_cognito_client_id_stage_one || '';
        this.react_app_mixpanel_token =
            process.env.react_app_mixpanel_token_stage_one || '';
        this.react_app_ev_api_app_id =
            process.env.react_app_ev_api_app_id_stage_one || '';
        this.react_app_ev_api_url =
            process.env.react_app_ev_api_url_stage_one || '';
        this.react_app_environment = envkeys.stageOne.value;
        this.react_app_firebase_api_key =
            process.env.react_app_firebase_api_key_test || '';
        this.react_app_firebase_auth_domain =
            process.env.react_app_firebase_auth_domain_test || '';
        this.react_app_firebase_project_id =
            process.env.react_app_firebase_project_id_test || '';
        this.react_app_firebase_storage_bucket =
            process.env.react_app_firebase_storage_bucket_test || '';
        this.react_app_firebase_messaging_senderId =
            process.env.react_app_firebase_messaging_senderId_test || '';
        this.react_app_firebase_app_id =
            process.env.react_app_firebase_app_id_test || '';
        this.react_app_firebase_measurement_id =
            process.env.react_app_firebase_measurement_id_test || '';
        this.react_app_firebase_app_version_key =
            process.env.react_app_firebase_app_version_key_test || '';
    }
    setStageTwoKeys() {
        this.react_app_api_url = process.env.react_app_api_url_stage_two || '';
        this.react_app_split_api_key =
            process.env.react_app_split_api_key_stage_two || '';
        this.react_app_userpool_id =
            process.env.react_app_userpool_id_stage_two || '';
        this.react_app_cognito_client_id =
            process.env.react_app_cognito_client_id_stage_two || '';
        this.react_app_mixpanel_token =
            process.env.react_app_mixpanel_token_stage_two || '';
        this.react_app_ev_api_app_id =
            process.env.react_app_ev_api_app_id_stage_two || '';
        this.react_app_ev_api_url =
            process.env.react_app_ev_api_url_stage_two || '';
        this.react_app_environment = envkeys.stageTwo.value;
        this.react_app_firebase_api_key =
            process.env.react_app_firebase_api_key_test || '';
        this.react_app_firebase_auth_domain =
            process.env.react_app_firebase_auth_domain_test || '';
        this.react_app_firebase_project_id =
            process.env.react_app_firebase_project_id_test || '';
        this.react_app_firebase_storage_bucket =
            process.env.react_app_firebase_storage_bucket_test || '';
        this.react_app_firebase_messaging_senderId =
            process.env.react_app_firebase_messaging_senderId_test || '';
        this.react_app_firebase_app_id =
            process.env.react_app_firebase_app_id_test || '';
        this.react_app_firebase_measurement_id =
            process.env.react_app_firebase_measurement_id_test || '';
        this.react_app_firebase_app_version_key =
            process.env.react_app_firebase_app_version_key_test || '';
    }
    setStageThreeKeys() {
        this.react_app_api_url =
            process.env.react_app_api_url_stage_three || '';
        this.react_app_split_api_key =
            process.env.react_app_split_api_key_stage_three || '';
        this.react_app_userpool_id =
            process.env.react_app_userpool_id_stage_three || '';
        this.react_app_cognito_client_id =
            process.env.react_app_cognito_client_id_stage_three || '';
        this.react_app_mixpanel_token =
            process.env.react_app_mixpanel_token_stage_three || '';
        this.react_app_ev_api_app_id =
            process.env.react_app_ev_api_app_id_stage_three || '';
        this.react_app_ev_api_url =
            process.env.react_app_ev_api_url_stage_three || '';
        this.react_app_environment = envkeys.stageThree.value;
        this.react_app_firebase_api_key =
            process.env.react_app_firebase_api_key_test || '';
        this.react_app_firebase_auth_domain =
            process.env.react_app_firebase_auth_domain_test || '';
        this.react_app_firebase_project_id =
            process.env.react_app_firebase_project_id_test || '';
        this.react_app_firebase_storage_bucket =
            process.env.react_app_firebase_storage_bucket_test || '';
        this.react_app_firebase_messaging_senderId =
            process.env.react_app_firebase_messaging_senderId_test || '';
        this.react_app_firebase_app_id =
            process.env.react_app_firebase_app_id_test || '';
        this.react_app_firebase_measurement_id =
            process.env.react_app_firebase_measurement_id_test || '';
        this.react_app_firebase_app_version_key =
            process.env.react_app_firebase_app_version_key_test || '';
    }
}
export const envObject = new envClass();
