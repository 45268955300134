import React, { useRef } from 'react';
import { OverlayView } from '@react-google-maps/api';

import s from './InformPopup.module.scss';
import { useOutside } from 'hooks';
const InformPopup = ({ info, setInfo }) => {
    const informRef = useRef(null);

    useOutside(informRef, () => {
        setInfo(null);
    });

    if (!info) return null;

    const { title, descriptionTitle, description, saddr, daddr } = info;


    return (
        <OverlayView
            position={daddr}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div ref={informRef} className={s.popupContainer}>
                <div className={s.popupTitle}>
                    <h3>{title}</h3>
                </div>
                <div className={s.popupDescription}>
                    <div className={s.title}>
                        {descriptionTitle && <h3>{descriptionTitle}</h3>}
                    </div>
                    <div className={s.text}>
                        {saddr ? (
                            <a
                                href={`http://maps.google.com/maps?saddr=${saddr?.lat},${saddr?.lng}&daddr=${daddr?.lat},${daddr?.lng}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {description}
                            </a>
                        ) : (
                            description.trim() && <p>{description}</p>
                        )}
                    </div>
                </div>
            </div>
        </OverlayView>
    );
};

export default InformPopup;
