import { makeAutoObservable } from 'mobx';
import { RequestLoadingStore, NotificationStore } from 'components';
import EventEmitter from 'utils/EventEmitter';
import { evStationApi } from 'api';
const getControlCenterRequest = (data) => {
    return {
        faults: 8,
        activeCharging: 0,
        chargingSession: 0,
        totalEnergy: 4830,
        totalCharging: 15.6,
        newUsers: 0,
        newCharge: 0,
        chargePoint: {
            value: [
                {
                    name: 'Personal EV',
                    y: 2400,
                    color: '#11AF3C',
                    interest: 60
                },
                { name: 'Commercial', y: 1200, color: '#1DD6A8', interest: 30 },
                {
                    name: 'Fleet Operators',
                    y: 400,
                    color: '#98F5B3',
                    interest: 30
                }
            ],
            total: 4000
        },
        locationCard: {
            value: [
                { name: 'London', y: 1462, color: '#082B63', interest: 50 },
                { name: 'Birmingham', y: 585, color: '#186CC9', interest: 20 },
                { name: 'Cambridge', y: 439, color: '#2087F1', interest: 15 },
                { name: 'Liverpool', y: 146, color: '#57A6FF', interest: 5 }
            ],
            total: 2929
        }
    };
};

class StationStore {
    metadata;
    data;
    loading;
    constructor() {
        this.reset();
        makeAutoObservable(this);
        EventEmitter.addEventListener('logOut', this.reset);
    }

    getControlCenter = async () => {
        RequestLoadingStore.showLoading();
        try {
            this.data = getControlCenterRequest();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    getStationList = async (payload) => {
        RequestLoadingStore.showLoading();
        try {
            const { data } = await evStationApi.list(
                payload.offset,
                payload.limit
            );
            this.data.stationList = data.items;
            this.metadata = data.metadata;
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    reset = () => {
        this.loading = false;
        this.metadata = {
            offset: 0,
            limit: 20,
            total: 1
        };
        this.data = {
            stationList: [],
            faults: 0,
            activeCharging: 0,
            chargingSession: 0,
            totalEnergy: 0,
            totalCharging: 0,
            newUsers: 0,
            newCharge: 0,
            chargePoint: {
                value: [],
                total: 0
            },
            locationCard: {
                value: [],
                total: 0
            }
        };
    };
}

export default new StationStore();
