import { Api } from 'api';
import { Auth } from 'aws-amplify';
import IonicStorage from 'storage';

class CognitoApi extends Api {
    async signIn(username: string, password: string) {
        try {
            return await Auth.signIn(username, password);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async signUp(data: { username: string; password: string }) {
        try {
            return await Auth.signUp(data);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async signOut() {
        try {
            await IonicStorage.clear();
            await Auth.signOut();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async resendConfirmationCode(username: string) {
        try {
            await Auth.resendSignUp(username);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async confirmSignUp(username: string, code: string) {
        try {
            return await Auth.confirmSignUp(username, code);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async changePassword(oldPassword: string, newPassword: string) {
        try {
            return await Auth.currentAuthenticatedUser().then((user: any) => {
                return Auth.changePassword(user, oldPassword, newPassword);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async newPasswordRequired(user: any, newPassword: string) {
        try {
            return await Auth.completeNewPassword(user, newPassword);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async forgotPassword(userName: string) {
        try {
            return await Auth.forgotPassword(userName);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async forgotPasswordSubmit(
        userName: string,
        code: string,
        newPassword: string
    ) {
        try {
            return await Auth.forgotPasswordSubmit(userName, code, newPassword);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updateName(
        name: string,
        familyName: string
    ) {
        try {
            return await Auth.currentAuthenticatedUser().then((user: any) => {
                return Auth.updateUserAttributes(user, {
                    'name' : name,
                    'family_name' : familyName
                });
            });
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    async getAttributes() {
        await IonicStorage.putOnLocalStorage();
        try {
            return await Auth.currentAuthenticatedUser().then((user: any) => {
                const { attributes } = user;
                return attributes;
            });
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }
}

const cognitoApi = new CognitoApi();
export default cognitoApi;
