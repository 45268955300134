import { Drivers, Storage } from '@ionic/storage';

class IonicStorageClass {
    private _storage: Storage | null = null;

    constructor() {
        this.init();
    }
    async init() {
        const storageConfig = new Storage({
            name: '__electricmilesDB',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        });
        this._storage = await storageConfig.create();
    }

    async setItem(key: string, value: string) {
        await this._storage?.set(key, value);
    }
    async clear() {
        await this._storage?.clear();
    }
    async putOnLocalStorage() {
        if(!this._storage) await this.init();
        await this._storage?.forEach((value, key) => {
            localStorage.setItem(key, value);
        });
    }
}
const IonicStorage = new IonicStorageClass();
export default IonicStorage;
