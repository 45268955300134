/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import moment from 'moment'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import s from './DateRangeInput.module.scss'
import calendarIcon from './image/calendarIcon.png'
import { Button } from 'components'
import { ReactComponent as Corner } from './image/corner.svg'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: { main: '#1e3466' },
    },

    typography: {
        fontFamily: [
            'Proxima',
        ].join(','),
    },
});


const DateRangeInput = ({
    setDate,
    placeholder,
    onBlur,
    position,
    withoutTime,
    initialValue,
    disabled,
    setEmpty,
    numberOfCalendars

}) => {


    const [startTime, setStartTime] = useState(initialValue || null)
    const {t} = useTranslation("misc");
    useEffect(() => {
        setEmpty(setStartTime)
    }, [])

    const [isActive, setIsActive] = useState(false)
    const [state, setState] = useState({ dates: null });

    const onSelect = dates => {
        const time = new Date()
        let dateWithTime = dates;
        if (new Date(dates).toDateString() === time.toDateString()) {
            dateWithTime = moment(dates).set({
                hour: time.getHours(),
                minute: time.getMinutes() + 5,
            })
        }

        setStartTime(dateWithTime)
        setState({ dates: dateWithTime })
    }
    const activeToggle = () => {
        if (disabled) return;
        setIsActive(state => !state)
    }

    const apply = () => {
        setDate(startTime)
        setIsActive(false)
        // onBlur()
    }


    const handleStartTime = (value) => {
        setStartTime(value)
    }


    function disablePrevDates(startDate) {
        const startSeconds = Date.parse(startDate);
        return (date) => {
            return Date.parse(date) < startSeconds;
        }
    }

    const startDate = new Date();


    return (
        <div className={s.main} onBlur={onBlur}>
            <div type="button" className={s.input} onClick={activeToggle} >
                {startTime ? <span className={s.value} > {startTime && !isActive && moment(startTime).format(withoutTime ? 'ddd, DD / MM / YYYY' : 'ddd, DD / MM / YYYY - HH:mm')}</span> : <span className={s.placeholder} > {placeholder}</span>}
                <div className={s.image} >
                    {!withoutTime && <img src={calendarIcon} alt="" />}
                </div>
            </div>
            <div className={classNames(s.datePiker, {
                [s.activePiker]: isActive,
                [s.topPosition]: position === 'top'
            })} >
                <div className={s.corner}>
                    <Corner />
                </div>
                <DateRangePicker
                    onSelect={onSelect}
                    value={state.dates}
                    numberOfCalendars={numberOfCalendars}
                    className="customPikerStyles"
                    selectionType='single'
                    minimumDate={new Date()}
                />
                {state.dates && !withoutTime && < div className={s.timeContainer}>
                    <div className={s.timeTitle}>
                        <span>Set start trip time</span>
                    </div>
                    <div className={s.timersContainer}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <div className={s.pickerContainer} >
                                    <TimePicker
                                        value={startTime}
                                        onChange={handleStartTime}
                                        InputProps={{
                                            style: {
                                                textAlign: 'center',
                                                color: "#1e3466",
                                            }
                                        }}
                                        disablePast
                                        shouldDisableDate={disablePrevDates(startDate)}
                                    />
                                </div>
                            </ThemeProvider >
                        </MuiPickersUtilsProvider>
                    </div>
                </div>}
                <div className={s.buttonContainer} >
                    <Button type="button" onClick={apply}>
                        {t("apply", "Apply")}
                    </Button>
                </div>
            </div>
        </div >
    );
};

DateRangeInput.defaultProps = {
    setDate: () => { },
    onBlur: () => { },
    setEmpty: () => { },
    numberOfCalendars: 2
};

export default DateRangeInput;