/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

export default function useEventListener(el, event, cb) {
    useEffect(() => {
        if (el) {
            el.addEventListener(event, cb)
        }
        if (el) {
            return () => el.removeEventListener(event, cb)
        }
    }, [el])
}