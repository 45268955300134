import { AuthApi } from 'api';

class StationsApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}stations${path}`;
    }

    brandList() {
        return this.get('/brands');
    }

    modelList(brandId: string | number) {
        return this.get(`/models/${brandId}`);
    }
    typeList() {
        return this.get('/connectors/types');
    }

    add(body: object) {
        return this.post('', body);
    }

    remove(id: string | number, reason: string) {
        const urlParams = new URLSearchParams();
        reason && urlParams.set('reason', reason);
        return this.delete(`/${id}?` + urlParams);
    }

    connectorStatus(id: string | number) {
        return this.get(`/connectors/${id}/status`);
    }

    heartbeat() {
        return this.get('/heartbeat');
    }

    future(body: object) {
        return this.post('/future', body);
    }
}

const stationsApi = new StationsApi();
export default stationsApi;
