import { makeAutoObservable } from 'mobx';
import { RequestLoadingStore, NotificationStore } from 'components';
import { securityApi } from 'api';
import { myHistory } from 'utils';
import { waitingList } from 'constants/routes';
import EventEmitter from 'utils/EventEmitter';

class EarlyAccessStore {
    number;

    constructor() {
        this.reset();
        makeAutoObservable(this);
        EventEmitter.addEventListener('logOut', this.reset);
    }

    getEarlyAccess = async (email) => {
        RequestLoadingStore.showLoading();
        try {
            const { data } = await securityApi.earlyAccess(email);
            this.number = data.orderNumber;
            myHistory.history.push({
                pathname: waitingList
            });
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    reset = () => {
        this.number = 0;
    };
}

export default new EarlyAccessStore();
