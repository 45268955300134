import React from 'react'
import { Transition } from 'react-transition-group';

const duration = 500;

const defaultStyle = {
    transition: `all ${duration}ms`,
    transform: "translate3d(0px, 30%, 0px)",
    opacity: 0
}

const transitionStyles = {
    entering: {
        opacity: 1,
        transform: "none"
    },
    entered: {
        opacity: 1,
        transform: "none"
    },
    exiting: {
        opacity: 0,
        transform: "translate3d(0px, 30%, 0px)",
    },
    exited: {
        opacity: 0,
        transform: "translate3d(0px, 30%, 0px)",
    },
}


export default function index({
    start,
    children
}) {

    return (
        <Transition in={start} timeout={duration}>
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    {start ? children : null}
                </div>)
            }
        </Transition>
    )
}
