import React from 'react';
import { OverlayView } from '@react-google-maps/api'

import s from './CurrentPosition.module.scss'

const CurrentPosition = ({
    position,
    onLoad,
    onUnmount
}) => {

    return (
        position && <OverlayView
            position={position}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div className={s.positionContainer} >
                <div className={s.yourPosition} />
            </div>
        </OverlayView>
    );
};

export default CurrentPosition;