import React from 'react';
import s from './PersonalDetails.module.scss';
import PersonalDetailsForm from './PersonalDetailsForm';
import { useTranslation } from 'react-i18next';

const PersonalDetailsFormContainer = ({ wizard }) => {
    const { next } = wizard;
    const { t } = useTranslation('signup');

    return (
        <div className={s.main}>
            <h1 className={s.contentTitle}>
                {t('aBitAbout', 'Tell us about yourself')}
            </h1>
            <p className={s.text}>
                {t('provideName', 'Please provide your name and surname')}
            </p>            
            <PersonalDetailsForm next={next} />
        </div>
    );
};

export default PersonalDetailsFormContainer;
