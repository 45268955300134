import { Api } from 'api';

class SecurityApi extends Api {
    makeUrl(path: string) {
        return `${this.baseUrl}security/${path}`;
    }

    reset(body: object) {
        return this.post('reset', body);
    }

    register(body: object) {
        return this.post('register', body);
    }

    carbon() {
        return this.get('carbon');
    }

    valid(email: string) {
        return this.post('valid', { email });
    }

    resend(email: string) {
        return this.post('resend', { email });
    }

    emailVerification(token: string) {
        return this.get(`email-verification/${token}`);
    }

    resetConfirmation(body: object) {
        return this.post('reset-confirmation', body);
    }

    earlyAccess(email: string) {
        return this.post('early_access', { email });
    }
}

const securityApi = new SecurityApi();
export default securityApi;
