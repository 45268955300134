import React from 'react';
import classNames from 'classnames';
import s from './CircleProgress.module.scss';
import variables from 'assets/styles/variables.module.scss';

const CircleProgress = ({
    max,
    current,
    bgColor,
    circleColor,
    progressColor,
    children,
    className
}) => {
    const currentState = (current * 100) / max;
    return (
        <div
            className={classNames(s.main, className)}
            style={{
                '--bgColor': bgColor,
                '--circleColor': circleColor,
                '--progressColor': progressColor
            }}
        >
            <div className={s.current}>{children}</div>
            <div className={s.circleColor} />
            <div
                className={s.left}
                style={{ width: currentState < 50 ? '0' : 'calc(50% + 1px)' }}
            >
                <div
                    style={{
                        transform: `rotate(${
                            currentState < 50
                                ? 180
                                : ((((currentState - 50) * 100) / 50) * 180) /
                                      100 +
                                  180
                        }deg)`
                    }}
                />
            </div>
            <div className={s.right}>
                <div
                    style={{
                        transform: `rotate(${
                            currentState >= 50
                                ? 360
                                : (((currentState * 100) / 50) * 180) / 100 +
                                  180
                        }deg)`
                    }}
                />
            </div>
        </div>
    );
};

CircleProgress.defaultProps = {
    max: 100,
    current: 0,
    bgColor: variables.neutralLight,
    circleColor: '#c3e3ce',
    progressColor: '#4faf3e'
};

export default CircleProgress;
