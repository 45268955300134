/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import s from './Table.module.scss'
import arrow from './image/arrow.png'
import { Checkbox } from 'components'
import {
    useTable,
    useSortBy,
    useRowSelect
} from 'react-table'
import throttle from 'lodash.throttle'


const getGridTemplateColumns = (array) => {
    const gridTemplateColumns = []
    for (let index = 0; index < array.length; index++) {
        if (array && array[index].gridTemplateColumns) {
            gridTemplateColumns.push(array[index].gridTemplateColumns)
        } else {
            gridTemplateColumns.push("1fr")
        }
    }
    return gridTemplateColumns.join(' ')
}

const getGridTemplateForColumn = (array) => {
    const gridTemplateColumns = []
    for (let index = 0; index < array.length; index++) {
        if (array && array[index].column.gridTemplateColumns) {
            gridTemplateColumns.push(array[index].column.gridTemplateColumns)
        } else {
            gridTemplateColumns.push("1fr")
        }
    }
    return gridTemplateColumns.join(' ')
}


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        )
    }
)



const Table = ({
    data,
    columns,
    sort,
    select,
    getSelectedRows,
    infiniteScroll,
    loadFunc,
    loading,
    onChangeSort,
    initialSortBy,
    emptyTable

}) => {



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { sortBy }
    } = useTable(
        {
            manualSortBy: true,
            columns,
            data,
            initialState: { sortBy: initialSortBy },
            disableMultiSort: true,
        },
        useSortBy,
        useRowSelect,
        hooks => {
            select && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                    gridTemplateColumns: "minmax(70px, 70px)",
                },
                ...columns,
            ])
        }

    )



    useEffect(() => {
        getSelectedRows(selectedFlatRows)
    }, [selectedFlatRows, getSelectedRows])

    useEffect(() => {
        if (sort) onChangeSort(sortBy);
    }, [sortBy])



    const throttleScroll = throttle((element) => {
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
            loadFunc()
        }
    }, 300)


    const handleScroll = (e) => {
        if (infiniteScroll) {
            throttleScroll(e.target)
        }
    }


    return (
        <div className={s.tableContainer}>
            <div {...getTableProps()} className={s.table}  >
                <div className={s.thead} >
                    {headerGroups.map(headerGroup => (
                        <div {...headerGroup.getHeaderGroupProps()} className={s.trHead}
                            style={{
                                gridTemplateColumns: getGridTemplateColumns(headerGroup.headers)
                            }}
                        >
                            {headerGroup.headers.map(column => (
                                <div
                                    {...column.getHeaderProps(sort && column.getSortByToggleProps())}
                                    className={s.th}

                                >
                                    {column.render('Header')}
                                    <span className={s.sortContainer}>
                                        {sort && column.isSorted
                                            ? column.isSortedDesc
                                                ? <img src={arrow} className={s.arrowTop} alt="" />
                                                : <img src={arrow} alt="" />
                                            : null}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()} className={s.tbody} onScroll={handleScroll} >
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <div {...row.getRowProps()} className={s.trBody}
                                    style={{
                                        gridTemplateColumns: getGridTemplateForColumn(row.cells)
                                    }}
                                >
                                    {row.cells.map(cell => {
                                        return (
                                            <div
                                                {...cell.getCellProps()}
                                                className={s.td}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                    {!rows.length && !loading && emptyTable}
                    {loading && <div>Loading...</div>}
                </div>
            </div>
        </div>)
};

Table.defaultProps = {
    sort: false,
    getSelectedRows: () => { },
    loading: false,
    onChangeSort: () => { },
    initialSortBy: [],
    emptyTable: null

};



export default memo(Table);