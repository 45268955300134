import { socialAttachApi } from 'api';
import { getSocialUrl } from 'utils';

class AttachSocialAccount {
    attachAccountToGoogle = async (code, page, socialPage) => {
        return this.attachSocial(
            code,
            page,
            socialPage,
            'google',
            socialAttachApi.attachGoogle.bind(socialAttachApi)
        );
    };

    attachAccountToOutlook = async (code, page, socialPage) => {
        return this.attachSocial(
            code,
            page,
            socialPage,
            'outlook',
            socialAttachApi.attachOutlook.bind(socialAttachApi)
        );
    };

    attachSocial = async (code, page, socialPage, type, cb) => {
        try {
            if (!code) {
                const { data } = await cb(
                    null,
                    getSocialUrl(type, socialPage, null, page)
                );
                return (window.location.href = data.auth_url);
            }
            const { data, status_code } = await cb(
                code,
                getSocialUrl('outlook', socialPage, null, page)
            );
            if (status_code === 307) {
                return (window.location.href = data.auth_url);
            }

            window.history.replaceState({}, document.title, page);
        } catch (error) {
            throw error;
        }
    };
}

export default new AttachSocialAccount();
