import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Input, WarningMessage, Button } from 'components';
import s from './InitialStep.module.scss';
import variables from 'assets/styles/variables.module.scss';
import { NotificationStore } from 'components';

import yupSchemaGenerator from 'utils/yupSchemaGenerator';
import { useTranslation } from 'react-i18next';
import SignUpStore from '../SignUpStore.mobx';
import { InputPassword } from 'components/atoms';
import { Link } from 'react-router-dom';
import { privacyPolicy, termsOfService } from 'constants/routes';
import { envObject, envkeys } from 'api/envSwithcer';
import { IsDebug } from '@awesome-cordova-plugins/is-debug';
import { isPlatform } from '@ionic/react';
import classNames from 'classnames';
import { siginInAnalytics } from 'pages/SignIn/SignIn.analytics';

const InitialStepForm = () => {
    const [enableSwitcher, setEnableSwitcher] = useState(true);
    const validation = SignUpStore.formValidation
        ? {
              email: SignUpStore.formValidation.email,
              password: SignUpStore.formValidation.password,
              rePassword: SignUpStore.formValidation.confirmPassword
          }
        : null;

    const { t } = useTranslation('signup');

    const formik = useFormik({
        initialValues: { email: '', password: '', rePassword: '' },
        onSubmit: ({ email, password }) => {
            email = email.toLowerCase();
            SignUpStore.signUp({ email, password });
            siginInAnalytics.sendSignUpContinueEvent(email);
        },
        validationSchema: yupSchemaGenerator(validation),
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false
    });
    const {
        handleSubmit,
        errors,
        handleChange,
        values,
        handleBlur,
        setErrors
    } = formik;

    const errorsArray = Object.keys(errors);

    const checkIfProd = () => {
        if(envObject.react_app_environment === envkeys.prod.value){
            setEnableSwitcher(false)
        }
    }

    useEffect(() => checkIfProd(), []); 

    return (
        <form onSubmit={handleSubmit}>
            {!!errors[errorsArray[0]] && errorsArray[0] === 'email' && (
                <WarningMessage
                    error={errors[errorsArray[0]]}
                    className={s.warning}
                />
            )}
            <Input
                className={s.input}
                onBlur={(e) => {
                    handleBlur(e);
                    setErrors({});
                }}
                type="text"
                name="email"
                onChange={handleChange}
                value={values.email}
                errors={errors.email}
                placeholder={t('placeholder.email', 'Email')}
            />
            <>
                <div>
                    <p className={s.text}>
                        {t(
                            'passwordRules',
                            'Your password must contain at least 8 characters and one upper case and lower case letter, one number and one special character.'
                        )}
                    </p>
                </div>
                {!!errors[errorsArray[0]] && errorsArray[0] === 'password' && (
                    <WarningMessage
                        error={errors[errorsArray[0]]}
                        className={s.warning}
                    />
                )}
                <InputPassword
                    className={s.input}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    errors={errors.password}
                    placeholder={t('placeholder.password', 'Password*')}
                    onBlur={(e) => {
                        handleBlur(e);
                        setErrors({});
                    }}
                />
                {!!errors[errorsArray[0]] &&
                    errorsArray[0] === 'rePassword' && (
                        <WarningMessage
                            error={errors[errorsArray[0]]}
                            className={s.warning}
                        />
                    )}
                <InputPassword
                    className={s.input}
                    type="password"
                    name="rePassword"
                    onChange={handleChange}
                    value={values.rePassword}
                    errors={errors.rePassword}
                    placeholder={t(
                        'placeholder.reTypePassword',
                        'Re-type Password*'
                    )}
                    onBlur={(e) => {
                        handleBlur(e);
                        setErrors({});
                    }}
                />
            </>
            <div className={s.termsAndPolicyContainer}>
                <div>
                    <p className={s.termsAndPolicyText}>
                        {t(
                            'agree',
                            "By continuing you agree to Electric Miles'"
                        )}{' '}
                        <a
                            href={termsOfService}
                            className={s.termsAndPolicyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('termsOfService', 'Terms of Service')}
                        </a>{' '}
                        {t('and', 'and')}{' '}
                        <a
                            href={privacyPolicy}
                            className={s.termsAndPolicyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('privacyPolicy', ' Privacy Policy')}
                        </a>
                        .
                    </p>
                </div>
            </div>
            <div className={s.submitButton}>
                <Button
                    type="submit"
                    disabled={
                        !Object.values(formik.values).every((item) => item)
                    }
                >
                    {t('button.continue', 'Continue')}
                </Button>
            </div>
            {enableSwitcher && (
                <center className={s.environment}>
                    {envObject.react_app_environment}
                </center>
            )}
        </form>
    );
};

export default InitialStepForm;
