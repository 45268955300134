import React from 'react';
import s from './Step.module.scss';
import classNames from 'classnames';
import shape from 'assets/images/Shape.png';

const Step = ({ title, index, isActive, isFinished }) => {
    return (
        <div className={s.step}>
            <div className={s.header}>
                <div className={s.index}>
                    <div
                        className={classNames(
                            s.indexNumber,
                            { [s.activeIndex]: isActive },
                            { [s.finishedIndex]: isFinished }
                        )}
                    >
                        {isFinished ? <img src={shape} alt="" /> : index}
                    </div>
                </div>
                <div className={s.title}>
                    <p
                        className={classNames(
                            s.titleText,
                            { [s.titleTextActive]: isActive },
                            { [s.finishedText]: isFinished }
                        )}
                    >
                        {title}
                    </p>
                </div>
            </div>
            <div className={s.footer}>
                <div className={s.index}>
                    <div className={s.line}></div>
                </div>
                <div className={s.title}></div>
            </div>
        </div>
    );
};

Step.defaultProps = {
    title: '',
    isActive: false,
    isFinished: false
};
export default Step;
