import { makeAutoObservable } from 'mobx';
import {
    RequestLoadingStore,
    NotificationStore,
    SplitWrapperStore
} from 'components';
import EventEmitter from 'utils/EventEmitter';
import {
    customerApi,
    vehicleApi,
    stationsApi,
    supplierApi,
    socialAttachApi,
    cognitoApi,
    AttachSocialAccount,
    evApi
} from 'api';
import { mixpanel, myHistory, clean } from 'utils';
import { otherID } from './ProfileTabContainer/DeleteChargerModal/DeleteChargerModal.jsx';
import { settingsSocial, homeSettings, signIn } from 'constants/routes';

class ProfileStore {
    email;
    automatedCharging;
    firstName;
    secondName;
    homeAddress;
    depotAddress;
    vehicles;
    stations;
    suppliers;
    googleAccount;
    outlookAccount;
    avatar;
    chargeSettings;
    isSmartRegs;
    isSaved;
    loading;

    constructor() {
        this.reset();
        makeAutoObservable(this);
        EventEmitter.addEventListener('logOut', this.reset);
    }

    getProfileInfo = async () => {
        try {
            const [user, response, automatedCharging] = await Promise.all([
                cognitoApi.getAttributes(),
                customerApi.info(),
                customerApi.automatedCharging()
            ]);
            const { data } = response;
            if(data){
            if (data.suppliers[0]) {
                mixpanel.people.set({
                    'Supplier': data.suppliers[0].supplier.name,
                    'Tariff': data.suppliers[0].tariff.name
                });
            }
            if (data.stations[0]) {
                mixpanel.people.set({
                    'Charger Firmware': data.stations[0].firmwareVersion,
                    'Charger Manufacturer': data.stations[0].model.brand.name
                });
            }

            this.email = data.email || '';
            this.automatedCharging = automatedCharging.data.enabled;
            this.smartChargingType = automatedCharging.data.type;
            this.firstName = user.name;
            this.secondName = user.family_name;
            this.homeAddress = data.home && data.home;
            this.depotAddress = data.depot && data.depot;
            this.vehicles = data.vehicles;
            this.stations = data.stations;
            this.suppliers = data.suppliers;
            this.googleAccount = data.google_account;
            this.outlookAccount = data.outlook_account;
            this.avatar = data.avatar;
            this.chargeSettings = data.chargeSettings;
            this.isSmartRegs = data?.stations[0]?.isSmartRegSupport || false;
        }
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            if (error.name === 'AuthError') {
                localStorage.removeItem('roles');
                myHistory.history.push(signIn);
                EventEmitter.logOut();
            }
        }
    };

    getProfile = async () => {
        this.loading = true;
        RequestLoadingStore.showLoading();
        await this.getProfileInfo();
        RequestLoadingStore.hideLoading();
        this.loading = false;
    };

    editProfile = async (payload) => {
        RequestLoadingStore.showLoading();
        try {
            const [{ data }] = await Promise.all([
                customerApi.edit({
                    homeAddress: payload.homeAddress,
                    chargeSettings: clean(payload.chargeSettings)
                }),
                cognitoApi.updateName(payload.firstName, payload.secondName)
            ]);
            this.email = data.email || '';
            this.homeAddress = data.home && data.home;
            this.depotAddress = data.depot && data.depot;
            this.vehicles = data.vehicles;
            this.stations = data.stations;
            this.suppliers = data.suppliers;
            this.googleAccount = data.google_account;
            this.outlookAccount = data.outlook_account;
            this.avatar = data.avatar;
            this.chargeSettings = data.chargeSettings;
            this.isSaved = true;
            NotificationStore.showNotification({
                content: 'Changes saved successfully'
            });
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    deleteCar = async (id) => {
        RequestLoadingStore.showLoading();
        try {
            await Promise.all([vehicleApi.remove(id)]);
            this.getProfile();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    deleteCarEVApi = async () => {
        RequestLoadingStore.showLoading();
        try {
            await Promise.all([evApi.removeEv()]);
            this.getProfile();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    deleteSupplier = async () => {
        RequestLoadingStore.showLoading();
        try {
            await supplierApi.remove();
            this.getProfile();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    deleteCharger = async (payload) => {
        try {
            await stationsApi.remove(
                payload.id,
                payload.reason === otherID
                    ? payload.comments
                    : payload.radioArrays[payload.reason].title
            );
            mixpanel.people.set({'Charger':' '});
            mixpanel.identify();
            mixpanel.track('Charger Deleted', {
                'Reason': payload.radioArrays[payload.reason].title,
                'Comment': payload.comments
            });
            await this.getProfileInfo();
            payload.hide();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        }
    };

    attachSocial = async (type, code) => {
        const parameters = [
            code,
            `${homeSettings}?tab=profile`,
            settingsSocial
        ];
        RequestLoadingStore.showLoading();
        try {
            if (type === 'google') {
                await AttachSocialAccount.attachAccountToGoogle(...parameters);
            }
            if (type === 'outlook') {
                await AttachSocialAccount.attachAccountToOutlook(...parameters);
            }

            if (code) {
                this.getProfileInfo();
            }
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    detachSocial = async (type) => {
        RequestLoadingStore.showLoading();
        try {
            if (type === 'google') {
                await socialAttachApi.detachGoogle();
            }
            if (type === 'outlook') {
                await socialAttachApi.detachOutlook();
            }
            this.getProfileInfo();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    logOut = async () => {
        RequestLoadingStore.showLoading();
        try {
            localStorage.removeItem('roles');
            localStorage.removeItem('CHARGING_STATE');
            await cognitoApi.signOut();
            await SplitWrapperStore.getUser();
            mixpanel.track('Log out');
            myHistory.history.push(signIn);
            EventEmitter.logOut();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
            RequestLoadingStore.hideLoading();
        }
    };

    setAutomatedCharging = async (payload) => {
        RequestLoadingStore.showLoading();
        try {
            const { data } = await customerApi.setAutomatedCharging({
                type: payload.chargingType,
                enabled: payload.enabled
            });
            this.automatedCharging = data.enabled;
            this.smartChargingType = payload.chargingType;
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    reset = () => {
        this.email = '';
        this.automatedCharging = false;
        this.smartChargingType = '';
        this.firstName = '';
        this.secondName = '';
        this.homeAddress = { address: '', postCode: '' };
        this.depotAddress = { address: '', postCode: '' };
        this.vehicles = [];
        this.stations = [];
        this.suppliers = [];
        this.googleAccount = null;
        this.outlookAccount = null;
        this.avatar = '';
        this.chargeSettings = {
            dayStart: '',
            dailyMiles: 0,
            chargeReserve: 0,
            costCap: 0.00,
            maxCharge: 0
        };
        this.isSaved = false;
        this.loading = false;
    };
}

export default new ProfileStore();
