import React from 'react'
import s from './Avatar.module.scss'
import defaultAvatar from './image/avatar.png'
export default function Avatar({
    src
}) {
    return (
        <div className={s.main}>
            <img src={src || defaultAvatar} alt="Avatar" />
        </div>
    )
}
