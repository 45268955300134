import React, { Children, cloneElement, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import s from './Select.module.scss';
import { useEventListener, useOutside } from 'hooks';
import checkDownCircle from 'assets/images/check-down-circle.svg';
import { Input } from 'components/atoms';

let transformedChildren, selectedItem;

const Select = ({
    selectedOption,
    handleChange,
    options,
    children,
    initialValue,
    input,
    placeholder,
    onChange,
    id,
    error,
    disabled
}) => {
    const inputRef = useRef(null);
    const selectRef = useRef(null);
    const containerRef = useRef(null);
    const isOpenRef = useRef(false);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(initialValue);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => setSelectedValue(initialValue), [initialValue]);
    const [activeItem, setActiveItem] = useState(0);
    const activeRef = useRef(0);

    const onClickHandler = () => {
        if(!disabled)
        setIsOpen((state) => !state);
        isOpenRef.current = !isOpenRef.current;
    };

    const onSelectHandler = (value) => {
        setSelectedValue(value);
        onChange(value);
        setToDefault();
        setInputValue('');
    };

    const onChangeHandler = (e) => {
        setSelectedValue(null);
        setInputValue(e.target.value);
    };

    let renderChildren;
    if (input) {
        if (inputValue) {
            renderChildren = children.filter((item) =>
                item.props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
            );
            renderChildren = Children.map(renderChildren, (child, index) => {
                return cloneElement(child, {
                    onSelect: onSelectHandler,
                    selectedValue,
                    activeItem: index === activeItem
                });
            });
        } else {
            renderChildren = Children.map(children, (child, index) => {
                return cloneElement(child, {
                    onSelect: onSelectHandler,
                    selectedValue,
                    activeItem: index === activeItem
                });
            });
        }
    } else {
        renderChildren = Children.map(children, (child, index) => {
            return cloneElement(child, {
                onSelect: onSelectHandler,
                selectedValue,
                activeItem: index === activeItem
            });
        });
    }

    transformedChildren = Children.map(renderChildren, (item) => {
        return item.props;
    });

    selectedItem = transformedChildren.find((item) => {
        return item.value === selectedValue;
    });

    useEventListener(window, 'keypress', function (e) {
        if (e.key === 'Enter' && isOpenRef.current) {
            onSelectHandler(transformedChildren[activeRef.current].value);
            setIsOpen(false);
            isOpenRef.current = false;
            inputRef.current && inputRef.current.blur();
        }
    });

    useEventListener(window, 'keyup', (e) => {
        if (e.key === 'ArrowUp' && isOpenRef.current) {
            setActiveItem((state) => {
                if (state === 0) {
                    return transformedChildren.length - 1;
                }
                return state - 1;
            });
            if (activeRef.current === 0) {
                activeRef.current = transformedChildren.length - 1;
            } else {
                activeRef.current = activeRef.current - 1;
            }
            containerRef.current.scrollTop = 42 * activeRef.current;
        }
    });

    useEventListener(window, 'keyup', (e) => {
        if (e.key === 'ArrowDown' && isOpenRef.current) {
            setActiveItem((state) => {
                if (state === transformedChildren.length - 1) {
                    return 0;
                }
                return state + 1;
            });
            if (activeRef.current === transformedChildren.length - 1) {
                activeRef.current = 0;
            } else {
                activeRef.current = activeRef.current + 1;
            }
            containerRef.current.scrollTop = 42 * activeRef.current;
        }
    });

    useOutside(selectRef, () => {
        setIsOpen(false);
        isOpenRef.current = false;
        setToDefault();
    });

    const setToDefault = () => {
        activeRef.current = 0;
        setActiveItem(0);
    };

    return (
        <div
            id={id}
            onClick={onClickHandler}
            className={classNames(s.main, options.mainClassName, disabled && s.disabled)}
            ref={selectRef}
        >
            <div
                className={classNames(
                    {
                        [s.selectedContainer]: !options.selectedContainer
                    },
                    {
                        [s.activeSelectedContainer]: isOpen
                    },
                    {
                        [options.activeSelectedContainer]:
                            isOpen && options.activeSelectedContainer
                    },
                    {
                        [options.selectedContainer]: options.selectedContainer
                    },
                    {
                        [s.selectError]: error
                    }
                )}
            >
                <div className={s.selected}>
                    {selectedItem
                        ? selectedItem.children
                        : inputValue
                        ? ''
                        : placeholder}
                </div>
                <div className={s.inputContainer}>
                    {input && (
                        <Input
                        // <input
                            className={s.input}
                            value={inputValue}
                            onChange={onChangeHandler}
                            ref={inputRef}
                        />
                    )}
                </div>
                <div
                    className={classNames(s.cherDown, {
                        [s.cherDownActive]: isOpen
                    })}
                >
                    <img alt="" src={options.cherDownIcon} />
                </div>
            </div>
            <div
                className={classNames(
                    s.childrenContainer,
                    { [s.activeChildrenContainer]: isOpen },
                    { [options.childrenContainer]: options.childrenContainer }
                )}
                ref={containerRef}
            >
                {renderChildren}
            </div>
        </div>
    );
};

Select.defaultProps = {
    input: false,
    placeholder: 'Select',
    children: [],
    onChange: () => {},
    options: {
        mainClassName: '',
        selectedContainer: '',
        cherDownIcon: checkDownCircle,
        childrenContainer: ''
    },
    id: ''
};

export default Select;
