import { useHistory } from 'react-router-dom';
import { myHistory } from 'utils';

function HistoryProvider(props) {
    const history = useHistory();
    myHistory.history = history;
    return null;
}

export default HistoryProvider;
