const enTranslationFiles = require.context('./en', true);
const transPathPath = (name) => enTranslationFiles(name, true);

const resources = {
    en: {
        'add-charger': transPathPath('./add-charger.json'),
        'add-supplier': transPathPath('./add-supplier.json'),
        'add-trip': transPathPath('./add-trip.json'),
        'calendar': transPathPath('./calendar.json'),
        'change-password': transPathPath('./change-password.json'),
        'common': transPathPath('./common.json'),
        'confirmation': transPathPath('./confirmation.json'),
        'connect-car': transPathPath('./connect-car.json'),
        'delete-account': transPathPath('./delete-account.json'),
        'early-access-sign-up': transPathPath('./early-access-sign-up.json'),
        'early-access': transPathPath('./early-access.json'),
        'email-verification': transPathPath('./email-verification.json'),
        'history': transPathPath('./history.json'),
        'initial_old': transPathPath('./initial_old.json'),
        'initial': transPathPath('./initial.json'),
        'map': transPathPath('./map.json'),
        'optimise-charge': transPathPath('./optimise-charge.json'),
        'personalHome': transPathPath('./personalHome.json'),
        'tour': transPathPath('./tour.json'),
        'profile': transPathPath('./profile.json'),
        'registered': transPathPath('./registered.json'),
        'reset-password': transPathPath('./reset-password.json'),
        'signin': transPathPath('./signin.json'),
        'signup': transPathPath('./signup.json'),
        'steps': transPathPath('./steps.json'),
        'trip-picker': transPathPath('./trip-picker.json'),
        'waiting-list': transPathPath('./waiting-list.json'),
        'welcome': transPathPath('./welcome.json'),
        'misc': transPathPath('./misc.json'),
        'restricted-hour': transPathPath('./restricted-hour.json'),
        'solar': transPathPath('./solar.json'),
    }
};

export default resources;
