export default {
    pressSignInButton: {
        eventName: 'Sign In Clicked',
        pageName: 'Login Page View'
    },
    pressLogoutButton: {
        eventName: 'Logout Tell us about yourself',
        pageName: 'Tell us about yourself Page View'
    },
    pressContinueButton: {
        eventName: 'Continue Tell us about yourself',
        pageName: 'Tell us about yourself Page View'
    },
    pressChangeAddress: {
        eventName: 'Logout Tell us your charger address',
        pageName: 'Tell us your charger address Page View'
    },
    pressChargerAddress: {
        eventName: 'Continue Tell us your charger address',
        pageName: 'Tell us your charger address Page View'
    },
    pressWelcome: { eventName: 'Continue', pageName: 'Welcome' },
    pressSignUp: { eventName: 'Sign Up clicked', pageName: 'Login Page View' },
    pressSuccessNotification: {
        eventName: 'Success message OK clicked',
        pageName: 'Login Page View'
    },
    pressSignUpContinue: {
        eventName: 'Continue clicked',
        pageName: 'Sign Up Page View'
    },
    accountExist: { eventName: 'Existing Accounts', pageName: 'Sign Up Page View' },
    resendEmail: {
        eventName: 'Resend Email Clicked',
        pageName: 'Sign Up Page View'
    },
    pressCloseButton: {
        eventName: 'Close button tapped event',
        pageName: 'Add Charger PageView'
    },
    pressCloseButtonAlt: {
        eventName: 'Close button tapped event',
        pageName: 'Connecting Charger PageView'
    },
    pressDownloadConnectMiles: {
        eventName: 'Download connect miles event',
        pageName: 'Add Charger PageView'
    },
    pressDownloadConnectMilesAlt: {
        eventName: 'Download connect miles event',
        pageName: 'Connecting Charger PageView'
    },
    pressQRScanCode: {
        eventName: 'Scan QR code event',
        pageName: 'Add Charger PageView'
    },
    pressConnectCharger: {
        eventName: 'Connect charger event',
        pageName: 'Add Charger PageView'
    },
    successfullChargerConnect: {
        eventName: 'Charger connection Success Event',
        pageName: 'Connecting Charger PageView'
    },
    apiFailedChargerConnect: {
        eventName: 'Charger connection api failed event',
        pageName: 'Connecting Charger PageView'
    },
    failedChargerConnect: {
        eventName: 'Charger connection Fail event',
        pageName: 'Connecting Charger PageView'
    },
    pressEditCharger: {
        eventName: 'Edit charger event',
        pageName: 'Connecting Charger PageView'
    },
    pressContactUs: {
        eventName: 'Contact Us event',
        pageName: 'Connecting Charger PageView'
    },
    pressAddChargeSettings: {
        eventName: 'Add/Connect charger tapped',
        pageName: 'Settings Screen'
    }
};
