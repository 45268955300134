import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { envObject } from 'api/envSwithcer';
import IonicStorage from 'storage';

IonicStorage.putOnLocalStorage();


Sentry.init({
    dsn: `${envObject.react_app_sentry_dsn}`,
    release: 'electric-miles-ionic@' + envObject.react_app_version,
    environment: `${envObject.react_app_environment}`,
    integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({ console: false })
    ],
    tracesSampleRate: 1.0,
    denyUrls: ['http://localhost:8100']
});
export default Sentry.withProfiler(App);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
