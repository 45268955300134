import { makeAutoObservable, observable } from 'mobx';
import { mixpanel } from 'utils';

class NotificationStore {
    content = null;
    type = 'error';
    timeToClose = null;
    cb = () => {};
    okText = 'OK';
    background = null;

    constructor() {
        makeAutoObservable(this, {
            cb: observable
        });
    }

    showNotification = ({ content, timeToClose, cb, okText, background }) => {
        this.content = content;
        this.type = '';
        this.timeToClose = timeToClose;
        this.cb = cb;
        this.okText = okText;
        this.background = background;
    };

    showOkNotification = (content, timeToClose, cb) => {
        this.content = content;
        this.timeToClose = timeToClose;
        this.type = cb;
    };

    hideNotification = () => {
        this.content = null;
        this.timeToClose = null;
        this.cb = () => {};
        this.type = '';
        this.okText = 'OK';
    };

    showErrorNotification = (error, timeToClose) => {
        if (error.name === 'AuthError') {
            mixpanel.track('Backend Error', {
                'Type': error.name,
                'Message': error.message
            });
            this.type = '';
        }
        if (Array.isArray(error)) {
            const messageArray = [];
            error.forEach((item) => {
                mixpanel.track('Backend Error', {
                    'Type': item.name,
                    'Message': item.message
                });
                messageArray.push(item.message);
            });
            this.type = 'error';
            this.content = messageArray.join('\n');
            return (this.timeToClose = timeToClose);
        }

        mixpanel.track('Backend Error', {
            'Type': error.name,
            'Message': error.message
        });
        this.type = 'error';
        this.content = error.message;
        this.timeToClose = timeToClose;
    };
}

export default new NotificationStore();
