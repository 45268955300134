import React, { useState } from 'react';
import classNames from 'classnames';
import s from './Input.module.scss';
import eyeClose from './images/eyeClose.png';
import eyeOpen from './images/eyeOpen.png';
import { IonInput } from '@ionic/react';

export default function Input({ onChange, onBlur, onFocus, className, ...input }) {
    const [isPassword, setIsPassword] = useState(false);

    return (
        <div className={classNames(s.main)}>
            <IonInput
                className={classNames(s.input, className)}
                id={input.name}
                {...input}
                onIonInput={onChange}
                onIonBlur={onBlur}
                onIonFocus={onFocus}
                type={isPassword ? 'text' : 'password'}
            />
            <button
                className={s.eye}
                onClick={setIsPassword.bind(null, (value) => !value)}
                type="button"
            >
                {isPassword ? (
                    <img src={eyeClose} alt={'eye'} />
                ) : (
                    <img src={eyeOpen} alt={'eye'} />
                )}
            </button>
        </div>
    );
}
