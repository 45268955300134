import React from 'react';
import s from './Root.module.scss';
import classNames from 'classnames';
import { Button } from 'components/atoms';

const Root = ({ hide, resolve, reject, title, text, ok, cancel }) => {
    const okHandle = () => {
        hide();
        resolve(true);
    };

    const cancelHandle = () => {
        hide();
        resolve(false);
    };

    return (
        <div className={classNames(s.backGround, {})}>
            <div className={s.content}>
                <div className={s.title}>
                    <h3>{title}</h3>
                </div>
                <div className={s.text}>
                    <p>{text}</p>
                </div>
                <div className={s.buttonContainer}>
                    <Button type="button" onClick={okHandle}>
                        {ok || 'OK'}
                    </Button>
                    <Button type="button" onClick={cancelHandle} outline>
                        {cancel || 'CANCEL'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export { Root };
