import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import s from './InputWithMeasure.module.scss';

export default function InputWithMeasure({
    className,
    alignEnd,
    right,
    left,
    value,
    maxLength,
    maxDp,
    onChange,
    withoutSpace,
    max,
    min,
    ...input
}) {
    const leftElement = useRef(null);
    const rightElement = useRef(null);
    const [leftPadding, setLeftPadding] = useState(0);
    const [rightPadding, setRightPadding] = useState(0);

    useEffect(() => {
        setLeftPadding(leftElement.current.offsetWidth);
        setRightPadding(rightElement.current.offsetWidth);
    }, []);

    const handleChange = (e) => {
        e.target.value = e.target.value.slice(0, maxLength);
        if (e.target.value > max) {
            e.target.value = max;
        }
        onChange(e);
    };

    const handleBlur = (e) => {
        e.target.value = parseFloat(e.target.value.slice(0, maxLength)).toFixed(maxDp ?? 0);
        if (isNaN(+e.target.value) || +e.target.value < min) {
            e.target.value = min;
        }
        onChange(e);
    };

    return (
        <div className={s.container}>
            <input
                className={classNames(s.input, className, {
                    [s.alignEnd]: alignEnd
                })}
                value={value}
                {...input}
                style={{
                    paddingLeft: `${20 + leftPadding}px`,
                    paddingRight: `${20 + rightPadding}px`
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
            />
            <div
                className={classNames(s.overlay, {
                    [s.overlayAlignEnd]: alignEnd
                })}
            >
                <div ref={leftElement} className={s.left}>
                    {left}
                    {!withoutSpace && ' '}
                </div>
                <div className={s.value}>{value}</div>
                <div ref={rightElement} className={s.right}>
                    {!withoutSpace && ' '}
                    {right}
                </div>
            </div>
        </div>
    );
}
