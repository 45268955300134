import React from 'react';
import s from './Loading.module.scss'
import logo from 'assets/images/logo_alpha.png'

const Loading = () => {
    return (
        <div className={s.container}>
            <img src={logo} alt="" className={s.circlebeat} />
        </div>
    );
};


export default Loading;