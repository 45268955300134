import React from 'react';
import s from './CloseButton.module.scss'
import { useHistory } from "react-router-dom";
import classNames from 'classnames'
import close from 'assets/images/close.svg'

const CloseButton = ({ className, }) => {
    let history = useHistory();

    const goBack = () => {
        history.goBack()
    }

    return (
        <button className={classNames(s.button, className)} onClick={goBack}>
            <img src={close} alt='' />
        </button>
    );
};

CloseButton.defaultProps = {

};

export default CloseButton;