import ProfileStoreMobx from 'pages/Profile/ProfileStore.mobx';
import { mixpanel } from 'utils';
import {
    ANALYTICS_KEY_CT_CLAMP,
    ANALYTICS_KEY_SOLAR,
    ANALYTICS_KEY_SOLAR_STATE
} from './Solar.constant';

export const solarChanged = (value: any) => {
    // @ts-ignore
    mixpanel.track(ANALYTICS_KEY_SOLAR_STATE[value?.solarMode], {
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
    // @ts-ignore
    mixpanel.track(ANALYTICS_KEY_CT_CLAMP[value?.sampleWiring], {
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
    // @ts-ignore
    mixpanel.track(ANALYTICS_KEY_SOLAR[`${value?.solarModeEnabled}`], {
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
};

export const screenVisited = () => {
    mixpanel.track('PageSolar', {
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
};

export const apiFailed = (message: any) => {
    mixpanel.track('ErrorSolar', {
        ...message,
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
};

export const faqClicked = () => {
    mixpanel.track('FaqSolar', {
        email: ProfileStoreMobx?.email,
        SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
    });
};
