import React from 'react';
import s from './FirstScreen.module.scss';
import {
    Map,
    FaultsCard,
    ActiveChargingCard,
    ChargingSessionCard,
    TotalEnergy,
    TotalCharging,
    NewUsersCard,
    NewCharge,
    ChargePointCard,
    LocationCard
} from './components';
import StationStore from './StationStore.mobx';
import { observer } from 'mobx-react-lite';

const FirstScreen = () => {
    const {
        faults,
        activeCharging,
        chargingSession,
        totalEnergy,
        totalCharging,
        newUsers,
        newCharge,
        chargePoint,
        locationCard
    } = StationStore.data;

    return (
        <div className={s.content}>
            <header className={s.header}>
                <h2>Electric Miles Control Centre</h2>
            </header>
            <div className={s.firstContent}>
                <div className={s.cardContainer}>
                    <FaultsCard value={faults} />
                    <ActiveChargingCard value={activeCharging} />
                    <ChargingSessionCard value={chargingSession} />
                    <TotalEnergy value={totalEnergy} />
                    <TotalCharging value={totalCharging} />
                    <NewUsersCard value={newUsers} />
                    <NewCharge value={newCharge} />
                    <ChargePointCard
                        value={chargePoint.value}
                        total={chargePoint.total}
                    />
                    <LocationCard
                        value={locationCard.value}
                        total={locationCard.total}
                    />
                </div>
                <div className={s.mapContainer}>
                    <Map />
                </div>
            </div>
        </div>
    );
};

export default observer(FirstScreen);
