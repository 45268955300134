/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import moment from 'moment'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import s from './DateRange.module.scss'
import calendarIcon from './image/calendarIcon.png'
import { Button } from 'components'
import { ReactComponent as Corner } from './image/corner.svg'
import { useTranslation } from 'react-i18next';


const DateRange = ({
    setDate,
    placeholder,
    onBlur,
    position,
    initialValue,
    disabled,
    setEmpty
}) => {


    const [startTime, setStartTime] = useState(initialValue || null)
    const {t} = useTranslation("misc");
    useEffect(() => {
        setEmpty(setStartTime)
    }, [])

    const [isActive, setIsActive] = useState(false)
    const [state, setState] = useState({ dates: null });

    const onSelect = dates => {
        setStartTime(dates)
        setState({ dates })
    }
    const activeToggle = () => {
        if (disabled) return;
        setIsActive(state => !state)
    }

    const apply = () => {
        setDate(startTime)
        setIsActive(false)
    }




    return (
        <div className={s.main} onBlur={onBlur}>
            <div type="button" className={s.input} onClick={activeToggle} >
                {startTime ? <span className={s.value} > {startTime && !isActive && `${moment(startTime.start).format('DD / MM / YYYY')} - ${moment(startTime.end).format('DD / MM / YYYY')}`}</span> : <span className={s.placeholder} > {placeholder}</span>}
                <div className={s.image} >
                    <img src={calendarIcon} alt="" />
                </div>
            </div>
            <div className={classNames(s.datePiker, {
                [s.activePiker]: isActive,
                [s.topPosition]: position === 'top'
            })} >
                <div className={s.corner}>
                    <Corner />
                </div>
                <DateRangePicker
                    onSelect={onSelect}
                    value={state.dates}
                    numberOfCalendars={2}
                    className="customPikerStyles"
                    selectionType='range'
                />
                <div className={s.buttonContainer} >
                    <Button type="button" onClick={apply}>
                        {t("apply",  "Apply")}
                    </Button>
                </div>
            </div>
        </div >
    );
};

DateRange.defaultProps = {
    setDate: () => { },
    onBlur: () => { },
    setEmpty: () => { },
};

export default DateRange;