import React, { forwardRef } from 'react';
import classNames from 'classnames';
import s from './Input.module.scss';
import { IonInput } from '@ionic/react';

function Input({ className, onChange, onBlur, onFocus, ...input }, ref) {
    return (
        <IonInput
            className={classNames(s.input, className)}
            id={input.name}
            {...input}
            onIonInput={onChange}
            onIonBlur={onBlur}
            onIonFocus={onFocus}
            ref={ref}
        />
    );
}

export default forwardRef(Input);
