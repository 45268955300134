import React from 'react';
import s from './SkipButton.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SkipButton = ({ className, onClick, to }) => {
    const { t } = useTranslation('common');

    if (to) {
        return (
            <Link className={classNames(s.button, className)} to={to}>
                {t('button.skip', 'Skip')}
            </Link>
        );
    }

    return (
        <button
            className={classNames(s.button, className)}
            onClick={onClick}
            type="button"
        >
            {t('button.skip', 'Skip')}
        </button>
    );
};

SkipButton.defaultProps = {};

export default SkipButton;
