/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import s from './TripDatePicker.module.scss';
import moment from 'moment';
import calendarIcon from './images/calendarIcon.png';
import classNames from 'classnames';
import { ReactComponent as Corner } from './images/corner.svg';
import { Checkbox, Select, Option, Input } from 'components';
import { TimePicker, DatePicker } from 'components/molecules';
import { TripDayCheckbox } from './components';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useForCypress } from 'hooks';

const days = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7
};

function weekOfMonth(m) {
    return m.week() - moment(m).startOf('month').week() + 1;
}

function clean(obj) {
    for (var propName in obj) {
        if (!obj[propName]) {
            delete obj[propName];
        }
    }

    return obj;
}

export default function TripDatePicker({
    placeholder,
    onApply,
    onBlur,
    error
}) {
    const { t } = useTranslation('trip-picker');

    const shortDays = [
        // t('trip-picker:days.1', 'Sun')
        { title: 'days.1', value: 7 },
        // t('trip-picker:days.2', 'Mon')
        { title: 'days.2', value: 1 },
        // t('trip-picker:days.3', 'Tue')
        { title: 'days.3', value: 2 },
        // t('trip-picker:days.4', 'Wed')
        { title: 'days.4', value: 3 },
        // t('trip-picker:days.5', 'Thu')
        { title: 'days.5', value: 4 },
        // t('trip-picker:days.6', 'Fri')
        { title: 'days.6', value: 5 },
        // t('trip-picker:days.7', 'Sat')
        { title: 'days.7', value: 6 }
    ];

    const [submitObj, setSubmitObj] = useState(null);
    const hiddenInput = useRef();

    const submitHandle = (data) => {
        setActive(false);
        const { endDate, endTime, startTime, ...obgForSubmit } = data;
        const objForSubmit = obgForSubmit;
        const cleanedObj = clean({ ...objForSubmit });
        const applyObj = { ...cleanedObj };
        if (applyObj.startDate) {
            applyObj.startDate = moment(cleanedObj.startDate).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        if (applyObj.lastStartAt) {
            applyObj.lastStartAt = moment(cleanedObj.startDate).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        onApply(applyObj);
        setSubmitObj(cleanedObj);
    };

    const formik = useFormik({
        initialValues: {
            startDate: moment(new Date()).set({
                hour: 0,
                minute: 0
            }),
            endDate: moment(new Date()).set({
                hour: 23,
                minute: 59
            }),
            recurrenceType: null,
            every: 1,
            weekDays: [],
            monthlyRecurrenceType: null,
            monthlyRecurrenceValue: null,
            maxOccurrences: '',
            lastStartAt: null
        },
        onSubmit: submitHandle,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false
    });

    const { values, handleChange, setFieldValue, submitForm } = formik;

    const [active, setActive] = useState(false);
    const [fullDay, setFullDay] = useState(true);
    const [repeatValue, setRepeatValue] = useState(1);
    const [monthlyRepeat, setMonthlyRepeat] = useState(null);
    const [endType, setEndType] = useState(1);

    const fullDayHandle = () => setFullDay((value) => !value);

    const setDate = (date) => {
        let dateWithTime = date;
        let endWithTime = date;

        if (fullDay) {
            dateWithTime = moment(date).set({
                hour: 0,
                minute: 0
            });

            endWithTime = moment(date).set({
                hour: 23,
                minute: 59
            });
        } else {
            dateWithTime = moment(date).set({
                hour: values.startTime.hour(),
                minute: values.startTime.minutes()
            });

            endWithTime = moment(date).set({
                hour: values.endTime.hour(),
                minute: values.endTime.minutes()
            });
        }
        setFieldValue('startDate', dateWithTime);
        setFieldValue('endDate', endWithTime);
    };

    const timeHandle = (time, name) => {
        const splitTime = time.split(':');
        const dateWithTime = moment(values[name]).set({
            hour: splitTime[0],
            minute: splitTime[1]
        });
        setFieldValue(name, dateWithTime);
    };

    useEffect(() => {
        if (fullDay) {
            let dateWithTime = values.startDate;
            let endWithTime = values.endDate;

            dateWithTime = moment(dateWithTime).set({
                hour: 0,
                minute: 0
            });

            endWithTime = moment(endWithTime).set({
                hour: 23,
                minute: 59
            });

            setFieldValue('startDate', dateWithTime);
            setFieldValue('endDate', endWithTime);
            setFieldValue('startTime', null);
            setFieldValue('endTime', null);
        }
    }, [fullDay]);

    const repeatHandle = (value) => {
        setRepeatValue(value);
        setFieldValue('recurrenceType', null);
        setFieldValue('every', 1);
        setFieldValue('weekDays', []);

        switch (value) {
            case 1: //   Don't repeat
                break;
            case 2: // Daily
                setFieldValue('recurrenceType', 'daily');
                setFieldValue('every', 1);
                break;
            case 3: // Weekly - current day
                setFieldValue('recurrenceType', 'weekly');
                setFieldValue('every', 1);
                setFieldValue('weekDays', [
                    days[moment(new Date()).format('dddd')]
                ]);
                break;
            case 4: //   Every weekday(Monday to Friday)
                setFieldValue('recurrenceType', 'weekly');
                setFieldValue('every', 1);
                setFieldValue('weekDays', [1, 2, 3, 4, 5]);
                break;
            case 5: //    Other...
                break;

            default:
                break;
        }
    };

    const weekHandle = (value) => {
        const weekArray = [...values.weekDays];
        if (values.weekDays.includes(value)) {
            const index = values.weekDays.indexOf(value);
            if (index > -1) {
                weekArray.splice(index, 1);
            }
        } else {
            weekArray.push(value);
        }
        setFieldValue('weekDays', weekArray);
    };

    const monthlyRepeatHandle = (value) => {
        setFieldValue('monthlyRecurrenceType', null);
        setFieldValue('monthlyRecurrenceValue', null);
        setFieldValue('weekDays', []);

        setMonthlyRepeat(value);
        switch (value) {
            case 1: ///    Monthly on the
                setFieldValue('monthlyRecurrenceType', 'day');

                break;
            case 2: // Every week of month
                setFieldValue('monthlyRecurrenceType', 'weekday');
                setFieldValue(
                    'monthlyRecurrenceValue',
                    weekOfMonth(values.startDate)
                );
                setFieldValue('weekDays', [
                    days[moment(values.startDate).format('dddd')]
                ]);

                break;

            default:
                break;
        }
    };

    const endTypeHandle = (e) => {
        setEndType(+e.target.value);
        setFieldValue('maxOccurrences', '');
        setFieldValue('lastStartAt', undefined);
    };

    const lastStartHandle = (value) => {
        setFieldValue('lastStartAt', moment(value));
    };

    const repeatTypeHandle = (value) => {
        setFieldValue('recurrenceType', value);
    };

    const onClickHandle = () => {
        setActive((value) => !value);
    };

    const cancelHandle = () => {
        setActive(false);
    };

    useForCypress(hiddenInput.current, (value) => {
        submitHandle({
            ...values,
            startDate: value
        });
    });

    const yearIncreaseBy = 5;

    return (
        <div className={s.main} onBlur={onBlur}>
            <div
                className={classNames(s.input, { [s.errorInput]: error })}
                onClick={onClickHandle}
            >
                {submitObj ? (
                    <span className={s.value}>
                        {moment(submitObj.startDate).format(
                            'ddd, DD / MM / YYYY - HH:mm'
                        )}{' '}
                        {!!submitObj.recurrenceType &&
                            `, ${submitObj.recurrenceType}`}{' '}
                        {!!submitObj.weekDays.length &&
                            ` - ${submitObj.weekDays.map((item) =>
                                t(
                                    shortDays.find(
                                        (findItem) => findItem.value === item
                                    ).title
                                )
                            )}`}
                    </span>
                ) : (
                    <span className={s.placeholder}>{placeholder}</span>
                )}
                <div className={s.imgContainer}>
                    <img src={calendarIcon} alt="" />
                </div>
                <input
                    ref={hiddenInput}
                    type="text"
                    className={s.hiddenInput}
                    id="tripDate"
                />
            </div>
            <div className={classNames(s.popup, { [s.activePopup]: active })}>
                <div className={s.corner}>
                    <Corner />
                </div>
                <div className={s.content}>
                    <h6 className={s.title}>
                        {t('selectDate', 'Select date and time')}
                    </h6>
                    <DatePicker
                        onChange={setDate}
                        value={values.startDate.format('DD / MM / YYYY')}
                        placeholder={t('day', 'Day')}
                        min={moment().format('YYYY-MM-DD')}
                        max={moment()
                            .set({
                                year: new Date().getFullYear() + yearIncreaseBy
                            })
                            .format('YYYY-MM-DD')}
                    />
                    <div className={s.checkboxContainer}>
                        <Checkbox checked={fullDay} onChange={fullDayHandle} />
                        <span className={s.fullDay}>
                            {t('fullDay', 'full day')}
                        </span>
                    </div>
                    {!fullDay && (
                        <div className={s.timersContainer}>
                            <TimePicker
                                placeholder={t('startTime', 'Start time')}
                                onChange={timeHandle}
                                name="startDate"
                                value={values.startDate.format('HH:mm')}
                            />
                            <TimePicker
                                placeholder={t('endTime', 'End time')}
                                name="endDate"
                                onChange={timeHandle}
                                value={values.endDate.format('HH:mm')}
                            />
                        </div>
                    )}
                    <div className={s.selectContainer}>
                        <Select
                            onChange={repeatHandle}
                            initialValue={repeatValue}
                        >
                            <Option value={1}>
                                {t('dontRepeat', "Don't repeat")}
                            </Option>
                            <Option value={2}>{t('daily', 'Daily')}</Option>
                            <Option value={3}>
                                {t('weekly', 'Weekly')} -{' '}
                                {moment(new Date()).format('dddd')}
                            </Option>
                            <Option value={4}>
                                {t(
                                    'everyWeekday',
                                    'Every weekday (Monday to Friday)'
                                )}
                            </Option>
                            <Option value={5}>
                                {t('other', 'Other')}
                                ...
                            </Option>
                        </Select>
                    </div>
                    {repeatValue === 5 && (
                        <div className={s.customInterval}>
                            <h6 className={s.title}>
                                {t('repeatAtIntervals', 'Repeat at intervals')}
                            </h6>
                            <div className={s.repeat}>
                                <Input
                                    type="number"
                                    pattern="\d*"
                                    name="every"
                                    value={values.every}
                                    onChange={handleChange}
                                />

                                <Select
                                    onChange={repeatTypeHandle}
                                    initialValue={values.recurrenceType}
                                >
                                    <Option value={'daily'}>
                                        {t('day', 'Day')}
                                    </Option>
                                    <Option value={'weekly'}>
                                        {t('weekly', 'Weekly')}
                                    </Option>
                                    <Option value={'monthly'}>
                                        {t('month', 'Month')}
                                    </Option>
                                    <Option value={'yearly'}>
                                        {t('year', 'Year')}
                                    </Option>
                                </Select>
                            </div>
                            {values.recurrenceType === 'weekly' && (
                                <div className={s.repeatDays}>
                                    <h6 className={s.title}>
                                        {t('repetitionDays', 'Repetition days')}
                                    </h6>
                                    <div className={s.daysContainer}>
                                        {shortDays.map((item) => {
                                            return (
                                                <TripDayCheckbox
                                                    title={t(item.title)}
                                                    key={item.value}
                                                    value={item.value}
                                                    onChange={weekHandle}
                                                    checked={values.weekDays.includes(
                                                        item.value
                                                    )}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            {values.recurrenceType === 'monthly' && (
                                <div className={s.selectMonth}>
                                    <Select
                                        onChange={monthlyRepeatHandle}
                                        initialValue={monthlyRepeat}
                                    >
                                        <Option value={1}>
                                            {t(
                                                'monthlyOnThe',
                                                'Monthly on the'
                                            )}{' '}
                                            {values.startDate.date()}
                                        </Option>
                                        <Option value={2}>
                                            {t('every', 'Every')}{' '}
                                            {weekOfMonth(values.startDate)}{' '}
                                            {moment(values.startDate).format(
                                                'dddd'
                                            )}{' '}
                                            {t('ofTheMonth', 'of the month')}
                                        </Option>
                                    </Select>
                                </div>
                            )}
                            <div className={s.end}>
                                <h6 className={s.title}>{t('End', 'End')}</h6>
                                <div className={s.endItem}>
                                    <Checkbox
                                        circle
                                        name={'endType'}
                                        checked={endType === 1}
                                        value={1}
                                        onChange={endTypeHandle}
                                    />
                                    <span className={s.itemTitle}>
                                        {t('never', 'Never')}
                                    </span>
                                </div>
                                <div className={s.endItem}>
                                    <Checkbox
                                        circle
                                        name={'endType'}
                                        checked={endType === 2}
                                        value={2}
                                        onChange={endTypeHandle}
                                    />
                                    <span className={s.itemTitle}>
                                        {t('date', 'Date')}
                                    </span>
                                    <DatePicker
                                        onChange={lastStartHandle}
                                        value={values.lastStartAt?.format(
                                            'MM / DD / YYYY'
                                        )}
                                        placeholder={t('day', 'Day')}
                                        min={moment().format('YYYY-MM-DD')}
                                        max={moment()
                                            .set({
                                                year:
                                                    new Date().getFullYear() +
                                                    yearIncreaseBy
                                            })
                                            .format('YYYY-MM-DD')}
                                    />
                                </div>
                                <div className={s.endItem}>
                                    <Checkbox
                                        circle
                                        name={'endType'}
                                        checked={endType === 3}
                                        value={3}
                                        onChange={endTypeHandle}
                                    />
                                    <span className={s.itemTitle}>
                                        {t('after', 'After')}
                                    </span>
                                    <Input
                                        type="number"
                                        pattern="\d*"
                                        placeholder={t('reruns', 'reruns')}
                                        name="maxOccurrences"
                                        onChange={handleChange}
                                        value={values.maxOccurrences}
                                        disabled={endType !== 3}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={s.footer}>
                        <button
                            className={s.cancel}
                            type="button"
                            onClick={cancelHandle}
                        >
                            {t('button.cancel', 'Cancel')}
                        </button>
                        <button
                            className={s.apply}
                            type="button"
                            onClick={submitForm}
                        >
                            {t('button.apply', 'Apply')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

TripDatePicker.defaultProps = {
    onApply: () => {}
};
