import { useReducer, useEffect } from 'react';

const initialState = (initialIndex = 0) => ({
    showSteps: false,
    currentStep: initialIndex
});

const NEXT = 'NEXT';
const PREVIOUS = 'PREVIOUS';
const SET_STEP = 'SET_STEP';

const next = () => ({ type: NEXT });
const previous = () => ({ type: PREVIOUS });
const setStep = (step) => ({ type: SET_STEP, payload: step });

function reducer(state, action) {
    switch (action.type) {
        case NEXT:
            return { ...state, currentStep: state.currentStep + 1 };
        case PREVIOUS:
            return { ...state, currentStep: state.currentStep - 1 };
        case SET_STEP:
            return { ...state, currentStep: action.payload };
        default:
            throw new Error();
    }
}

export default function useWizard(initialIndex) {
    const [state, dispatch] = useReducer(reducer, initialState(initialIndex));

    useEffect(() => {
        document.querySelector('ion-content').scrollToTop &&  document.querySelector('ion-content').scrollToTop();
    }, [state.currentStep]);

    return {
        state,
        next: () => dispatch(next()),
        previous: () => dispatch(previous()),
        setStep: (step) => dispatch(setStep(step))
    };
}
