import * as yup from 'yup';


function fieldValidation(schema) {

    if (!schema?.type) return null

    let fieldValidation = yup[schema.type]()
    const { validations = [] } = schema

    for (let i = 0; i < validations.length; i++) {
        const arg = []
        let value = validations[i].value
        if (validations[i].type === 'matches' && value) {
            value = value.replace(/\//g, '')
            value = new RegExp(value, validations[i].flags.join(''))
        }

        if (validations[i].type === 'oneOf' && value && typeof value[0] === 'object' && value[0].yupRef) {
            value[0] = yup.ref(value[0].yupRef)

        }

        validations[i].value && arg.push(value)
        validations[i].message && arg.push(validations[i].message)

        fieldValidation = fieldValidation[validations[i].type](...arg)
    }

    return fieldValidation


}

function generator(schema) {

    const shape = {}

    for (let prop in schema) {
        // console.log("obj." + prop + " = " + obj[prop]);
        shape[prop] = fieldValidation(schema[prop])
    }


    return yup.object().shape({
        ...shape
    })


}


export default generator