import { AuthApi } from 'api'

class VehicleApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}vehicle${path}`;
    }

    brandList() {
        return this.get('/brand/list')
    }

    modelList(brandId: number | string) {
        return this.get(`/model/list?brandId=${brandId}`)

    }

    add(body: object) {
        return this.post('', body)
    }

    edit(id: string, body: object) {
        return this.put(`/${id}`, body)
    }

    remove(id: number | string) {
        return this.delete(`/${id}`)
    }

    honk(body: object) {
        return this.post('/honk', body)
    }

    fleet(id: number | string, body: object) {
        return this.put(`/fleet/${id}`, body)
    }
}

const vehicleApi = new VehicleApi()
export default vehicleApi;

