import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { FormTitle } from 'components/atoms';
import { signIn } from 'constants/routes';
import { Link } from 'react-router-dom';
import { SocialSharing } from '@ionic-native/social-sharing';
import shareIcon from './image/Icon.png';
import s from './WaitingList.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import EarlyAccessStore from '../EarlyAccess/EarlyAccessStore.mobx';

function WaitingList() {
    const { t } = useTranslation('waiting-list');

    const share = () => {
        SocialSharing.shareWithOptions({
            appName: 'Electric Miles',
            message:
                'Have you checked out the newest smart charging app in town? I’ve just signed up to get early access to Electric Miles so I can schedule my EV charge and forget about it, reduce my EV charging costs and save and even earn money while I sleep or work.',
            subject: 'Social Share Copy',
            url: 'https://www.electricmiles.io/em-app-live/'
        })
            .then((value) => {
                console.log(value);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <IonPage>
            <IonContent className={s.ionicContent}>
                <div className={s.page} id="ionic-page">
                    <div className={s.container}>
                        <section className={s.section}>
                            <header className={s.header}>
                                <FormTitle>
                                    {t(
                                        'youAreOnTheList',
                                        'You’re on the list!'
                                    )}
                                    <br />
                                    <span role="img" aria-label="">
                                        ⚡ 🚘️
                                    </span>
                                </FormTitle>
                            </header>
                            <div>
                                <h3 className={s.title}>
                                    {t('youAreNumber', 'You are number')}
                                </h3>
                                <h3 className={s.number}>
                                    {EarlyAccessStore.number}
                                </h3>
                                <p className={s.text}>
                                    {t('onTheList', 'on the list')}
                                </p>

                                <div className={s.shareContainer}>
                                    <p className={s.shareText}>
                                        {t(
                                            'shareOnSocialMedia',
                                            'Share on social media to move up the list.'
                                        )}
                                    </p>
                                    <button
                                        type="button"
                                        className={s.shareButton}
                                        onClick={share}
                                    >
                                        <img src={shareIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                            <footer className={s.footer}>
                                <p className={s.text}>
                                    {t(
                                        'existingEarlyUser',
                                        'Existing early user?'
                                    )}
                                </p>
                                <Link className={s.link} to={signIn}>
                                    {t('logIn', 'Log in')} →
                                </Link>
                            </footer>
                        </section>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default observer(WaitingList);
