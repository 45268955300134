import React, { useState, useEffect, memo } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import classNames from 'classnames';
import s from './GooglePlaceAutocomplete.module.scss';
import { IonInput } from '@ionic/react';

import poweredByGoogle from './images/powered_by_google_on_white.png';

const GooglePlaceAutocomplete = ({
    name,
    onChange,
    value,
    setFieldError,
    placeholder,
    setId,
    initialValue,
    onBlur,
    setCoordinates,
    setPostCode,
    type,
    className,
    errors
}) => {
    const [input, setInput] = useState('');

    useEffect(() => {
        if (initialValue) {
            setInput(initialValue);
        }
    }, [initialValue]);

    const handleSelect = (address, id) => {
        setFieldError(name, null);
        setId(id);

        if (setCoordinates) {
            geocodeByAddress(address)
                .then((results) => {
                    const postCode = results[0].address_components.find(
                        (item) => item.types.includes('postal_code')
                    )?.long_name;
                    setPostCode(postCode);
                    type !== 'postcode' && setInput(address);
                    onChange(address, input);
                    setInput(address);
                    return getLatLng(results[0]);
                })
                .then((latLng) => setCoordinates(latLng))
                .catch((error) => console.error('Error', error));
        }
    };

    const handleChange = (value) => {
        onChange('');
        setInput(value);
        setFieldError(
            name,
            'Please select the right address from the drop down menu'
        );
    };

    const handleBlur = (e) => {
        if (type === 'postcode') {
            onChange('', input);
        }
        onBlur(e);
    };

    return (
        <PlacesAutocomplete
            value={input}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={500}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => {
                const inputProps = getInputProps({
                    placeholder,
                    className: classNames(s.input, {
                        [s.errorInput]: errors
                    }),
                    name,
                    id: name,
                    onBlur
                });

                return (
                    <div className={classNames(s.main, className)}>
                        <label>{inputProps.placeholder}</label>
                        <IonInput
                            {...inputProps}
                            onIonInput={inputProps.onChange}
                            onIonBlur={handleBlur}
                        />
                        <div className={s.dropDownContainer}>
                            {loading && (
                                <div className={s.loading}>Loading...</div>
                            )}
                            {suggestions.map((suggestion) => {
                                const { key, ...itemProps } =
                                    getSuggestionItemProps(suggestion, {
                                        className: classNames(s.item)
                                    });
                                return (
                                    <div key={suggestion.index} {...itemProps}>
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                            {!!suggestions.length && (
                                <div className={s.poweredByGoogleImg}>
                                    <img
                                        src={poweredByGoogle}
                                        alt="poweredByGoogle"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </PlacesAutocomplete>
    );
};

GooglePlaceAutocomplete.defaultProps = {
    setFieldError: () => {},
    placeholder: 'Placeholder',
    setId: () => {},
    onChange: () => {},
    setPostCode: () => {},
    setCoordinates: () => {},
    onBlur: () => {}
};

export default memo(GooglePlaceAutocomplete);
