import React, { useState, useMemo } from 'react';
import { SearchInput, Table, Toggle } from 'components';
import s from './Station.module.scss';
import stationsDemo from './stationsDemo.json';
import StationStore from './StationStore.mobx';
import { observer } from 'mobx-react-lite';

const SecondScreen = ({ loadFunc }) => {
    const [chargers, setChargers] = useState({});

    const data = useMemo(() => stationsDemo, []);
    const tableLoading = StationStore.loading;
    const columns = [
        {
            Header: '#',
            accessor: 'id',
            gridTemplateColumns: 'minmax(70px, 70px)'
        },
        {
            Header: 'Name',
            accessor: 'name',
            gridTemplateColumns: 'minmax(188px, 3fr)'
        },
        {
            Header: 'Charge Box Identity',
            accessor: 'charge_box_identity',
            gridTemplateColumns: 'minmax(188px, 2fr)'
        },
        {
            Header: 'Status',
            accessor: (row) => row.connectors[0].status.name
        },
        {
            Header: 'Charging',
            gridTemplateColumns: 'minmax(120px, 1fr)',
            accessor: 'charging',
            disableSortBy: true,
            Cell: (data) => {
                return (
                    <Toggle
                        name={data.row.index}
                        checked={chargers[data.row.index]}
                        onChange={() =>
                            setChargers((state) => ({
                                ...state,
                                [data.row.index]: !state[data.row.index]
                            }))
                        }
                    />
                );
            }
        }
    ];

    return (
        <div className={s.content}>
            <header className={s.header}>
                <h2>Stations</h2>
                <form action="">
                    <div>
                        <SearchInput />
                    </div>
                    <div>
                        <button className={s.exportButton}>create</button>
                    </div>
                </form>
            </header>
            <div className={s.tableContainer}>
                <Table
                    data={data}
                    columns={columns}
                    sort
                    infiniteScroll
                    loadFunc={loadFunc}
                    loading={tableLoading}
                />
            </div>
        </div>
    );
};

export default observer(SecondScreen);
