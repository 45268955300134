import React from 'react';
import { Redirect } from 'react-router-dom';
import { home, initial, signUp } from 'constants/routes';
import { useRole } from 'hooks';
import { earlyAccess } from 'constants/treatments';
import { SplitTreatments } from '@splitsoftware/splitio-react';

const RedirectToHome = () => {
    const { can } = useRole();

    return (
        <SplitTreatments names={[earlyAccess]}>
            {({ treatments, isReady }) => {
                if (!isReady) return null;
                return can('login:automatically') ? (
                    <Redirect to={home} />
                ) : (
                    <Redirect
                        to={
                            treatments[earlyAccess].treatment === 'on'
                                ? initial
                                : signUp
                        }
                    />
                );
            }}
        </SplitTreatments>
    );
};

export default RedirectToHome;
