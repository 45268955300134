import React from 'react';
import s from './BackButton.module.scss';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import back from 'assets/images/back.svg'

const BackButton = ({ className }) => {
    let history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    return (
        <button className={classNames(s.button, className)} onClick={goBack}>
            <img src={back} alt='' />
        </button>
    );
};

BackButton.defaultProps = {};

export default BackButton;
