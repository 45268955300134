import React from 'react';
import s from '../SignUp.module.scss'
import { Advantages } from 'components'
import car from 'assets/images/car.png'

const PersonalDetailsAdvances = ({ steps }) => {
    return (
        <Advantages backGroundColor="rgb(255, 255, 255, 0)" image={car} logo={false}>
            <div className={s.stepsContainer}>
                {steps}
            </div>
        </Advantages>
    );
};

export default PersonalDetailsAdvances;