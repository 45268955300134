import React from 'react';
import s from './SkipNext.module.scss';
import classNames from 'classnames';
import afterSignUpSetup from 'constants/afterSignUpSetup';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SkipNext({ skipHandle, white, onContinue, hideNext }) {
    const location = useLocation();
    const { t } = useTranslation('common');

    return (
        <div className={s.main}>
            <div className={s.buttonContainer}></div>
            <div className={s.circleContainer}>
                {afterSignUpSetup.map((item, index) => {
                    if (afterSignUpSetup.length - 1 === index) {
                        return null;
                    }
                    return (
                        <div
                            key={index}
                            className={classNames(s.circle, {
                                [s.activeCircle]: item === location.pathname
                            })}
                        />
                    );
                })}
            </div>
            {hideNext ? null : (
                <div className={s.buttonContainer}>
                    <button
                        className={classNames(s.nextButton, {
                            [s.whiteSkipButton]: white
                        })}
                        type="button"
                        onClick={skipHandle}
                    >
                        {t('button.skip', 'Skip')}
                    </button>
                </div>
            )}
        </div>
    );
}
