import React from 'react';
import classNames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HCMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";

import s from './HalfCircleProgress.module.scss'


const HalfCircleProgress = ({
    children,
    bgColor,
    current,
    max,
    className
}) => {

    // const currentState = current * 100 / max


    const options = {
        chart: {
            type: "solidgauge",
            backgroundColor: 'none',
            height: "100%"
        },

        title: null,

        tooltip: {
            borderWidth: 0,
            backgroundColor: "none",
            shadow: false,
            enabled: false

        },

        pane: {
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: [
                {
                    outerRadius: "100%",
                    innerRadius: "70%",
                    backgroundColor: '#cccfff',
                    shape: 'arc'
                }
            ]
        },

        yAxis: {
            min: 0,
            max: max,
            lineWidth: 0,
            tickPositions: [],
            tickWidth: 0,
            minorTickInterval: null,
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                stickyTracking: false,
            }
        },

        series: [
            {
                name: "Move",
                type: "solidgauge",
                data: [
                    {
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, '#4048EF'],
                                [1, '#5A7BEF']
                            ]
                        },
                        radius: "100%",
                        innerRadius: "70%",
                        y: current
                    }
                ]
            }
        ]
    };

    HCMore(Highcharts);
    SolidGauge(Highcharts);



    return (
        <div className={classNames(s.main, className)}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={'chart'}
            />
            <div className={s.current}>
                {children}
            </div>
        </div>
    );
};

export default HalfCircleProgress;