import React from 'react'
import s from '../../TripDatePicker.module.scss'
import classNames from 'classnames'

export default function TripDayCheckbox({
    value,
    checked,
    onChange,
    title
}) {
    return (
        <div className={classNames(s.day, { [s.activeDay]: checked })} onClick={onChange.bind(null, value)}>
            <span>
                {title}
            </span>
        </div>
    )
}
