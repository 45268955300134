export const getCorrectFieldName = (by) => {
    switch (by) {
        case 'expirationTime':
            return 'time duration cap';
        case 'power':
            return 'energy cap';
        case 'price':
            return 'price you want to cap at';
        default:
            return '';
    }
};
