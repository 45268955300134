import React from 'react';
import s from './CalendarTrip.module.scss'
import iconTime from './image/icon_time.png'
import close from './image/close.png'
import map from './image/map.png'
import { Link } from 'react-router-dom'
import {
    homeMap,
} from 'constants/routes'
import { useTranslation } from 'react-i18next';

const CalendarTrip = (props) => {
    const { time, name, id, removeEvent, setSelectEvent } = props

    const {t} = useTranslation("misc")

    const handleRemove = () => {
        removeEvent(id)
        setSelectEvent(null)
    }
    return (
        <div className={s.main}>
            <div className={s.container}>
                <div className={s.content} >
                    <div className={s.timeAndLength} >
                        <div className={s.time} >
                            <div className={s.timeImg}>
                                <img src={iconTime} alt="" />
                            </div>
                            <div className={s.value} >
                                {time}
                            </div>
                        </div>
                    </div>
                    <div className={s.nameContainer} >
                        <span className={s.name} >
                          {t("name", "Name")}
                        </span>
                        <span className={s.title} >
                            {name}
                        </span>
                    </div>
                    <div className={s.buttonContainer} >
                        <Link className={s.map}
                            to={{
                                pathname: homeMap,
                                search: `?eventId=${id}`,
                            }}
                        >
                            <img src={map} alt="" />
                        </Link>
                        <div className={s.remove} role="button" onClick={handleRemove}  >
                            <img src={close} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarTrip;