import { AuthApi } from 'api'

interface Date {
    startDate: any;
    endDate: any;
}

class DashboardApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}dashboard/${path}`;
    }

    home() {
        return this.get('home')
    }

    calendar(data: Date) {
        return this.get(`calendar?startDate=${data.startDate}&endDate=${data.endDate}`)
    }

    map() {
        return this.get('map')
    }

    chargingData() {
        return this.get('fleet/charging-data')
    }

    powerMonitorData() {
        return this.get('fleet/power-monitor-data')
    }

    vehiclesData() {
        return this.get('fleet/vehicles-data')
    }

    vehiclesList() {
        return this.get('fleet/vehicles-list')
    }

    summary() {
        return this.get('fleet/summary')
    }

    graphData() {
        return this.get('fleet/graph-data')
    }
}

const dashboardApi = new DashboardApi()
export default dashboardApi;