import { envObject } from "api/envSwithcer";

//sub home
export const home = '/home';
export const homeMap = '/home/map';
export const homeCalendar = '/home/calendar';
export const homeHistory = '/home/history';
export const homeStation = '/home/station';
export const homeSettings = '/home/settings';
export const homeProfile = '/home/profile';
export const homeSupport = '/home/support';

export const settingsSocial = 'settings';

export const faq = 'https://www.electricmiles.io/faq/';
export const evApiUrl = `https://connect.evapi.io/?app_id=${envObject.react_app_ev_api_app_id}&callback=http://localhost/callback`;

//root
export const registered = '/registered';
export const restrictedHours = '/restricted_hours';
export const solar = '/solar';
export const resetPassword = '/reset_password';
export const signIn = '/sign_in';
export const signInWithout = 'sign_in';
export const signUp = '/sign_up';
export const signUpWithout = 'sign_up';
export const test = '/test';
export const changePassword = '/change_password';
export const authCar = '/authorize_car';
export const connectCar = '/connect_car';
export const addCharger = '/add_charger';
export const addSupplier = '/add_supplier';
export const root = '/';
export const addTrip = '/add_trip';
export const changeOpinion = '/change_opinion';
export const deleteAccount = '/delete_account';
export const privacyPolicy = 'https://electricmiles.com/privacy/';
export const noFleet = '/no_fleet';
export const socialPage = '/social_page';
export const welcomePage = '/welcome';
export const optimiseChargePage = '/optimise_charge';
export const socialOptimiseChargePage = 'optimise_charge';
export const emailVerification = '/email_verification';
export const emailVerificationSocial = 'email_verification';
export const settingResetPassword = '/setting_reset_password';
export const initial = '/initial';
export const waitingList = '/waiting-list';
export const confirmation = '/confirmation';
export const earlyAccess = '/early-access';
export const earlyAccessSignUp = '/early-access-sign-up';
export const notFound = '*';
export const termsOfService = 'https://electricmiles.com/terms/';
