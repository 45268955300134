import React from 'react';

import s from './Checkbox.module.scss';
import classNames from 'classnames';

const Checkbox = React.forwardRef(
    (
        {
            errors,
            value,
            id,
            name,
            checked,
            onChange,
            type,
            disable,
            onBlur,
            className
        },
        ref
    ) => {
        return (
            <label
                htmlFor={id}
                className={classNames(
                    { [s.error]: errors },
                    s.checkBox,
                    className
                )}
            >
                <input
                    className={s.input}
                    onChange={onChange}
                    type={type}
                    value={value}
                    id={id}
                    name={name}
                    checked={checked}
                    disabled={disable}
                    ref={ref}
                    onBlur={onBlur}
                />
                <span className={s.checkMark}>
                    <span className={s.mark} />
                </span>
            </label>
        );
    }
);

Checkbox.defaultProps = {
    type: 'checkbox'
};

export default Checkbox;
