import { Api } from 'api'

class SocialRegistrationApi extends Api {
    makeUrl(path: string) {
        return `${this.baseUrl}social-network/${path}`;
    }

    registrationGoogle(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('registration/google?' + urlParams)
    }

    registrationFacebook(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('registration/facebook?' + urlParams)
    }

    registrationLinkedin(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('registration/linkedin?' + urlParams)
    }

    registrationApple(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('registration/apple?' + urlParams)
    }

}

const socialRegistrationApi = new SocialRegistrationApi()
export default socialRegistrationApi;