import React, { useEffect, useState } from 'react';
import s from "./TurnOffModal.module.scss";
import { InformationModal } from 'components';
import { Message } from 'components';
import { useTranslation } from 'react-i18next';

const TurnOffModal = ({ style, children, onClick, visible }) => {
    const {t} = useTranslation("misc");
    const [isVisible, setVisible] = useState(visible);

    useEffect(() => {
        setVisible(visible);
    },[visible]);

    const close = () => {
        onClick();
        setVisible(false);
    };

    if (isVisible) {
        return (
            <div className={s.main} style={{ ...style }}>
                <Message cornerPosition="rightCorner" className={s.message}>
                    <div className={s.contentContainer}>
                        <div>{children}</div>
                        {/* <button className={s.button} onClick={close}>
                            {t("ok", "OK")}
                        </button> */}
                    </div>
                </Message>
            </div>
        );
    }

    return null;
};

InformationModal.defaultProps = {
    style: {
        right: '50px',
        bottom: '55px'
    }
};

export default TurnOffModal;
