import React from 'react';
import s from './CarCard.module.scss';
import shape from 'assets/images/Shape.png';
import { convertMilesToKm } from 'utils';
import { useTreatments } from 'hooks';
import { supportKilometers } from 'constants/treatments';

const CarCard = ({ icon, title, model, batteryRange, batterySize }) => {
    const [showSupportKilometers] = useTreatments([supportKilometers]);

    return (
        <div className={s.main}>
            <div className={s.check}>
                <img src={shape} alt="" />
            </div>
            <div className={s.grid}>
                <div className={s.car}>
                    <img src={icon} alt="icon" />
                    <span>{title}</span>
                </div>
                <div className={s.valueTitle}>Model</div>
                <div className={s.value}>{model}</div>
                <div className={s.valueTitle}>Battery range</div>
                <div className={s.value}>
                    {showSupportKilometers
                        ? `${convertMilesToKm(batteryRange)} km`
                        : `${batteryRange} mi`}
                </div>
                <div className={s.valueTitle}>Battery Size</div>
                <div className={s.value}>{batterySize} kWh</div>
            </div>
        </div>
    );
};

export default CarCard;
