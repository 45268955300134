import React from 'react';
import s from './Oops.module.scss';
import iconAlert from './images/iconAlert.svg';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const Oops = ({ error, errorInfo }) => {
    const {t} = useTranslation("misc");
    return (
        <IonPage id="ionic-page">
            <IonContent>
                <div className={s.main}>
                    <div className={s.image}>
                        <img src={iconAlert} alt="" />
                    </div>
                    <div className={s.text}>
                        <h3> {t("oops", "Oops!")}</h3>
                        <p>{t("sww", "Something went wrong")} </p>
                    </div>
                    <div className={s.error}>
                        {error.message}
                        <br />
                        {errorInfo && errorInfo.componentStack.toString()}
                    </div>
                    <div className={s.backLink}>
                        <a href="/">{t("restart", "Restart the application")} </a>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Oops;
