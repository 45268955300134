import React from 'react';
import { useFormik } from 'formik';

import { Button, GooglePlaceAutocomplete, WarningMessage } from 'components';
import s from './YourPlaces.module.scss';
import { mixpanel } from 'utils';
import yupSchemaGenerator from 'utils/yupSchemaGenerator';
import { useTranslation } from 'react-i18next';
import SignUpStore from '../SignUpStore.mobx';
import { siginInAnalytics } from 'pages/SignIn/SignIn.analytics';
import { useLocation } from 'react-router';

const YourPlacesForm = () => {
    const { t } = useTranslation('signup');
    const location = useLocation();
    const emailFromURL = location?.state?.email;
    const validation = SignUpStore.formValidation
        ? {
              homeAddress: SignUpStore.formValidation.homeAddressAddress,
              homePostCode: SignUpStore.formValidation.homeAddressPostCode
          }
        : null;

    const formik = useFormik({
        initialValues: {
            homeAddress: '',
            homePostCode: '',
            homeAddressCoordinates: null,
            homeAddressPlaceId: null
        },
        onSubmit: (data) => {
            mixpanel.track('Signup', { 'Step': 'Places' });
            SignUpStore.setHomeAddress = data;
            SignUpStore.saveProfile(emailFromURL);
            siginInAnalytics.sendChargerAddressEvent({
                postCode: data?.homePostCode,
                address: data?.homeAddress
            });
        },
        validationSchema: yupSchemaGenerator(validation),

        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false
    });
    const {
        handleSubmit,
        errors,
        values,
        setFieldValue,
        setFieldError,
        handleBlur
    } = formik;

    const errorsArray = Object.keys(errors);

    const address_filled_in = () => {
        return Object.values({
            'homePostCode': formik.values.homePostCode,
            'homeAddress': formik.values.homeAddress,
            'homeAddressCoordinates': formik.values.homeAddressCoordinates
        }).every((t) => t);
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className={s.textContainer}>
                <p className={s.text}>
                    {t(
                        'setYouPostcode',
                        'Your postcode will be used for selecting your supplier tariff and smart charging'
                    )}
                </p>
            </div>
            {!!errors[errorsArray[0]] && errorsArray[0] === 'homePostCode' && (
                <WarningMessage
                    error={errors[errorsArray[0]]}
                    className={s.warning}
                />
            )}
            <GooglePlaceAutocomplete
                name="homePostCode"
                className={s.input}
                placeholder={t('placeholder.homePostcode', 'Home postcode*')}
                value={values.homePostCode}
                initialValue={values.homePostCode}
                errors={errors.homePostCode}
                type="postcode"
                setCoordinates={(coordinates) =>
                    setFieldValue('homeAddressCoordinates', coordinates)
                }
                onChange={(value, input) => {
                    value && setFieldValue('homeAddress', value);
                    setFieldValue('homePostCode', input || '');
                }}
            />
            {!!errors[errorsArray[0]] && errorsArray[0] === 'homeAddress' && (
                <WarningMessage
                    error={errors[errorsArray[0]]}
                    className={s.warning}
                />
            )}
            <GooglePlaceAutocomplete
                onBlur={handleBlur}
                className={s.input}
                type="text"
                name="homeAddress"
                setFieldError={setFieldError}
                onChange={(value) => setFieldValue('homeAddress', value)}
                value={values.homeAddress}
                initialValue={values.homeAddress}
                errors={errors.homeAddress}
                setId={(id) => setFieldValue('homeAddressPlaceId', id)}
                setCoordinates={(coordinates) =>
                    setFieldValue('homeAddressCoordinates', coordinates)
                }
                placeholder={t('placeholder.homeAddress', 'Home Address')}
                setPostCode={(value) =>
                    setFieldValue('homePostCode', value || '')
                }
            />
            <div className={s.submitButton}>
                <Button type="submit" disabled={!address_filled_in()}>
                    {t('button.continue', 'Continue')}
                </Button>
            </div>
        </form>
    );
};

export default YourPlacesForm;
