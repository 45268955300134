import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import s from './Autocomplete.module.scss';
import searchIcon from './image/search.png';
import { useTranslation } from 'react-i18next';

const CustomAutocomplete = ({ onLoad, onPlaceChanged }) => {
    const { t } = useTranslation('map');

    return (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <div className={s.autocomplete}>
                <label className={s.inputContainer} htmlFor="search">
                    <input
                        type="text"
                        placeholder={t(
                            'placeholder.search',
                            'Search by postcode, town or city'
                        )}
                        className={s.autocompleteInput}
                        id="search"
                    />
                    <img src={searchIcon} alt="" />
                </label>
            </div>
        </Autocomplete>
    );
};

export default CustomAutocomplete;
