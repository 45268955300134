import { makeAutoObservable } from 'mobx';
import { RequestLoadingStore, NotificationStore } from 'components';
import EventEmitter from 'utils/EventEmitter';
import { customerApi, validationRulesApi, cognitoApi } from 'api';
import { myHistory, mixpanel, saveToLocalStorage } from 'utils';
import { registered, welcomePage } from 'constants/routes';
import { envObject } from 'api/envSwithcer';
import { siginInAnalytics } from 'pages/SignIn/SignIn.analytics';
import ProfileStoreMobx from 'pages/Profile/ProfileStore.mobx';

class SignUpStore {
    formValidation;
    data;
    wizard;

    constructor() {
        this.reset();
        makeAutoObservable(this);
        EventEmitter.addEventListener('logOut', this.reset);
    }

    set setData(data) {
        this.data = { ...data };
    }

    set setHomeAddress(data) {
        this.data.homeAddress = {
            address: data.homeAddress,
            latitude: data.homeAddressCoordinates.lat,
            longitude: data.homeAddressCoordinates.lng,
            postCode: data.homePostCode
        };
    }

    getSignUpFormValidation = async () => {
        RequestLoadingStore.showLoading();
        try {
            this.formValidation = await validationRulesApi.getRules(
                'registration'
            );
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    signUp = async (payload) => {
        const { email, password } = payload;

        RequestLoadingStore.showLoading();
        try {
            const response = await cognitoApi.signUp({
                username: email,
                password
            });
            const USER_ID = response.data;
            mixpanel.identify(USER_ID);
            mixpanel.people.set({
                'app_version': `${envObject.react_app_version}`
            });
            siginInAnalytics.sendSuccessNotificationEvent(email);
            myHistory.history.push({
                pathname: registered,
                state: {
                    email: email
                }
            });
        } catch (error) {
            siginInAnalytics.sendFailureAccountExistEvent(email, error);
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    saveProfile = async (emailFromURL) => {
        RequestLoadingStore.showLoading();
        try {
            const dataForRequest = {
                accountType: this.data.accountType,
                homeAddress: this.data.homeAddress,
                chargeSettings: { dayStart: '00:00' }
            };
            const [, customerData] = await Promise.all([
                cognitoApi.updateName(this.data.firstName, this.data.lastName),
                customerApi.edit(dataForRequest)
            ]);
            const { data } = customerData;
            saveToLocalStorage('roles', JSON.stringify(data.roles));
            localStorage.removeItem('wizard');
            ProfileStoreMobx.getProfile();
            myHistory.history.push({
                pathname: welcomePage,
                state : {
                    email:emailFromURL
                }
            });
            this.reset();
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    reset = () => {
        this.formValidation = null;
        this.data = {
            accountType: 1,
            firstName: '',
            lastName: '',
            termsAndPolicy: true,
            homeAddress: null,
            depotAddress: null
        };
        this.wizard = {};
    };
}

export default new SignUpStore();
