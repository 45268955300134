import React from 'react';
import { HighchartsCircleProgress } from 'components';
import variables from 'assets/styles/variables.module.scss';

const CircleChargeProgress = ({
    max,
    current,
    bgColor,
    circleColor,
    children,
    className
}) => {
    const styleSettings = {};
    const currentState = (current * 100) / max;

    if (currentState >= 50) {
        styleSettings.progressColor = '#11AF3C';
        styleSettings.progressBackGroundColor = 'rgba(17,175,60, 0.2)';
    }

    if (25 <= currentState && currentState < 50) {
        styleSettings.progressColor = '#e8be37';
        styleSettings.progressBackGroundColor = 'rgba(232, 190, 55, 0.2)';
    }

    if (currentState < 25) {
        styleSettings.progressColor = '#ec3c39';
        styleSettings.progressBackGroundColor = 'rgba(236, 60, 57, 0.2)';
    }

    return (
        <HighchartsCircleProgress
            current={current}
            max={max}
            backgroundColor={styleSettings.progressBackGroundColor}
            color={styleSettings.progressColor}
            className={className}
        >
            {children}
        </HighchartsCircleProgress>
    );
};

CircleChargeProgress.defaultProps = {
    max: 100,
    current: 0,
    bgColor: variables.neutralLight,
    circleColor: '#c3e3ce',
    progressColor: '#4faf3e'
};

export default CircleChargeProgress;
