import { Capacitor } from '@capacitor/core';
import { envObject } from 'api/envSwithcer';

export default function getSocialUrl(
    social: string,
    page: string,
    tab: string,
    webPage: string
) {
    const isTest = envObject.react_app_environment === 'test';
    const defaultSocialUrl = isTest
        ? 'https://dashboard.test.electricmiles.io/social_page'
        : 'https://dashboard.electricmiles.io/social_page';
    const iosSocialLogin = 'https://api.electricmiles.io/index.html';

    if (Capacitor.platform === 'web') {
        const urlParams = new URLSearchParams();
        urlParams.set('type', social);
        tab && urlParams.set('tab', tab);

        return window.location.origin + webPage + '?' + urlParams;
    }

    if (social === 'google') {
        return `${defaultSocialUrl}?page=${page}&type=${social}`;
    }

    if (Capacitor.platform !== 'ios') {
        return `${defaultSocialUrl}?page=${page}&type=${social}`;
    }

    return `${iosSocialLogin}?page=${page}&type=${social}&pageUrl=${defaultSocialUrl}`;
}
