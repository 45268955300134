import React from 'react';
import moment from 'moment-timezone';
import s from './Trip.module.scss';
import { ReactComponent as Corner } from './image/corner.svg';
import iconTime from './image/icon_time.png';
import close from './image/close.png';
import map from './image/map.png';
import { CircleChargeProgress } from 'components';
import { round } from 'utils';
import { Link } from 'react-router-dom';
import { homeMap } from 'constants/routes';

import { convertMilesToKm } from 'utils';
import { supportKilometers } from 'constants/treatments';
import { useTreatments } from 'hooks';

const Trip = ({ mill, time, name, remainingBattery, id, remove, eventId }) => {
    const [showSupportKilometers] = useTreatments([supportKilometers]);

    const removeHandler = () => {
        remove(eventId);
    };
    return (
        <div className={s.main}>
            <div className={s.map}>
                <div className={s.mapCircle} />
                <div className={s.mapStreet} />
            </div>
            <div className={s.container}>
                <div className={s.corner}>
                    <Corner />
                </div>
                <div className={s.content}>
                    <div className={s.timeAndLength}>
                        <div className={s.length}>
                            <span className={s.value}>
                                {showSupportKilometers
                                    ? convertMilesToKm(mill)
                                    : mill}
                            </span>
                            <span className={s.mill}>
                                {showSupportKilometers ? 'km' : 'mi'}
                            </span>
                        </div>
                        <div className={s.time}>
                            <div>
                                <img src={iconTime} alt="" />
                            </div>
                            <div className={s.value}>
                                {moment(time).tz('GMT').format('HH:mm')}
                            </div>
                        </div>
                    </div>
                    <div className={s.nameContainer}>
                        <span className={s.name}>Name</span>
                        <span className={s.title}>{name}</span>
                    </div>
                    <div className={s.remainingContainer}>
                        <span className={s.name}>remaining battery</span>
                        <div className={s.chart}>
                            <CircleChargeProgress
                                max={100}
                                current={remainingBattery}
                                className={s.tripChart}
                            >
                                <div className={s.circleChildren}>
                                    <span className={s.remainingBatteryValue}>
                                        {' '}
                                        {round(remainingBattery, 1)}
                                    </span>
                                    <span className={s.percent}>%</span>
                                </div>
                            </CircleChargeProgress>
                        </div>
                    </div>
                    <div className={s.buttonContainer}>
                        <Link
                            className={s.map}
                            to={{
                                pathname: homeMap,
                                search: `?eventId=${eventId}`
                            }}
                        >
                            <img src={map} alt="" />
                        </Link>
                        {eventId && (
                            <div className={s.remove} onClick={removeHandler}>
                                <img src={close} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Trip;
