/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonContent, IonSelect, IonSelectOption } from '@ionic/react';
import { useWizard } from 'hooks';
import s from './SignUp.module.scss';
import SignUpAdvantages from './SignUp.advantages';
import SignUpFormContainer from './SignUp.formContainer';
import { CookiesModal } from 'components';
import { useLocation } from 'react-router-dom';
import SignUpStore from './SignUpStore.mobx';
import { envkeys, envObject } from 'api/envSwithcer';
import {IsDebug} from "@awesome-cordova-plugins/is-debug";
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';

const SignUp = () => {
    const location = useLocation();

    const wizard = useWizard(location.state?.firstLogin ? 1 : 0);
    const [showSelect, setshowSelect] = useState(false);
    const [enableSwitcher, setEnableSwitcher] = useState(true);
    const [noOfTaps, setNoOfTaps] = useState(0); // need 5 to show select
    useEffect(() => {
        if(noOfTaps > 5) {
            setshowSelect(true);
        }
    }, [noOfTaps]);
    const { state } = wizard;

    useEffect(() => {
        SignUpStore.wizard = wizard;
        SignUpStore.getSignUpFormValidation();
    }, []);

    const checkIfProd = () => {
        if(envObject.react_app_environment === envkeys.prod.value){
            setEnableSwitcher(false)
        }
    }

    useEffect(() => checkIfProd(), []);
    return (
        <IonContent>
            <div className={s.SignUp}>
                {enableSwitcher && <>
                {!showSelect && <div className={s.hiddenTap} onClick={()=> setNoOfTaps(prevState => prevState + 1)}></div>}
                {IsDebug && showSelect && <IonSelect
                    aria-hidden
                    className={s.envSelect}
                    mode="ios"
                    interface="action-sheet"
                    defaultValue={'PROD'}
                    onIonChange={(e) => {
                        envObject.changeKeys(e.detail.value);
                    }}
                >
                    <IonSelectOption value={'PROD'}>Production</IonSelectOption>
                    <IonSelectOption value={'STAGE_ONE'}>Stage 1</IonSelectOption>
                    <IonSelectOption value={'STAGE_TWO'}>Stage 2</IonSelectOption>
                    <IonSelectOption value={'STAGE_THREE'}>Stage 3</IonSelectOption>
                    <IonSelectOption value={'TEST'}>Test</IonSelectOption>
                </IonSelect>}
                </>}
                <div className={s.gridSignUp}>
                    <SignUpAdvantages wizardState={state} />
                    <SignUpFormContainer wizard={wizard} />
                </div>
            </div>
            {/* <CookiesModal /> */}
        </IonContent>
    );
};

export default SignUp;
