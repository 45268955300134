import { AuthApi } from 'api';

interface List {
    offset: any;
    limit: any;
    begin: any;
    end: any;
    orderBy: any;
    order: any;
}

interface totalsBody {
    fromDate: string;
    toDate: string;
}
interface toggleRandDelay {
    randomDelay: boolean;
}

class SessionsApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}sessions/${path}`;
    }

    list({ offset, limit, begin, end, orderBy, order }: List) {
        const urlParams = new URLSearchParams();
        (offset || offset === 0) && urlParams.set('offset', offset);
        limit && urlParams.set('limit', limit);
        begin && urlParams.set('begin', begin);
        end && urlParams.set('end', end);
        orderBy && urlParams.set('orderBy', orderBy);
        order && urlParams.set('order', order);

        return this.get('list?' + urlParams);
    }

    csv(fromDate: string, toDate: string) {
        const urlParams = new URLSearchParams();
        urlParams.set('from_date', fromDate);
        urlParams.set('to_date', toDate);

        return this.get('csv?' + urlParams);
    }

    history(dateURLParams: string | '') {
        if (dateURLParams) return this.get(`history?${dateURLParams}`);
        else return this.get(`history`);
    }
    totals(body: totalsBody) {
        return this.post('totals', body);
    }

    nearestAutomated() {
        return this.get('schedules/nearest/automated');
    }
    nearestScheduled() {
        return this.get('schedules/nearest');
    }
    toggleRandDelay(sessionId: number, body: toggleRandDelay) {
        return this.put(`${sessionId}/random-delay`, body);
    }

    nearestAutomatedCancel() {
        return this.get('schedules/nearest/automated/cancel');
    }
}

const sessionsApi = new SessionsApi();
export default sessionsApi;
