import React from 'react';
import tripMarker from './images/tripMarker.png';
import { MarkerClusterer, Marker } from '@react-google-maps/api';

const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
};

function index({ events, setInfo, currentPosition }) {
    const clickHandler = (address) => {
        setInfo({
            title: address.name,
            descriptionTitle: '',
            description: address.address,
            saddr: currentPosition,
            daddr: {
                lat: address.latitude,
                lng: address.longitude
            }
        });
    };

    return (
        <MarkerClusterer options={options}>
            {(clusterer) =>
                events.map((item) => {
                    if (!item.latitude || !item.longitude) return null;
                    return (
                        <Marker
                            key={item.id}
                            position={{
                                lat: item.latitude,
                                lng: item.longitude
                            }}
                            clusterer={clusterer}
                            icon={tripMarker}
                            animation={window.google.maps.Animation.DROP}
                            onClick={() => clickHandler(item)}
                        />
                    );
                })
            }
        </MarkerClusterer>
    );
}

export default index;
