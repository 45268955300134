// component for testing others components. This component open on /test root.
import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import EventEmitter from 'utils/EventEmitter';

const Test = () => {
    console.log('test')
    return (
        <IonPage id="ionic-page">
            <IonContent>
                <button onClick={EventEmitter.logOut}>logOut</button>
            </IonContent>
        </IonPage>
    );
};

export default Test;
