// @ts-ignore-file
import React, { Suspense, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import {
    IonApp,
    useIonAlert
    //  IonRouterOutlet
} from '@ionic/react';
// import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Capacitor } from '@capacitor/core';
import router from './App.router';
import {
    RequestLoading,
    ErrorBoundary,
    AppUrlListener,
    Notification,
    SplitWrapper
} from 'components';
import { HistoryProvider } from 'pages';
import { Switch } from 'react-router-dom';
import { setupConfig } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { mixpanel } from 'utils';
import './i18n';
import { earlyAccess } from 'constants/treatments';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import Amplify from 'aws-amplify';
import HardwareBackButton from 'components-v2/HardwareBackButton';
import { envObject } from 'api/envSwithcer';
import { compareVersions } from 'compare-versions';
import { useFirebaseVersioning } from 'firebase/useFirebaseVersioning';

// const { Keyboard } = Plugins;

// if (Capacitor.platform !== 'web') {
//     Keyboard.setAccessoryBarVisible({
//         isVisible: true
//     });
// }

setupConfig({
    swipeBackEnabled: false
});

mixpanel.init(
    envObject.react_app_mixpanel_token,
    {
        'api_host': `${envObject.react_app_mixpanel_url}`,
        debug: true,
        ignore_dnt: true
    },
    ''
);

Amplify.configure({
    Auth: {
        // Amazon Cognito Region
        region: `${envObject.react_app_aws_region}`,
        // Amazon Cognito User Pool ID
        userPoolId: envObject.react_app_userpool_id,
        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: envObject.react_app_cognito_client_id
    }
});

const App: React.FC = () => {
    // const [present] = useIonAlert();
    // const {forceUpdate, remoteVersion, appVersion, redirectionToStore} = useFirebaseVersioning()
    // useEffect(() => {
    //     if (forceUpdate && remoteVersion && appVersion && Boolean(compareVersions(remoteVersion, appVersion) > 0)) //return 1 (true)
    //         present({
    //             mode: 'ios',
    //             header: 'New version available',
    //             message: `There is new version of Electic Miles available v${remoteVersion}`,
    //             cssClass: 'restrictedHoursPrompt',
    //             buttons: [
    //                 { text: 'Cancel', handler: (d) => { } },
    //                 {
    //                     text: 'Update',
    //                     handler: (d) => {
    //                         redirectionToStore();
    //                     }
    //                 }
    //             ]
    //         });
    // }, [forceUpdate, appVersion, remoteVersion]);
    return (
        <SplitWrapper>
            <IonApp id="ionic_app">
                <Suspense fallback="">
                    <ErrorBoundary>
                        <AppUrlListener />
                        <Router>
                            <HistoryProvider />
                            {/* <HardwareBackButton /> */}
                            <SplitTreatments names={[earlyAccess]}>
                                {({ treatments, isReady }) => {
                                    if (!isReady) return null;
                                    return (
                                        <Switch>
                                            {router.map((item: any, index) => {
                                                if (
                                                    treatments[item.split]
                                                        ?.treatment === 'off'
                                                )
                                                    return null;
                                                return (
                                                    <Route
                                                        path={item.path}
                                                        component={
                                                            item.component
                                                        }
                                                        exact={item.exact}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                        </Switch>
                                    );
                                }}
                            </SplitTreatments>
                            <Notification />
                        </Router>
                        <RequestLoading />
                    </ErrorBoundary>
                </Suspense>
            </IonApp>
        </SplitWrapper>
    );
};

export default App;
