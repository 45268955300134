import React from 'react';
import stationMarker from './images/stationMarker.png';
// import stationIcon from './images/stationMarker.svg'
import { MarkerClusterer, Marker } from '@react-google-maps/api';

const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
};

// const stationIcon = `<svg width="58" height="61" viewBox="0 0 58 61" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path fill-rule="evenodd" clip-rule="evenodd" d="M41 53C50.3888 53 58 45.3888 58 36L58 17C58 7.61116 50.3888 3.14931e-06 41 2.32851e-06L17 0C7.61116 0 3.96802e-06 7.61116 3.14722e-06 17L0 36C0 45.3888 7.61116 53 17 53L19.0775 53C20.2926 53 21.4419 53.5524 22.201 54.5012L25.8765 59.0957C27.4778 61.0973 30.5222 61.0973 32.1235 59.0957L35.799 54.5012C36.5581 53.5524 37.7074 53 38.9225 53H41Z" fill="gold" />
//         <path d="M29.4439 15.7675C29.7619 15.1407 30.5938 14.7646 31.2054 15.1658C31.6702 15.4416 31.7436 16.0433 31.6213 16.5196C31.1075 18.8763 30.5693 21.233 30.1044 23.5896C32.3064 23.6147 34.4838 23.5646 36.6857 23.6147C37.8356 23.715 38.3739 25.3446 37.7133 26.2221C34.704 30.9354 31.7681 35.6989 28.7833 40.4123C28.294 41.365 26.6548 41.1143 26.6548 39.9861C27.1686 37.2784 27.9025 34.5958 28.5142 31.8881C26.508 31.8881 24.4773 31.9132 22.4711 31.8881C21.1989 31.8881 20.6362 30.1582 21.2478 29.1805C23.9391 24.6677 26.7282 20.2301 29.4439 15.7675Z" fill="#88D79D" />
//     </svg>`

function index({ chargePoint, setInfo, currentPosition }) {
    const clickHandler = (item) => {
        const address = item.chargeDeviceLocation.address;

        setInfo({
            title: item.chargeDeviceName,
            descriptionTitle: '',
            description: `${address.county || ''} ${address.street || ''} ${
                address.postCode || ''
            }`,
            saddr: currentPosition,
            daddr: {
                lat: item.chargeDeviceLocation.location.latitude,
                lng: item.chargeDeviceLocation.location.longitude
            }
        });
    };

    return (
        <MarkerClusterer options={options}>
            {(clusterer) =>
                chargePoint.map((item) => {
                    return (
                        <Marker
                            key={item.chargeDeviceId}
                            position={{
                                lat:
                                    item.chargeDeviceLocation.location.latitude,
                                lng:
                                    item.chargeDeviceLocation.location.longitude
                            }}
                            clusterer={clusterer}
                            // icon={{ url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(stationIcon) }}
                            icon={stationMarker}
                            animation={window.google.maps.Animation.DROP}
                            onClick={() => clickHandler(item)}
                        />
                    );
                })
            }
        </MarkerClusterer>
    );
}

export default index;
