import React from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import s from './HighchartsCircleProgress.module.scss';

import HCMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
HCMore(Highcharts);
SolidGauge(Highcharts);

const HighchartsCircleProgress = ({
    max,
    current,
    color,
    backgroundColor,
    children,
    className,
    animation = true
}) => {
    const options = {
        chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'none'
        },

        title: null,

        tooltip: {
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            enabled: false
        },

        pane: {
            startAngle: 0,
            endAngle: 360,
            background: {
                outerRadius: '100%',
                innerRadius: '88%',
                backgroundColor: backgroundColor,
                borderWidth: 0
            }
        },

        yAxis: {
            min: 0,
            max: max,
            lineWidth: 0,
            tickPositions: []
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: false,
                animation
            }
        },

        series: [
            {
                name: 'Move',
                type: 'solidgauge',
                data: [
                    {
                        color: color,
                        radius: '100%',
                        innerRadius: '88%',
                        y: current
                    }
                ]
            }
        ]
    };

    return (
        <div className={classNames(s.main, className)}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={'chart'}
            />
            <div className={s.current}>{children}</div>
        </div>
    );
};

export default HighchartsCircleProgress;
