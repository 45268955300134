/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import s from './Station.module.scss';
import FirstScreen from './FirstScreen.jsx';
import SecondScreen from './SecondScreen.jsx';
import { MobileHeader, SearchInput, AddMobile } from 'components';
import { observer } from 'mobx-react-lite';
import StationStore from './StationStore.mobx';
const Station = ({ showNavBar }) => {
    const [showAppButton, setShowAppButton] = useState(false);

    const debounceScroll = throttle((element) => {
        if (
            element.scrollHeight - element.scrollTop - element.clientHeight <
            200
        ) {
            return setShowAppButton(true);
        }
        return setShowAppButton(false);
    }, 300);

    const scrollHandle = (e) => {
        if (window.innerWidth <= 800) {
            debounceScroll(e.target);
        }
    };

    const loadFunc = () => {
        if (StationStore.metadata.offset < StationStore.metadata.total)
            StationStore.getStationList(StationStore.metadata);
    };

    useEffect(() => {
        StationStore.getControlCenter();
        loadFunc();
    }, []);

    return (
        <>
            <MobileHeader
                onClick={showNavBar}
                middleButton={showAppButton ? <SearchInput /> : undefined}
                rightButton={showAppButton ? <AddMobile /> : undefined}
            />
            <div className={s.background}>
                <div className={s.main} onScroll={scrollHandle}>
                    <FirstScreen />
                    <SecondScreen loadFunc={loadFunc} />
                </div>
            </div>
        </>
    );
};

export default observer(Station);
