import React from 'react';
import { Select, Option } from 'components';
import { Card } from '../';
import s from '../../FirstScreen.module.scss';
import cherDown from '../../image/cherDown.png';
import { useTranslation } from 'react-i18next';

const NewUsersCard = ({ value }) => {
    const {t} = useTranslation('misc')
    return (
        <Card
            title="New users"
            rightTitle={
                <div>
                    <Select
                        id="time"
                        options={{
                            selectedContainer: s.selectedContainer,
                            cherDownIcon: cherDown,
                            activeSelectedContainer: s.activeSelectedContainer,
                            childrenContainer: s.childrenContainer
                        }}
                        initialValue={'today'}
                    >
                        <Option value="today" className={s.option}>
                        {t("chargerSession.today", "Today")}      
                        </Option>
                        <Option value="month" className={s.option}>
                        {t("chargerSession.month", "Month")}      
                        </Option>
                    </Select>
                </div>
            }
        >
            <div className={s.valueContainerWithFooter}>
                <div className={s.valueAndMeasure}>
                    <h3 className={s.value}>{value}</h3>
                </div>
                <div className={s.noData}>
                    <span className={s.increaseValue}>No current data</span>
                </div>
            </div>
        </Card>
    );
};

export default NewUsersCard;
