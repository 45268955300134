import React, { useEffect, useRef, useState } from 'react';
import s from './Information.module.scss';
import { Message } from 'components';
import vector from 'assets/images/Vector.png';
import classNames from 'classnames';

const Information = ({ icon, children, width, initialPosition }) => {
    const mainRef = useRef(null);
    const [position, setPosition] = useState(initialPosition || 'center');
    useEffect(() => {
        if (!initialPosition) {
            setTimeout(() => {
                if (
                    mainRef?.current?.getBoundingClientRect().right + 111 >
                    window.innerWidth
                ) {
                    return setPosition('right');
                }
                if (mainRef?.current?.getBoundingClientRect().top < 200) {
                    return setPosition('bottom');
                }
            }, 100);
        }
    }, [initialPosition]);

    return (
        <div className={s.main} ref={mainRef}>
            <div className={s.icon}>
                <img src={icon} alt="" />
            </div>
            <div
                style={{ width }}
                className={classNames(s.messageContainer, {
                    [s.rightMessageContainer]: position === 'right',
                    [s.bottomMessageContainer]: position === 'bottom',
                    [s.bottomRightMessageContainer]:
                        position === 'bottom-right',
                    [s.bottomLeftMessageContainer]: position === 'bottom-left'
                })}
            >
                <Message cornerPosition={initialPosition || position}>
                    {children}
                </Message>
            </div>
        </div>
    );
};

Information.defaultProps = {
    icon: vector,
    children: 'Test text',
    width: ''
};

export default Information;
