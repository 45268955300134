import { analyticsManager } from 'analytics';
import ProfileStoreMobx from 'pages/Profile/ProfileStore.mobx';
import analyticsKey from 'analytics/analytics.keys';
import { cognitoApi } from 'api';

class ChargerAnalytics {
    sendCloseButtonEvent = () => {
        analyticsManager.logEvent(analyticsKey.pressCloseButton.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressCloseButton.pageName,
            SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
        });
    };

    sendCloseButtonAltEvent = () => {
        analyticsManager.logEvent(analyticsKey.pressCloseButtonAlt.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressCloseButtonAlt.pageName,
            SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
        });
    };
    sendDownloadConnectMilesEvent = (serialNumber:string) => {
        return analyticsManager.logEvent(
            analyticsKey.pressDownloadConnectMiles.eventName,
            {
                email: ProfileStoreMobx?.email,
                pageName: analyticsKey.pressDownloadConnectMiles.pageName,
                SerialNumber: serialNumber || ProfileStoreMobx?.stations[0]?.charge_box_identity
            }
        );
    };
    sendDownloadConnectMilesAltEvent = (serialNumber:string) => {
        return analyticsManager.logEvent(
            analyticsKey.pressDownloadConnectMilesAlt.eventName,
            {
                email: ProfileStoreMobx?.email,
                pageName: analyticsKey.pressDownloadConnectMilesAlt.pageName,
                SerialNumber: serialNumber || ProfileStoreMobx?.stations[0]?.charge_box_identity
            }
        );
    };
    sendQRScanCodeEvent = () => {
        analyticsManager.logEvent(analyticsKey.pressQRScanCode.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressQRScanCode.pageName,
            SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity
        });
    };
    sendConnectChargerEvent = (data: any) => {
        analyticsManager.logEvent(analyticsKey.pressConnectCharger.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressConnectCharger.pageName,
            SerialNumber: ProfileStoreMobx?.stations[0]?.charge_box_identity,
            ...data
        });
    };
    sendSuccessChargerConnectEvent = (data:any) => {
        analyticsManager.logEvent(
            analyticsKey.successfullChargerConnect.eventName,
            {
                email: ProfileStoreMobx?.email,
                pageName: analyticsKey.successfullChargerConnect.pageName,
                make:data?.make,
                model:data?.model,
                postcode:data?.postcode,
                selectedCharger:data?.selectedCharger,
                serialNumber:data?.serialNumber,
            }
        );
    };
    sendApiFailedChargerConnectEvent = (data:any, error?:any) => {
        analyticsManager.logEvent(analyticsKey.apiFailedChargerConnect.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.apiFailedChargerConnect.pageName,
            ...data,
            error:error
        });
    };
    sendFailedChargerConnectEvent = (data:any, error?:any) => {
        analyticsManager.logEvent(analyticsKey.failedChargerConnect.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.failedChargerConnect.pageName,
            ...data,
            error:error
        });
    };
    sendEditChargerEvent = (serialNumber:string, error:any) => {
        analyticsManager.logEvent(analyticsKey.pressEditCharger.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressEditCharger.pageName,
            SerialNumber: serialNumber || ProfileStoreMobx?.stations[0]?.charge_box_identity,
            error:error
        });
    };
    sendContactUsEvent = (serialNumber:string) => {
        return analyticsManager.logEvent(analyticsKey.pressContactUs.eventName, {
            email: ProfileStoreMobx?.email,
            pageName: analyticsKey.pressContactUs.pageName,
            SerialNumber: serialNumber || ProfileStoreMobx?.stations[0]?.charge_box_identity
        });
    };
    sendAddChargeSettingsEvent = () => {
        analyticsManager.logEvent(
            analyticsKey.pressAddChargeSettings.eventName,
            {
                email: ProfileStoreMobx?.email,
                pageName: analyticsKey.pressAddChargeSettings.pageName
            }
        );
    };
}

export const chargerAnalytics = new ChargerAnalytics();
