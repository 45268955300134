import React from 'react';
import s from './Button.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function Button({
    children,
    className,
    sm,
    md,
    lg,
    ...props
}) {
    return (
        <Link
            {...props}
            className={classNames(
                s.button,
                { [s.md]: md },
                { [s.lg]: lg },
                className
            )}
        >
            {children}
        </Link>
    );
}
