import React from 'react';
import s from './StepConnection.module.scss';
import { Header, Steps } from 'components';
import classNames from 'classnames'

const StepConnection = ({ stepSettings, wizard, Step, isOther, formBg }) => {
    const steps = stepSettings.map((item) => {
        return item.title;
    });
    const { state } = wizard;
    const { currentStep } = state;
    const { form } = stepSettings[currentStep];

    return (
        <div className={s.main}>
            <Header
                className={s.header}
                logoClassName={s.logoClassName}
                closeButtonClassName={s.closeButtonClassName}
            />
            <div className={s.content}>
                <div className={s.stepsContainer}>
                    {Step ? (
                        <Step />
                    ) : (
                        <Steps steps={steps} currentStep={currentStep} />
                    )}
                </div>
                <div className={classNames(s.formContainerBg, formBg)}>
                    <div className={s.formContainer}>
                        {form(wizard, isOther)}
                    </div>
                    <footer className={s.footer}></footer>
                </div>
            </div>
        </div>
    );
};

export default StepConnection;
