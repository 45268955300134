import { AuthApi } from 'api';

class CustomerApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}customer${path}`;
    }

    info() {
        return this.get('/info');
    }

    edit(body: object) {
        return this.put('', body);
    }

    deleteCustomer(body: object) {
        return this.post('/delete', body);
    }

    addEvent(body: object) {
        return this.post('/event/add', body);
    }

    removeEvent(body: object) {
        return this.post('/event/remove', body);
    }

    getEvent(body: object) {
        return this.post('/event/get', body);
    }

    calendarUpdate() {
        return this.get('/calendar/update');
    }

    statistics() {
        return this.get('/statistics');
    }

    simple() {
        return this.get('/simple');
    }

    chargeSettings(body: object) {
        return this.put('/chargeSettings', body);
    }

    automatedCharging() {
        return this.get('/smart-charging');
    }

    setAutomatedCharging(body: object) {
        return this.put('/smart-charging', body);
    }
}

const customerApi = new CustomerApi();
export default customerApi;
