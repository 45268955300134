import React from 'react';
import { Root } from './Root.jsx';
import { render } from 'react-dom';
import { unmountComponentAtNode } from 'react-dom';
const wrapper = document.body.appendChild(document.createElement('div'));

const Modal = {
    hide: () => {
        unmountComponentAtNode(wrapper);
    },
    confirm: (props) => {
        return new Promise((resolve, reject) => {
            render(
                <Root
                    {...props}
                    hide={Modal.hide}
                    resolve={resolve}
                    reject={reject}
                />,
                wrapper
            );
        });
    },
    create: (Component, props) => {
        return render(<Component hide={Modal.hide} {...props} />, wrapper);
    }
};

export default Modal;
