import React, { memo, useState, useRef, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import {
    CurrentPosition,
    Autocomplete,
    CurrentPositionButton,
    Accuracy,
    HomeMark,
    WorkMark,
    StationList,
    EventsList,
    InformPopup,
    ChargePointList
} from './components';
import { Geolocation } from '@capacitor/geolocation';

const containerStyle = {
    width: '100%',
    height: '100%'
};

let autocompleteInput;

const MyGoogleMap = ({
    homeAddress,
    workAddress,
    stations,
    centerCoordinates,
    events,
    chargePoint,
    onGetChargePoints
}) => {
    const isMounted = useRef(false);
    const isMountedMap = useRef(false);

    const [center, setCenter] = useState({
        lat: 51.5073509,
        lng: -0.1277583
    });

    const [info, setInfo] = useState(null);

    useEffect(() => {
        if (centerCoordinates) {
            setCenter(centerCoordinates);
        }
    }, [centerCoordinates]);

    const [currentPosition, setCurrentPosition] = useState(null);

    const [accuracy, setAccuracy] = useState(0);

    const onLoad = (autocomplete) => {
        autocompleteInput = autocomplete;
    };

    const onUnmount = () => {
        isMounted.current = false;
    };

    const overlayOnload = () => {
        isMounted.current = true;
    };

    const onPlaceChanged = () => {
        if (autocompleteInput !== null) {
            const location = autocompleteInput.getPlace();
            if (location.geometry) {
                setCenter({
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng()
                });
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const scrollToCurrentPosition = async () => {
        try {
            const coordinates = await Geolocation.getCurrentPosition();
            const pos = {
                lat: coordinates.coords.latitude,
                lng: coordinates.coords.longitude
            };
            setAccuracy(coordinates.coords.accuracy);

            setCenter(pos);
            setCurrentPosition(pos);
        } catch (error) {
            alert(`Please check is Location allowed`);
        }
    };

    const onUnmountMap = () => {
        isMountedMap.current = false;
    };

    const onLoadMap = async () => {
        isMountedMap.current = true;

        try {
            const coordinates = await Geolocation.getCurrentPosition();
            const pos = {
                lat: coordinates.coords.latitude,
                lng: coordinates.coords.longitude
            };
            setAccuracy(coordinates.coords.accuracy);

            setCenter(pos);
            setCurrentPosition(pos);
        } catch (error) {}
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const eventId = urlParams.get('eventId');
        if (eventId) {
            const selectedEvent =
                events && events.find((item) => item.id === +eventId);
            if (selectedEvent) {
                setCenter({
                    lat: selectedEvent.latitude,
                    lng: selectedEvent.longitude
                });
                setInfo({
                    title: selectedEvent.name,
                    descriptionTitle: '',
                    description: selectedEvent.address,
                    saddr: currentPosition,
                    daddr: {
                        lat: selectedEvent.latitude,
                        lng: selectedEvent.longitude
                    }
                });
            }
        }
    }, [events, currentPosition]);

    useEffect(() => {
        onGetChargePoints(center);
    }, [onGetChargePoints, center]);

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            id="searchbox-example"
            onUnmount={onUnmountMap}
            onLoad={onLoadMap}
            options={{ streetViewControl: false }}
        >
            <CurrentPositionButton onClick={scrollToCurrentPosition} />
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} />

            <CurrentPosition
                position={currentPosition}
                onLoad={overlayOnload}
                onUnmount={onUnmount}
            />

            <HomeMark
                address={homeAddress}
                setInfo={setInfo}
                currentPosition={currentPosition}
            />
            <WorkMark
                address={workAddress}
                setInfo={setInfo}
                currentPosition={currentPosition}
            />

            <StationList
                stations={stations}
                setInfo={setInfo}
                currentPosition={currentPosition}
            />
            <EventsList
                events={events}
                setInfo={setInfo}
                currentPosition={currentPosition}
            />
            <ChargePointList
                chargePoint={chargePoint}
                setInfo={setInfo}
                currentPosition={currentPosition}
            />
            <InformPopup info={info} setInfo={setInfo} />

            {isMountedMap.current && (
                <Accuracy center={currentPosition} accuracy={accuracy} />
            )}
        </GoogleMap>
    );
};

GoogleMap.defaultProps = {
    homeAddress: null,
    workAddress: null,
    stations: [],
    events: []
};

export default memo(MyGoogleMap);
