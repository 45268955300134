import { Api } from 'api';
import { forwardTo } from 'utils';
import { signIn } from 'constants/routes';
import { Auth } from 'aws-amplify';
import IonicStorage from 'storage';

class CognitoAuthApi extends Api {
    async request(path: string, options: object) {
        let bearer = null;
        let jwt;

        try {
            await IonicStorage.putOnLocalStorage();
            const currentSession = await Auth.currentSession();
            const accessToken = currentSession.getAccessToken();
            jwt = accessToken.getJwtToken();
        } catch (error) {
            console.log(error);
        }

        bearer = `Bearer ${jwt}`;

        if (!jwt) {
            localStorage.removeItem('roles');
            await IonicStorage.clear();
            await Auth.signOut();
            forwardTo(signIn);

            throw new Error(
                'Session expired, Please login in again.'
            );
        }
        // const requestHeaders: any = {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': bearer,
        //     'Client-Timezone': `${new Date().getTimezoneOffset()}`
        // };

        const requestHeaders = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': bearer
        });
        if (this.addTimeZone) {
            requestHeaders.append(
                'Client-Timezone',
                `${new Date().getTimezoneOffset()}`
            );
        }

        const response = await fetch(this.makeUrl(path), {
            headers: requestHeaders,
            ...options
        });

        if (response.status === 204) return null;
        if (response.status === 401) {
            localStorage.removeItem('roles');
            await IonicStorage.clear();
            await Auth.signOut();
            forwardTo(signIn);

            throw new Error(
                'Session expired, Please login in again.'
            );
        }
        const json = await response.json();

        if (response.status === 500 && json.error.message === "Bad credentials.") return null;
        return response.ok || response.status < 400
            ? json
            : Promise.reject(json.error || Error(`${response.status}`));
    }
}

export default CognitoAuthApi;
