import React from 'react';
import { Card } from '../'
import s from '../../FirstScreen.module.scss'

const ActiveChargingCard = ({
    value
}) => {
    return (
        <Card
            title="Active charging sessions"
        >
            <div className={s.valueContainer} >
                <div className={s.valueAndMeasure}>
                    <h3 className={s.value} >
                        {value}
                    </h3>
                    <span className={s.measure} >
                        kwh
                </span>
                </div>
            </div>
        </Card>
    );
};

export default ActiveChargingCard;