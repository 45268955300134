import React from 'react';
import locationIcon from './image/locationIcon.png'
import s from './CurrentPositionButton.module.scss'

const CurrentPositionButton = ({
    onClick
}) => {

    return (

        <button type="button" className={s.main} onClick={onClick} >
            <img src={locationIcon} alt="" />
        </button>

    );
};

export default CurrentPositionButton;