/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Message } from 'components';
import { useOutside } from 'hooks';
import s from './Notification.module.scss';
import errorIcon from './image/error.png';
import classNames from 'classnames';
import variables from 'assets/styles/variables.module.scss';
import { observer } from 'mobx-react-lite';
import NotificationStore from './NotificationStore.mobx';
let timer;

const getNotificationTypeSettings = (type, background) => {
    switch (type) {
        case 'ok':
            return {
                title: '',
                bgColor: background || '#11af3c',
                cornerColor: background || '#11af3c',
                textColor: '#ffffff'
            };
        case 'error':
            return {
                title: 'Oops something went wrong!',
                icon: errorIcon,
                bgColor: variables.errorColor,
                cornerColor: variables.errorColor,
                textColor: '#ffffff'
            };

        default:
            return {
                title: '',
                icon: null,
                bgColor: background || variables.secondaryGradient,
                textColor: '#ffffff',
                cornerColor: background || '#1DD6A8'
            };
    }
};

const Notification = () => {
    const modelRef = useRef(null);
    const {
        icon,
        bgColor,
        textColor,
        title,
        cornerColor
    } = getNotificationTypeSettings(
        NotificationStore.type,
        NotificationStore.background
    );

    const closeByTime = (time) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            NotificationStore.hideNotification();
        }, time * 1000);
    };
    useEffect(() => {
        if (NotificationStore.content && NotificationStore.timeToClose) {
            clearTimeout(timer);
            closeByTime(NotificationStore.timeToClose);
        }
    }, [NotificationStore.content]);

    useEffect(() => {
        if (!NotificationStore.timeToClose) {
            clearTimeout(timer);
        }
    }, [NotificationStore.timeToClose]);

    useOutside(modelRef, () => {
        NotificationStore.hideNotification();
    });

    const closeHandle = () => {
        NotificationStore.hideNotification();
        NotificationStore.cb();
    };

    return (
        <div
            ref={modelRef}
            className={classNames(s.main, {
                [s.hidden]: !NotificationStore.content
            })}
        >
            <Message
                cornerPosition="rightCorner"
                className={s.message}
                bgColor={bgColor}
                cornerColor={cornerColor}
            >
                <div className={s.contentContainer}>
                    <div className={s.iconContainer}>
                        {NotificationStore.type === 'error' && (
                            <img src={icon} alt="" className={s.icon} />
                        )}
                    </div>
                    <div className={s.contentWrapper}>
                        <h3 className={s.title}>{title}</h3>
                        <div style={{ color: textColor }} className={s.content}>
                            {NotificationStore.content}
                        </div>
                    </div>
                    <button className={s.button} onClick={closeHandle}>
                        {NotificationStore.okText || 'OK'}
                    </button>
                </div>
            </Message>
        </div>
    );
};

export default observer(Notification);
