import React from 'react';
import s from './PortingChargerModal.module.scss';
import chargers from './images/Chargers.png';
import { useTranslation } from 'react-i18next';

export default function PortingChargerModal({ chargerName }) {
    const { t } = useTranslation('profile');

    return (
        <div className={s.backGround}>
            <div className={s.content}>
                <div className={s.container}>
                    <h4>{t('yourCharger', 'Your Charger')}</h4>
                    <div className={s.card}>
                        <div className={s.grid}>
                            <div className={s.img}>
                                <img src={chargers} alt="" />
                            </div>
                            <div>
                                <p>
                                    {t('portingYourCharger', {
                                        defaultValue:
                                            'Porting your {{chargerName}} charger back to the manufacturer...',
                                        chargerName
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
