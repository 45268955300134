import React from 'react';
import { FormContainer } from 'components';
import wizardSettings from './SignUp.wizardSettings';
import { signIn } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import SignUpStore from './SignUpStore.mobx';
import { observer } from 'mobx-react-lite';
import { siginInAnalytics } from 'pages/SignIn/SignIn.analytics';
import { useHistory, useLocation } from 'react-router';

const SignUpFormContainer = ({ wizard }) => {
    const { state } = wizard;
    const history = useHistory();
    const location = useLocation();
    const emailFromURL = location?.state?.email;
    const { currentStep } = state;
    const { form } = wizardSettings(SignUpStore.data.accountType).wizard[
        currentStep
    ];
    const { t } = useTranslation('signup');

    
    const formHeader = {
        text: t('formHeader.text', 'I have an account'),
        link: {
            to: signIn,
            text: t('formHeader.link', 'Sign in')
        },
        className: "signIn"
    };
    const logOutHeader = {
        link: {
            to: signIn,
            text: t('logOut', 'Log out')
        },
        className: "logOut",
        onClick : () => {
            currentStep === 1 && siginInAnalytics.sendLogoutEvent(emailFromURL);
            currentStep === 2 && siginInAnalytics.sendChangeAddressEvent(emailFromURL);
            history.push(signIn)
        }
    };

    return (
        <FormContainer
            header={state.currentStep === 0 ? formHeader : logOutHeader}
            gradient="linear-gradient(90deg, rgba(76,91,240,1) 0%, rgba(111,92,247,1) 35%, rgba(146,93,254,1) 100%)"
        >
            {form(wizard)}
        </FormContainer>
    );
};

export default observer(SignUpFormContainer);
