import React from 'react';
import s from './RequestLoading.module.scss';
import { Loading, Portal } from 'components';
import { observer } from 'mobx-react-lite';
import RequestLoadingStore from './RequestLoadingStore.mobx';

const modalRoot = document.getElementById('modal-root');

const RequestLoading = () => {
    if (!RequestLoadingStore.isVisible) {
        return null;
    }
    return (
        <Portal modalRoot={modalRoot}>
            <div className={s.main}>
                <Loading />
            </div>
        </Portal>
    );
};
RequestLoading.defaultProps = {
    isVisible: false
};

export default observer(RequestLoading);
