import { Api } from 'api'

class ValidationRulesApi extends Api {
    makeUrl(path) {
        console.log(this.baseUrl);

        return `${this.baseUrl}validation-rules/${path}`;
    }

    getRules(type) {
        return this.get(type)
    }


}

const validationRulesApi = new ValidationRulesApi()


export default validationRulesApi;