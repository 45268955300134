import React, { memo } from 'react';
import s from './Toggle.module.scss';

const Toggle = ({ checked, onChange, name, disable }) => {
    return (
        <label id={name} className={s.switch}>
            <input
                className={s.input}
                type="checkbox"
                onChange={onChange}
                name={name}
                checked={checked}
                disabled={disable}
            />
            <div className={s.slider}>
                <div className={s.toggle}>
                </div>
            </div>
        </label>
    );
};

Toggle.defaultProps = {
    checked: true,
    onChange: () => {},
    name: '',
    disable: ''
};

export default memo(Toggle);
