import React from 'react';
import { Alert } from 'components/atoms';
import s from './WithAlert.module.scss';

export default function WithAlert({ children, error }) {
    return (
        <div className={s.container}>
            {error && (
                <div className={s.alertContainer}>
                    <Alert error={error} />
                </div>
            )}
            {children}
        </div>
    );
}
