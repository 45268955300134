import React from 'react';
import { InitialStepAdvances, InitialStepFormContainer } from './InitialStep';
import {
    PersonalDetailsAdvances,
    PersonalDetailsFormContainer
} from './PersonalDetails';
import { YourPlacesAdvances, YourPlacesFormContainer } from './YourPlaces';
// import i18n from '../../i18n';

const wizardSettings = [
    {
        advances: (steps) => <InitialStepAdvances steps={steps} />,
        form: (wizard) => <InitialStepFormContainer wizard={wizard} />
    },
    {
        // t('steps:signup.personalDetails', 'Personal details')
        advances: (steps) => <PersonalDetailsAdvances steps={steps} />,
        title: 'steps:signup.personalDetails',
        form: (wizard) => <PersonalDetailsFormContainer wizard={wizard} />
    },
    {
        // t('steps:signup.yourPlaces', 'Your places')
        advances: (steps) => <YourPlacesAdvances steps={steps} />,
        title: 'steps:signup.yourPlaces',
        form: (wizard) => <YourPlacesFormContainer wizard={wizard} />
    }
];

const getWizardSettings = () => {
    let steps;
    let wizard;
    wizard = wizardSettings;
    steps = wizard.reduce((filtered, item) => {
        if (item.title) {
            filtered.push(item.title);
        }
        return filtered;
    }, []);

    return {
        steps,
        wizard
    };
};

export default getWizardSettings;
