import React from 'react';
import x from './image/x.png'
import s from './AddMobile.module.scss'

const AddMobile = ({ onClick }) => {
    return (
        <div className={s.add} onClick={onClick}>
            <img src={x} alt="" />
        </div>
    );
};

export default AddMobile;