import React from 'react';
import { useFormik } from 'formik';
import { Input, WarningMessage, Button, Checkbox } from 'components';
import s from './PersonalDetails.module.scss';
import { Link } from 'react-router-dom';
import { privacyPolicy } from 'constants/routes';
import { mixpanel } from 'utils';
import yupSchemaGenerator from 'utils/yupSchemaGenerator';
import { useTranslation } from 'react-i18next';
import SignUpStore from '../SignUpStore.mobx';
import { siginInAnalytics } from 'pages/SignIn/SignIn.analytics';

const PersonalDetailsForm = ({ next }) => {
    const { t } = useTranslation('signup');
    const validation = SignUpStore.formValidation
        ? {
              firstName: SignUpStore.formValidation.firstName,
              lastName: SignUpStore.formValidation.lastName,
              termsAndPolicy: true
          }
        : null;

    const formik = useFormik({
        initialValues: { firstName: '', lastName: '', termsAndPolicy: true },
        onSubmit: (data) => {
            SignUpStore.setData = data;
            mixpanel.track('Signup', { 'Step': 'Personal Details' });
            siginInAnalytics.sendContinueEvent({
                name: data?.firstName,
                surname: data?.lastName
            });
            next();
        },
        validationSchema: yupSchemaGenerator(validation),
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false
    });
    const {
        handleSubmit,
        errors,
        handleChange,
        values,
        handleBlur,
        setErrors
    } = formik;

    const errorsArray = Object.keys(errors);

    return (
        <form onSubmit={handleSubmit}>
            {!!errors[errorsArray[0]] && errorsArray[0] === 'firstName' && (
                <WarningMessage
                    error={errors[errorsArray[0]]}
                    className={s.warning}
                />
            )}
            <Input
                className={s.input}
                type="text"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                errors={errors.firstName}
                placeholder={t('placeholder.name', 'Name*')}
                onBlur={(e) => {
                    handleBlur(e);
                    setErrors({});
                }}
            />
            {!!errors[errorsArray[0]] && errorsArray[0] === 'lastName' && (
                <WarningMessage
                    error={errors[errorsArray[0]]}
                    className={s.warning}
                />
            )}
            <Input
                className={s.input}
                type="text"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                errors={errors.lastName}
                placeholder={t('placeholder.surName', 'Surname*')}
                onBlur={(e) => {
                    handleBlur(e);
                    setErrors({});
                }}
            />
            <div className={s.submitButton}>
                <Button
                    type="submit"
                    disabled={
                        !Object.values(formik.values).every((item) => item)
                    }
                >
                    {t('button.continue', 'Continue')}
                </Button>
            </div>
        </form>
    );
};

export default PersonalDetailsForm;
