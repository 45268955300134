import React from 'react';
import s from './YourPlaces.module.scss';
import YourPlacesForm from './YourPlacesForm';
import { useTranslation } from 'react-i18next';
import back from 'assets/images/back.svg'

const YourPlacesFormContainer = ({ wizard }) => {
    const { previous } = wizard;
    const { t } = useTranslation('signup');

    return (
        <div className={s.main}>
            <button className={s.backButton} onClick={previous}>
                <img src={back} alt='' />
            </button>
            <h2 className={s.contentTitle}>
                {t('setYourPlaces', 'Tell us your charger address')}
            </h2>
            <YourPlacesForm />
        </div>
    );
};

export default YourPlacesFormContainer;
