import { useEffect } from 'react';

export default function useForCypress(ref, cb) {
    useEffect(() => {
        if (ref) {
            const input = ref;
            input._value = input.value;
            Object.defineProperty(input, 'value', {
                get: function () {
                    return this._value;
                },
                set: function (v) {
                    cb(v);
                    this._value = v;
                }
            });
        }
    }, [cb, ref]);
}
