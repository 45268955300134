import React from 'react';
import s from './FormContainer.module.scss'
import { Link } from 'react-router-dom'

const FormContainer = ({ children, header, gradient }) => {
    return (
        <div className={s.SignInFormContainer} style={{ background: gradient }}>
            <header className={`${s.formHeader}  ${s[header.className]}`}>
                <p className={s.headerText}>
                    {header.text}{" "}
                    {Boolean(header.onClick) ? (
                        <a onClick={() => header.onClick()} className={s.headerLink}>
                            {header.link.text}
                        </a>
                    ) : (
                        <Link to={header.link.to} className={s.headerLink}>
                            {header.link.text}
                        </Link>
                    )}
                </p>
            </header>
            {children}
        </div>
    );
};

export default FormContainer;