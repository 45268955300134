import React, { useRef } from 'react';
import s from './InputRange.module.scss';
import { useForCypress } from 'hooks';

const InputRange = ({
    name,
    id,
    value,
    onChange,
    min,
    max,
    disable,
    setFieldValue
}) => {
    const hiddenInput = useRef();

    useForCypress(hiddenInput.current, (value) => {
        setFieldValue(name, value);
    });

    return (
        <div className={s.main}>
            <input
                name={name}
                id={id}
                className={s.input}
                type="range"
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                disabled={disable}
            />
            <input
                ref={hiddenInput}
                type="text"
                className={s.hiddenInput}
                id={`range-${name}`}
                name={name}
            />

            <div className={s.range}>
                <div className={s.rangeSlider}>
                    <div className={s.rangeSliderBackground} />
                    <div
                        className={s.rangeSliderThumb}
                        style={{ width: `${(value * 100) / max}%` }}
                    >
                        <div className={s.thumb} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputRange;
