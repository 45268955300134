import { Api } from 'api'

class SocialAuthApi extends Api {
    makeUrl(path: string) {
        return `${this.baseUrl}social-network/${path}`;
    }

    authGoogle(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('auth/google?' + urlParams)
    }

    authFacebook(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('auth/facebook?' + urlParams)
    }

    authLinkedin(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('auth/linkedin?' + urlParams)
    }

    authApple(code: string, url: string) {
        const urlParams = new URLSearchParams();
        code && urlParams.set("code", code)
        url && urlParams.set("redirect_url", url)
        return this.get('auth/apple?' + urlParams)
    }


}

const socialAuthApi = new SocialAuthApi()
export default socialAuthApi;