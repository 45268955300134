import React from 'react';
import s from './Advantages.module.scss';
import { Logo } from 'components';
import variables from 'assets/styles/variables.module.scss';

const bgStyle = (image, backGroundColor) => ({
    backgroundImage: `linear-gradient(${backGroundColor} 50%, rgba(0,0,0,0)), url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain'
});
const Advantages = ({ children, backGroundColor, image, gradient, logo=true }) => {
    let logo_container;
    if(logo){ logo_container= (<div className={s.logo}><Logo className={s.logoLike} /></div>) }
    return (
        <div className={s.advantages} style={{ background: backGroundColor }}>
            <div
                className={s.backGround}
                style={bgStyle(image, backGroundColor)}
            />
            <div className={s.content}>
                {logo_container}
                {children}
            </div>
        </div>
    );
};
Advantages.defaultProps = {
    children: '',
    backGroundColor: variables.neutralLight,
    image: null,
    gradient: false
};

export default Advantages;
