import React from 'react';
import s from './SupplierCard.module.scss';
import shape from 'assets/images/Shape.png';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const SupplierCard = ({
    icon,
    title,
    supplier,
    name,
    peak,
    offPeak,
    rateWindows,
    rateType,
    peakRate,
    offPeakRate,
    windowStart,
    windowEnd,
    tariffNewName
}) => {
    const { t } = useTranslation('misc');
    console.log(tariffNewName);
    return (
        <div className={s.main}>
            <div className={s.check}>
                <img src={shape} alt="" />
            </div>
            <div className={s.grid}>
                <div className={s.logo}>
                    <p> {t('supplerCard.youAreWith', 'You are with')}</p>
                </div>
                <div className={s.car}>
                    <img src={icon} alt="icon" />
                    <span>{title}</span>
                </div>
                {rateWindows === 2 && peak && !peakRate ? (
                   <div className={s.valueList}>
                        <div className={s.valueTitle}>
                            <p> {t("dayRate")} </p>
                        </div>
                        <div className={s.value}>
                            <p> {peak} p/kWh </p>
                        </div>
                        </div>
                ): peakRate && (
                    <div className={s.valueList}>
                        <div className={s.valueTitle}>
                            <p> { rateType === "dual" ? t("dayRate"): t("singleRate")} </p>
                        </div>
                        <div className={s.value}>
                            <p> {peakRate} p/kWh </p>
                        </div>
                        </div>
                )}
                {offPeak && !offPeakRate && (
                   <div className={s.valueList}>
                        <div className={s.valueTitle}>
                        {rateWindows !== 2 ? <p> {t("singleRate")} </p> : <p> {t("nightRate")}</p>}
                        </div>
                        <div className={s.value}>
                            {rateWindows > 2 ? (
                                <p> {t('supplerCard.variable', 'Variable')} </p>
                            ) : (
                                <p> {offPeak} p/kWh </p>
                            )}
                        </div>
                        </div>
                )} 
                {offPeakRate && (
                    <div className={s.valueList}>
                        <div className={s.valueTitle}>
                            <p>  {t("nightRate")}   </p>
                        </div>
                        <div className={s.value}>
                            <p> {offPeakRate} p/kWh </p>
                        </div>
                        </div>
                )}
                {(windowStart || windowEnd) && (
                    <div className={s.valueList}>
                        <div className={s.valueTitle}>
                            <p> {t("nightWindows")} </p>
                        </div>
                        <div className={s.value}>
                            <p> {windowStart} to {windowEnd}  </p>
                        </div>
                        </div>
                )}
            </div>
        </div>
    );
};

export default SupplierCard;
