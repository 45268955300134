import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { useEffect, useState } from 'react';

export default function useKeyboardListener () {
    const [isKeyboardShown, setIsKeyboardShown] = useState(false);

    const addKeyboardListener = async () => {
        const deviceInfo = await Device.getInfo();
        if (
            deviceInfo.platform === 'ios' ||
            deviceInfo.platform === 'android'
        ) {
            Keyboard.addListener('keyboardWillShow', () =>
                setIsKeyboardShown(true)
            );
            Keyboard.addListener('keyboardWillHide', () =>
                setIsKeyboardShown(false)
            );
        }
    };
    useEffect(() => {
        addKeyboardListener();
    }, []);

    return {
        isKeyboardShown
    };
};
