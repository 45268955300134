import rules from 'roles/rules';
import { useLocalStorage } from 'hooks';
import { useRef, useMemo } from 'react';

export default function useRole() {



    const { getItem } = useLocalStorage('roles');
    const roles = getItem('parse');

    const permissions = useMemo(() => {
        let permissionArray = [];

        if (!roles) return permissionArray;

        roles.forEach((role) => {
            const rolePermission = rules[role];
            if (rolePermission?.static) {
                permissionArray = [
                    ...permissionArray,
                    ...rolePermission.static
                ];
            }
        });
        return permissionArray;
    }, [roles]);

    const can = useRef((action, enable = true, disable = false) => {
        if (permissions.includes(action)) {
            return enable;
        }
        return disable ?? null;
    });

    return { can: can.current };
}
