export const SOLAR_MODE_OPTIONS = {
    0: 'FAST',
    1: 'ECO',
    2: 'ECO+'
};

export const WIRING_OPTIONS = {
    0: 'CT 2000',
    1: 'CT 3000',
    2: 'Electric meter'
};

export const ANALYTICS_KEY_SOLAR_STATE = {
    '0': 'SolarFast',
    '1': 'SolarEco',
    '2': 'SolarEcoPlus'
};

export const ANALYTICS_KEY_CT_CLAMP = {
    '0': 'WiringCT',
    '1': 'WiringMeter'
};
export const ANALYTICS_KEY_SOLAR = {
    'true': 'SolarEnabled',
    'false': 'SolarDisabled'
};
