import React from 'react';
import s from './ActionContainer.module.scss';

const ActionContainer = ({ children }) => {
    return (
        <div className={s.main}>
            <div className={s.iconContainer}>
                <div className={s.round} />
                <div className={s.round} />
                <div className={s.round} />
            </div>
            <div className={s.content}>
                <div className={s.childrenContainer}>{children}</div>
            </div>
        </div>
    );
};

export default ActionContainer;
