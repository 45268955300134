import React from 'react';
import { Select, Option } from 'components';
import { Card } from '../';
import s from '../../FirstScreen.module.scss';
import cherDown from '../../image/cherDown.png';
import { supportEuro } from 'constants/treatments';
import { useTreatments } from 'hooks';
import { useTranslation } from 'react-i18next';

const TotalCharging = ({ value }) => {
    const [showSupportEuro] = useTreatments([supportEuro]);
    const {t} = useTranslation('misc')

    return (
        <Card
            title="Total Charging Spend"
            rightTitle={
                <div>
                    <Select
                        id="time"
                        options={{
                            selectedContainer: s.selectedContainer,
                            cherDownIcon: cherDown,
                            activeSelectedContainer: s.activeSelectedContainer,
                            childrenContainer: s.childrenContainer
                        }}
                        initialValue={'today'}
                    >
                        <Option value="today" className={s.option}>
                        {t("chargerSession.today", "Today")}      
                        </Option>
                        <Option value="month" className={s.option}>
                        {t("chargerSession.month", "Month")}      
                        </Option>
                    </Select>
                </div>
            }
        >
            <div className={s.valueContainerWithFooter}>
                <div className={s.valueAndMeasure}>
                    <span className={s.measure}>
                        {showSupportEuro ? '€' : '£'}
                    </span>
                    <h3 className={s.value}>{value}</h3>
                </div>
                <div className={s.increase}>
                    <span className={s.arrow}>➞</span>
                    <span className={s.increaseValue}>88.73% Increase</span>
                </div>
            </div>
        </Card>
    );
};

export default TotalCharging;
