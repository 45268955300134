import React from 'react';
import { Checkbox, Information } from 'components'
import s from './CheckboxWithLabel.module.scss'
import classNames from 'classnames'
import infoIcon from 'assets/images/info.png'

const CheckboxWithLabel = ({ id, onChange, checked, title, name, disable, className, info, position }) => {
    return (
        <label htmlFor={id} className={classNames(s.main, className, { [s.disable]: disable })} >
            <div className={s.checkBoxContainer} >
                <Checkbox id={id} onChange={onChange} disable={disable} checked={checked} type="radio" name={name} />
            </div>
            <div>
                <span className={classNames(s.titleText, { [s.disableTitleText]: disable })}>
                    {title}
                </span>
            </div>
            <div className={s.infoContainer}>
                {info && <Information
                    icon={infoIcon}
                    initialPosition={position}
                >
                    {info}
                </Information>}
            </div>
        </label >
    );
};

CheckboxWithLabel.defaultProps = {
    disable: false
};

export default CheckboxWithLabel;