import React from "react";
import { MarkerClusterer, Marker } from "@react-google-maps/api";
import stationMarker from "./images/stationMarker.png";

const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

function index({ stations, setInfo, currentPosition }) {
  const clickHandler = (address) => {
    setInfo({
      title: address.name,
      descriptionTitle: address.charge_box_identity,
      description: address.address,
      saddr: currentPosition,
      daddr: {
        lat: address.latitude,
        lng: address.longitude,
      },
    });
  };

  return (
    <MarkerClusterer options={options} ignoreHidden={true}>
      {(clusterer) =>
        stations.map((item) => {
          return item.latitude && item.longitude ? (
            <Marker
              key={item.id}
              position={{
                lat: item.latitude,
                lng: item.longitude,
              }}
              clusterer={clusterer}
              icon={stationMarker}
              animation={window.google.maps.Animation.DROP}
              onClick={() => clickHandler(item)}
            />
          ) : null;
        })
      }
    </MarkerClusterer>
  );
}

export default index;
