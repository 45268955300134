import { makeAutoObservable } from 'mobx';
import { RequestLoadingStore, NotificationStore } from 'components';
import { chargePointsApi } from 'api';
import { myHistory } from 'utils';
import { homeSettings } from 'constants/routes';
import { apiFailed, screenVisited, solarChanged } from './Solar.analytics';

class Solar {
    solarData = {
        sampleWiringValue: "0"
    };
    constructor() {
        makeAutoObservable(this);
    }
    getSolar = async (chargeBoxIdentity) => {
        this.solarData = {};
        RequestLoadingStore.showLoading();
        try {
            screenVisited();
            const response = await chargePointsApi.getSolar(chargeBoxIdentity);
            this.solarData = JSON.parse(JSON.stringify(response.data));
        } catch (error) {
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };

    submitSolar = async (payload) => {
        RequestLoadingStore.showLoading();
        try {
            await chargePointsApi.postSolar(payload);
            solarChanged(payload);
            NotificationStore.showNotification({
                content:
                    'You’ve succesfully saved your solar preferences. Your charger will now reboot.',
                timeToClose: 4000,
                cb: () => {
                    myHistory.history.push({
                        pathname: homeSettings
                    });
                }
            });
             myHistory.history.push({
                 pathname: homeSettings
             });
        } catch (error) {
            apiFailed();
            NotificationStore.showErrorNotification(error);
        } finally {
            RequestLoadingStore.hideLoading();
        }
    };
}
export default new Solar();
