import { makeAutoObservable } from 'mobx';
import { Auth } from 'aws-amplify';

class SplitWrapperStore {
    isUserLoaded = false;
    currentUserName = null;

    constructor() {
        makeAutoObservable(this);
    }

    getUser = async () => {
        const currentUserInfo = await Auth.currentUserInfo();
        this.currentUserName = currentUserInfo?.username;
        this.isUserLoaded = true;
    };
}

export default new SplitWrapperStore();
