import React, { useEffect, useState } from 'react';
import s from './AppVersion.module.scss';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { envObject } from 'api/envSwithcer';

const AppVersion = () => {
    const [appInfo, setAppInfo] = useState({});
    const [isWeb, setIsWeb] = useState();

    useEffect(() => {
        Device.getInfo().then((info) => {
            setIsWeb(info.platform === 'web');
            if (info.platform !== 'web') {
                App.getInfo().then((info) => {
                    setAppInfo(info);
                });
            }
        });
    }, []);

    return (
        <div className={s.navLink}>
            <div className={s.indicator} />
            <div className={s.linkContent}>
                {/* <div className={s.linkIcon}></div> */}
                <span className={s.linkTitle}>
                    {isWeb
                        ? `v ${envObject.react_app_version}`
                        : `v ${appInfo.version} (${appInfo.build})`}
                </span>
            </div>
        </div>
    );
};

export default AppVersion;
