/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import s from './TimerComponent.module.scss';
import { HighchartsCircleProgress } from 'components';
import { useTranslation } from 'react-i18next';
import variables from 'assets/styles/variables.module.scss';
import { isPlatform } from '@ionic/react';
import { chargerAnalytics } from 'pages/AddCharger/AddCharger.analytcs';

export default function TimerComponent({
    serialNumber,
    delay,
    callBack,
    loading,
    errorMessage,
    stopCount,
    isConnected
}) {
    const timer = useRef();
    const [start, setStart] = useState(0);
    const [time, setTime] = useState(0);
    const { t } = useTranslation('add-charger');

    const startTimer = () => {
        setStart(Date.now());
    };

    useEffect(() => {
        if (isConnected) {
            clearInterval(timer.current);
        }
    }, [isConnected]);

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    useEffect(() => {
        if (!loading) {
            startTimer();
        }
    }, [loading]);

    useEffect(() => {
        if (loading && timer.current) {
            clearInterval(timer.current);
            setStart(0);
            setTime(0);
        }

        if (stopCount) {
            clearInterval(timer.current);
            setStart(0);
            setTime(0);
        }
    }, [timer.current, loading, stopCount]);

    useEffect(() => {
        if (start) {
            timer.current = setInterval(() => {
                setTime(Date.now() - start);
            }, 1000);
        }
    }, [start]);

    const currenSec = Math.floor(time / 1000);

    useEffect(() => {
        if (delay - currenSec <= 0) {
            clearInterval(timer.current);
            callBack();
        }
    }, [time]);

    let content;
    if (isConnected) {
        content = (
            <>
                <p className={s.successText}>
                    {t('hurrayYouAreConnected', 'Hurray you are connected')}
                </p>
                <p className={s.text}>
                    {t('youCanContinue', 'You can continue')}
                </p>
            </>
        );
    } else {
        if (loading) {
            content = (
                <>
                    <p className={s.text}>
                        {t('tryingToConnect', 'Trying to connect your charger')}
                    </p>
                    <div className={s.circleContainer}>
                        <div className={s.circle}>
                            <div className={s.loader} />
                        </div>
                    </div>
                </>
            );
        }

        if (!stopCount && !loading) {
            content = (
                <>
                    <p className={s.text}>
                        {t(
                            'weAreConnecting',
                            'We are connecting your charger to our server, please wait'
                        )}
                    </p>
                    <p className={s.text}>
                        {t('tryingToConnect', 'Trying to connect your charger')}
                    </p>
                    <div className={s.circleContainer}>
                        <div className={s.circle}>
                            <HighchartsCircleProgress
                                current={delay - currenSec}
                                max={delay}
                                backgroundColor="#EDF3FA"
                                color={variables.secondary}
                                animation={false}
                            >
                                <div className={s.current}>
                                    <span className={s.value}>
                                        {delay - currenSec}
                                    </span>
                                    <span className={s.percent}>s</span>
                                </div>
                            </HighchartsCircleProgress>
                        </div>
                    </div>
                </>
            );
        }

        if (stopCount && !loading) {
            content = (
                <>
                    <h3 className={s.errorTitle}>
                        {t('chargerNotOnline', 'Charger not online')}
                    </h3>
                    <p className={s.errorText}>
                        {t(
                            'pleaseDownloadConnect',
                            'Please, connect your charger to the network before adding it to this account. To do this, you will need to download the Connect Miles app and set the charger to online. "'
                        )}
                    </p>
                    <div className={s.downloadConnectContainer}>
                        <button
                            type="button"
                            onClick={() => redirectionToApp()}
                            className={s.downloadConnect}
                        >
                            Download Connect Miles
                        </button>
                    </div>
                </>
            );
        }
    }

    const redirectionToApp = async () => {
        await chargerAnalytics.sendDownloadConnectMilesAltEvent(serialNumber);
        window.location.href = 'connectmiles://';
        setTimeout(() => {
            if (isPlatform('ios')) {
                window.location.href =
                    'https://apps.apple.com/us/app/connect-miles/id1619083667';
            } else {
                window.location.href =
                    'https://play.google.com/store/apps/details?id=io.electricmiles.connect';
            }
        }, 1500);
    };

    return (
        <div className={s.main}>
            {errorMessage && <p className={s.errorText}>{errorMessage}</p>}
            {content}
            {/* <div className={s.footer}>
                <a
                    href="https://www.electricmiles.io/#contacts"
                    className={s.contactUs}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('contactUs', 'Contact us')}
                </a>
            </div> */}
        </div>
    );
}
