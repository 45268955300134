import { AuthApi } from 'api'

class StationsApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}ev/station/${path}`;
    }


    list(offset: number, limit: number) {
        return this.get(`list?offset=${offset}&limit=${limit}`)
    }

}

const evStationApi = new StationsApi()
export default evStationApi;