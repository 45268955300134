/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import s from './InitialStep.module.scss';
import InitialStepForm from './InitialStepForm';

import { useTranslation } from 'react-i18next';

const InitialStepFormContainer = ({ onSignUp }) => {
    const { t } = useTranslation('signup');

    return (
        <div className={s.main}>
            
            <div className={s.initialFormContainer}>
            <h2 className={s.contentTitle}>{t('title', 'Sign up')}</h2>

                <InitialStepForm onSignUp={onSignUp} />
            </div>
        </div>
    );
};

export default InitialStepFormContainer;
