import React from 'react';
import { CircleChart } from 'components';
import { Card } from '../';
import s from '../../FirstScreen.module.scss';

const ChargePointCard = ({ value, total }) => {
    return (
        <Card
            title="Charge Point by access type"
            rightTitle={<span className={s.titleValue}>({total} total)</span>}
        >
            <div className={s.cardWithChart}>
                <div>
                    {value.map((item, index) => {
                        return (
                            <div key={index} className={s.valueRow}>
                                <span
                                    className={s.valueColor}
                                    style={{ background: item.color }}
                                />
                                <span className={s.valueName}>
                                    {item.name}{' '}
                                </span>
                                <span
                                    className={s.value}
                                >{`${item.y}-${item.interest}%`}</span>
                            </div>
                        );
                    })}
                </div>
                <div className={s.circleWrapper}>
                    <CircleChart data={value} />
                </div>
            </div>
        </Card>
    );
};

export default ChargePointCard;
