import React from 'react';
import wizardSettings from './SignUp.wizardSettings';
import { Steps } from 'components';
import SignUpStore from './SignUpStore.mobx';
import { observer } from 'mobx-react-lite';

const SignUpAdvantages = ({ wizardState }) => {
    const { currentStep } = wizardState;
    const { advances } = wizardSettings(SignUpStore.data.accountType).wizard[
        currentStep
    ];
    const steps = (
        <Steps
            steps={wizardSettings(SignUpStore.data.accountType).steps}
            currentStep={currentStep - 1}
        />
    );

    return advances(steps);
};

export default observer(SignUpAdvantages);
