import React, { useEffect } from 'react';
import { myHistory } from 'utils';
import { signIn } from 'constants/routes';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    useEffect(() => {
        App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            const url = new URL(data.url);
            const urlParams = new URLSearchParams(url.search);
            const page = urlParams.get('page');
            const code = urlParams.get('code');
            const type = urlParams.get('type');

            myHistory.history.push({
                pathname: page || signIn,
                state: { code, type, page },
                search: '?' + urlParams.toString()
            });
        });
    }, []);

    return null;
};

export default AppUrlListener;
