import React from 'react';
import { Oops } from 'components';
import { mixpanel } from "utils";


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        mixpanel.track("Frontend Error", {"Message": errorInfo});
        this.setState({
            errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            return <Oops error={this.state.error} errorInfo={this.state.errorInfo} />;
        }
        return this.props.children;
    }
}