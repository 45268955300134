import React from 'react';
import s from '../SignUp.module.scss';
import { Advantages } from 'components';
import car from 'assets/images/car.png';
import { useTranslation } from 'react-i18next';

const InitialStepAdvances = () => {
    const { t } = useTranslation('signup');

    return (
        <Advantages backGroundColor="rgb(255, 255, 255, 0)" image={car}>
            <h2 className={s.advantagesTitle}>
                {t(
                    'advantagesTitle',
                    'Cheaper, cleaner and smarter charging for EVs'
                )}
            </h2>
            <p className={s.advantagesQuestion}>
                {t('advantagesQuestion', 'Do you have a')}
            </p>
            <ul className={s.list}>
                <li className={s.listItem}>
                    {t('advantages.1', 'Personal EV/plug-in Hybrid? or')}
                </li>
                <li className={s.listItem}>
                    {t('advantages.2', 'A fleet of EVs? or')}
                </li>
                <li className={s.listItem}>
                    {t('advantages.3', 'Return-to-home commercial EV van?')}
                </li>
            </ul>
            <p className={s.advantagesText}>
                {t(
                    'advantagesText',
                    'If so, sign up on Electric Miles to automate your charging schedule, using the cleanest and cheapest energy'
                )}
            </p>
        </Advantages>
    );
};

export default InitialStepAdvances;
