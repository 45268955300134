import React from 'react'
import { Marker } from '@react-google-maps/api'
import workMarker from './images/workMarker.png'


function index({
    address,
    setInfo,
    currentPosition
}) {
    if (!address) return null

  

    const clickHandler = () => {
        setInfo({
            title: address.name,
            descriptionTitle: "",
            description: address.address,
            saddr: currentPosition,
            daddr: {
                lat: address.latitude,
                lng: address.longitude
            }
        })
    }
    return (
        <Marker position={{
            lat: address.latitude,
            lng: address.longitude
        }}
            icon={workMarker}
            animation={window.google.maps.Animation.DROP}
            onClick={clickHandler}
        />
    )
}

export default index
