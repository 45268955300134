import React from 'react';
import s from './Option.module.scss';
import classNames from 'classnames';

const Option = ({
    children,
    value,
    onSelect,
    selectedValue,
    activeItem,
    className,
    onClick
}) => {
    const onClickHandler = () => {
        onSelect(value, children);
        onClick(value);
    };

    return (
        <div
            id={value}
            value={value}
            onClick={onClickHandler}
            className={classNames(
                s.main,
                {
                    [s.selected]: value === selectedValue,
                    [s.activeItem]: activeItem
                },
                className
            )}
        >
            {children}
        </div>
    );
};

Option.defaultProps = {
    onClick: function () {}
};

export default Option;
