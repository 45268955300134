import React, { useState, } from 'react';
import { GoogleMap } from '@react-google-maps/api'
const containerStyle = {
    width: '100%',
    height: '100%'
};

const Map = () => {
    const [map, setMap] = useState(null)

    const [center,] = useState({
        lat: 51.5073509,
        lng: -0.1277583
    })


    const onLoadMap = React.useCallback(function callback(map) {
        setMap(map)

    }, [])


    const idleHandle = () => {
        if (map) {
            const bounds = map.getBounds();
            const southWest = bounds.getSouthWest();
            const northEast = bounds.getNorthEast();
            // eslint-disable-next-line no-unused-vars
            const position = {
                southWest: {
                    lat: southWest.lat(),
                    lng: southWest.lng()
                },
                northEast: {
                    lat: northEast.lat(),
                    lng: northEast.lng()

                }
            }
        }
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            id="searchbox-example"
            onLoad={onLoadMap}
            onIdle={idleHandle}
        >

        </GoogleMap >

    );
};

export default Map;