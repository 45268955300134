import { AuthApi } from 'api'

class AuthSecurityApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}security/${path}`;
    }
    passwordChange(body: object) {
        return this.post('password/change', body)
    }

}

const authSecurityApi = new AuthSecurityApi()
export default authSecurityApi;