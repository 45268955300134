import React from 'react';
import s from './SearchInput.module.scss'
import searchIcon from './image/search.png'

const SearchInput = ({
    placeholder
}) => {
    return (
        <div className={s.autocomplete}>
            <label className={s.inputContainer} htmlFor='search'>
                <input
                    type="text"
                    placeholder={placeholder}
                    className={s.autocompleteInput}
                    id="search"
                />
                <img src={searchIcon} alt="" />
            </label>
        </div>
    );
};

export default SearchInput;