import { useCallback } from 'react';
import IonicStorage from 'storage';

export default function useLocalStorage(name) {
    const setItem = useCallback(
        (data) => {
            IonicStorage.setItem(name, data);
            localStorage.setItem(name, data);
        },
        [name]
    );

    const getItem = useCallback(
        (isParse) =>{
            IonicStorage.putOnLocalStorage();
            return isParse
                ? JSON.parse(localStorage.getItem(name))
                : localStorage.getItem(name)},
        [name]
    );

    const removeItem = useCallback(() => localStorage.removeItem(name), [name]);
    const clear = useCallback(() => localStorage.clear(), []);

    return {
        getItem,
        setItem,
        removeItem,
        clear
    };
}
