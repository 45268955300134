import icon from './image/Icon.png';
import { useHistory } from 'react-router-dom';
import s from './CloseButton.module.scss';

export default function CloseButton({ onClick }) {
    let history = useHistory();

    const goBack = () => {
        history.goBack();
    };
    return (
        <button className={s.main} type="button" onClick={onClick || goBack}>
            <img className={s.img} src={icon} alt="close" />
        </button>
    );
}
