/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce'

export default function useSearch({
    searchMethod,
    delay,
    selectValue = () => { },
    selectId = () => { },
    shouldSearch,
}) {
    const allowSearch = useRef(true)
    const [input, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const [suggestions, setSuggestions] = useState([])

    const getInputProps = () => ({
        value: input,
        onChange: e => { allowSearch.current = true; setInput(e.target.value) }
    })

    const getSuggestionItemProps = () => ({
        onClick: e => {
            allowSearch.current = false;
            setInput(e.currentTarget.getAttribute("value"))
            selectValue(e.currentTarget.getAttribute("value"))
            selectId(e.currentTarget.id)
            setSuggestions([])
        }
    })

    const debounceSearch = debounce(async () => {
        try {
            setLoading(true)
            setSuggestions([])
            const { data } = await searchMethod(input)
            setSuggestions(data)
            setLoading(false)

        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }, delay || 300)

    useEffect(() => {
        if ((shouldSearch || input) && allowSearch.current) {
            debounceSearch()
        }
    }, [shouldSearch, input])

    return ({
        getInputProps,
        loading,
        suggestions,
        getSuggestionItemProps
    })
}