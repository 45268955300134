import React from 'react';
import { Step } from 'components';
import { useTranslation } from 'react-i18next';

const Steps = ({ steps, currentStep }) => {
    const { t } = useTranslation('steps');

    return (
        <div>
            {steps.map((item, index) => {
                return (
                    <Step
                        key={index}
                        title={t(item)}
                        index={index + 1}
                        isActive={currentStep === index}
                        isFinished={index < currentStep}
                    />
                );
            })}
        </div>
    );
};

Steps.defaultProps = {
    steps: []
};

export default Steps;
