import React from 'react';
import { Circle } from '@react-google-maps/api';
import variables from 'assets/styles/variables.module.scss';

const Accuracy = ({ center, accuracy }) => {
    const options = {
        strokeColor: variables.neutralLight,
        strokeOpacity: 0,
        strokeWeight: 0,
        fillColor: '#2086F1',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: accuracy,
        zIndex: 1
    };

    if (accuracy < 300) {
        return null;
    }
    return <Circle center={center} options={options} />;
};

export default Accuracy;
