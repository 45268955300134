import React from 'react';
import s from './Button.module.scss'
import classNames from 'classnames';

const Button = ({ children, className, lock, ...button }) => {
    return (
        <button {...button} className={classNames(s.button, { [s.lock]: lock }, className)}>
            {children}
        </button>
    );
};

Button.defaultProps = {

};

export default React.memo(Button);

