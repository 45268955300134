import React from 'react';
import s from '../SignUp.module.scss';
import { Advantages } from 'components';
import car from 'assets/images/car.png';
import fleetOperators from 'assets/images/fleetOperators.png';
import signInBackGround from 'assets/images/signInBackGround.png';
import { observer } from 'mobx-react-lite';
import SignUpStore from '../SignUpStore.mobx';

const YourPlacesAdvances = ({ steps }) => {
    switch (SignUpStore.data.accountType) {
        case 1:
            return (
                <Advantages
                    backGroundColor="rgb(255, 255, 255, 0)"
                    image={signInBackGround}
                    logo={false}
                >
                    <div className={s.stepsContainer}>{steps}</div>
                </Advantages>
            );
        case 2:
            return (
                <Advantages
                    backGroundColor="rgb(227,239,252,0)"
                    image={fleetOperators}
                    logo={false}
                >
                    <div className={s.stepsContainer}>{steps}</div>
                </Advantages>
            );

        default:
            return (
                <Advantages backGroundColor="rgb(255, 255, 255,0)" image={car}  logo={false}>
                    <div className={s.stepsContainer}>{steps}</div>
                </Advantages>
            );
    }
};

export default observer(YourPlacesAdvances);
