import React, { forwardRef } from 'react';
import classNames from 'classnames';
import s from './InputNumber.module.scss';
import { IonInput } from '@ionic/react';

function InputNumber(
    { className, onBlur, onFocus, onChange, min, max, ...input },
    ref
) {
    const handleChange = (e) => {
        if (e.target.value === '') {
            return onChange(e);
        }
        if (+e.target.value < min) {
            e.target.value = min;
        }
        if (+e.target.value > max) {
            e.target.value = max;
        }
        onChange(e);
    };

    return (
        <IonInput
            className={classNames(s.input, className)}
            id={input.name}
            {...input}
            onIonBlur={onBlur}
            onIonFocus={onFocus}
            onIonChange={handleChange}
            ref={ref}
            type="number"
            inputmode="decimal"
            pattern="\d*"
        />
    );
}

export default forwardRef(InputNumber);
