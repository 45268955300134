import React from 'react';
import classNames from 'classnames'
import s from './Header.module.scss'
import { Logo, CloseButton, BackButton } from 'components'
const Header = ({ className, logoClassName, closeButtonClassName, backButtonClassName, }) => {
    return (
        <header className={classNames(s.main, className)}>
            {/* Remove logo from the whole application except registration, keeping this commented out incase we need it back */}
            {/* <Logo className={classNames(s.logo, logoClassName)} /> */}
            {/*<CloseButton className={classNames(s.closeButton, closeButtonClassName)} />*/}
        </header>
    )
}

export default Header