import React from 'react';
import 'react-big-calendar/lib/sass/styles.scss';
import './EventsCalendar.scss';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

// import 'moment/locale/uk';
// change lang for calendar
// moment.locale('uk');
// console.log(moment(1316116057189).fromNow());

const localizer = momentLocalizer(moment);

const navigateConst = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

const EventsCalendar = ({
    eventList,
    selectEvent,
    defaultDate,
    onNavigate,
    getNavigate
}) => {
    const getCustomToolbar = (toolbar) => {
        const { onNavigate } = toolbar;
        getNavigate(
            () => onNavigate(navigateConst.NEXT),
            () => onNavigate(navigateConst.PREVIOUS),
            () => onNavigate(navigateConst.TODAY)
        );
        return null;

        // <button onClick={() => onNavigate(navigateConst.NEXT)}>
        //     NEXT
        // </button>
    };

    return (
        <Calendar
            localizer={localizer}
            events={eventList}
            views={['month']}
            defaultView="month"
            defaultDate={defaultDate}
            startAccessor="start"
            endAccessor="end"
            onNavigate={onNavigate}
            onSelectEvent={selectEvent}
            components={{
                toolbar: getCustomToolbar
            }}
            style={
                {
                    // height: '1000px'
                }
            }
            popup={true}
            eventLimit={5}
        />
    );
};

export default EventsCalendar;
