import React, { useState } from 'react';
import s from './Input.module.scss';
import classNames from 'classnames';
import eyeClose from './images/eyeClose.png';
import eyeOpen from './images/eyeOpen.png';
import { IonInput, IonItem, IonLabel } from '@ionic/react';

const Input = ({ className, errors, onChange, onBlur, onFocus, ...input }) => {
    const [isPassword, setIsPassword] = useState(false);

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <div className={classNames(s.main, className)}>
            <label>{input.placeholder}</label>
            <IonInput
                className={classNames(
                    { [s.error]: errors },
                    { [s.password]: input.type === 'password' },
                    s.input
                )}
                id={input.name}
                {...input}
                onIonInput={handleChange}
                onIonBlur={onBlur}
                onIonFocus={onFocus}
                type={isPassword ? 'text' : input.type}
            />
            {input.type === 'password' && (
                <button
                    className={s.eye}
                    onClick={setIsPassword.bind(null, (value) => !value)}
                    type="button"
                >
                    {isPassword ? (
                        <img src={eyeClose} alt={'eye'} />
                    ) : (
                        <img src={eyeOpen} alt={'eye'} />
                    )}
                </button>
            )}
        </div>
    );
};

Input.defaultProps = {
    type: 'text'
};

export default Input;
