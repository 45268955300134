import { envObject } from "./envSwithcer";

class Api {
    baseUrl =  envObject.react_app_api_url;
    addTimeZone = true;

    makeUrl(path: string) {
        return `${this.baseUrl}${path}`;
    }

    async request(path: string, options?: object) {
        const requestHeaders = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        requestHeaders.append(
            'Client-Timezone',
            `${new Date().getTimezoneOffset()}`
        );
        const response = await fetch(this.makeUrl(path), {
            headers: requestHeaders,
            ...options
        });

        if (response.status === 204) return null;
        const json = await response.json();
        return response.ok || response.status < 400
            ? json
            : Promise.reject(json.error || Error(`${response.status}`));
    }

    get(path: string, options?: object) {
        return this.request(path, { method: 'get', ...options });
    }

    post(path: string, body?: object, options?: object) {
        return this.request(path, {
            method: 'post',
            ...options,
            body: JSON.stringify(body)
        });
    }

    put(path: string, body?: object, options?: object) {
        return this.request(path, {
            method: 'put',
            ...options,
            body: JSON.stringify(body)
        });
    }

    delete(path: string, options?: object) {
        return this.request(path, { method: 'delete', ...options });
    }
}
export default Api;
