import React from 'react';
import s from './MobileHeader.module.scss'
import menu from './image/Menu.png'
import logo from 'assets/images/logo.png'
import classNames from 'classnames'

const MobileHeader = ({
    onClick,
    rightButton,
    middleButton,
    gridTemplateColumns,
    classNameLogo
}) => {
    return (
        <div className={s.main} >
            <div className={s.content} style={{ gridTemplateColumns }} >
                <button type="button" className={s.buttonContainer} onClick={onClick} >
                    <img src={menu} alt="" />
                </button>
                <div className={s.middle} >
                    {middleButton || (<div className={classNames(s.logoContainer, classNameLogo)} >
                        <img src={logo} alt="" />
                    </div>)}
                </div>
                <div className={s.right}>
                    {rightButton}
                </div>
            </div>
        </div>
    );
};

export default MobileHeader;