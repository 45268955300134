import React from 'react';
import classNames from 'classnames';
import s from './Message.module.scss';
import { ReactComponent as Corner } from './image/corner.svg';
import variables from 'assets/styles/variables.module.scss';

const Message = ({
    children,
    bgColor,
    cornerPosition,
    className,
    cornerColor
}) => {
    return (
        <div
            className={s.main}
            style={{ '--color': bgColor, '--cornerColor': cornerColor }}
        >
            <div className={classNames(s.content, className)}>{children}</div>
            <div
                className={classNames(
                    s.cornerContainer,
                    { [s.cornerCenter]: cornerPosition === 'center' },
                    {
                        [s.rightNotificationCorner]:
                            cornerPosition === 'rightCorner'
                    },
                    { [s.cornerRight]: cornerPosition === 'right' },
                    { [s.cornerBottom]: cornerPosition === 'bottom' },
                    {
                        [s.cornerBottomRight]: cornerPosition === 'bottom-right'
                    },
                    { [s.cornerBottomLeft]: cornerPosition === 'bottom-left' }
                )}
            >
                <Corner />
            </div>
        </div>
    );
};

Message.defaultProps = {
    cornerPosition: 'center',
    bgColor: variables.neutralLight,
    cornerColor: variables.neutralLight
};

export default Message;
