import s from './Button.module.scss';
import classNames from 'classnames';
export default function Button({
    children,
    className,
    sm,
    md,
    lg,
    outline,
    selected,
    outlineSm,
    white,
    ...props
}) {
    return (
        <button
            {...props}
            className={classNames(
                s.button,
                { [s.outline]: outline },
                { [s.outlineSm]: outlineSm },
                { [s.selected]: selected },
                { [s.white]: white },
                { [s.md]: md },
                { [s.lg]: lg },
                className
            )}
        >
            {children}
        </button>
    );
}
