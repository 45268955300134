import { IonContent, useIonAlert } from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import s from './Solar.module.scss';
import arrowRightGreen from '../../assets/images/arrow-right-green.svg';
import ProfileStore from '../Profile/ProfileStore.mobx';
import SolarMobx from './Solar.mobx';
import { observer } from 'mobx-react-lite';
import { Toggle } from 'components/atoms';
import { Option, Select } from 'components';
import { SOLAR_MODE_OPTIONS, WIRING_OPTIONS } from './Solar.constant';
import infoDiamond from 'assets/images/info-diamond-blue.png';
import { faqClicked } from './Solar.analytics';
import { localeData } from 'moment';

const Solar = () => {
    const { t } = useTranslation('solar');
    const history = useHistory();
    const [present] = useIonAlert();
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [chargerSerialNumber, setchargerSerialNumber] = useState();
    const ELECTRIC_METER = "1";
    const CT_2000 = "0";
    const ONLY_SOLAR =  "2";

    const [localSolarState, setLocalSolarState] = useState();

    const handleSubmit = () => {
        let payload = {
            chargeBoxIdentity: chargerSerialNumber,
            sampleWiring: localSolarState.sampleWiringValue,
            solarMode: localSolarState.solarModeEnabled ? localSolarState.solarModeValue : "0",
            solarModeEnabled: localSolarState.solarModeEnabled || false
        };

        if (localSolarState?.sampleWiringValue === ELECTRIC_METER){
            payload.solarMode = "0";
            payload.solarModeEnabled = false
        }

        SolarMobx.submitSolar(payload);
        SolarMobx.getSolar(chargerSerialNumber);
    };

    const handleGoBack = () => {
        if (saveDisabled) history.goBack();
        else
            present({
                mode: 'ios',
                cssClass: 'restrictedHoursPrompt',
                message:
                    'Are you sure you want to cancel? Changes made to this screen won’t be saved.',
                buttons: [
                    { text: 'No', handler: (d) => { } },
                    { text: 'Yes', handler: (d) => history.goBack() }
                ]
            });
    };

    useEffect(() => {
        if (ProfileStore.stations.length) {
            setchargerSerialNumber(
                ProfileStore.stations[0].charge_box_identity
            );
            SolarMobx.getSolar(ProfileStore.stations[0].charge_box_identity);
        }
    }, [ProfileStore.stations]);


    useEffect(() => {
        setSaveDisabled(true)
        const serverData = {
            sampleWiringValue: SolarMobx?.solarData?.sampleWiringValue,
            sampleWiring: SolarMobx?.solarData?.sampleWiring,
            solarModeValue: SolarMobx?.solarData?.solarModeValue,
            solarMode: SolarMobx?.solarData?.solarMode,
            solarModeEnabled: SolarMobx?.solarData?.solarModeEnabled
        }

        const localData = {
            sampleWiringValue: localSolarState?.sampleWiringValue,
            sampleWiring: localSolarState?.sampleWiring,
            solarModeValue: localSolarState?.solarModeValue,
            solarMode: localSolarState?.solarMode,
            solarModeEnabled: localSolarState?.solarModeEnabled
        }

        if (JSON.stringify(serverData) !== JSON.stringify(localData)) {
            setSaveDisabled(false)
        }
        // disables save button, when solarModeEnables is true but solarmodevalue = 0 (i.e FAST)
        if (localSolarState?.solarModeValue === "0" && localData?.solarModeEnabled) {
            setSaveDisabled(true)
        }
    }, [localSolarState]);


    useEffect(() =>{
        SolarMobx.solarData && setLocalSolarState(JSON.parse(JSON.stringify(SolarMobx?.solarData)))
        }, [SolarMobx?.solarData]);

    const handleSolarToggle = () => setLocalSolarState(prevState => ({ ...prevState, solarModeEnabled: !prevState.solarModeEnabled }))
    const handleSolarMode = (value) => setLocalSolarState(prevState => ({ ...prevState, solarModeValue: value, solarMode: SOLAR_MODE_OPTIONS[value] }))
    const handleWiring = (value) => setLocalSolarState(prevState => ({ ...prevState, sampleWiringValue: value, sampleWiring: WIRING_OPTIONS[value] }))


    useEffect(() => {
        if (localSolarState?.sampleWiringValue === ELECTRIC_METER){
            setLocalSolarState(prevState => ({
                ...prevState,
                solarMode: "FAST",
                solarModeValue: "0",
                solarModeEnabled: false
            }))   
        }
    }, [localSolarState?.sampleWiringValue]);

    return (
        <IonContent>
            <div className={s.container}>
                <p className={s.headingText}>
                    {t('headingText', 'Restricted charging hours')}
                </p>
                <p className={s.description}>
                    <i>
                       {localSolarState?.sampleWiringValue === ELECTRIC_METER ? t('notConnectedToCTClamp') : t('descriptionItalic')}
                    </i>
                </p>
                <a
                    href="https://electricmiles.zendesk.com/hc/en-gb/sections/7971912839185-Solar-Charging"
                    className={s.href}
                    target="_blank"
                    onClick={() => faqClicked()}
                >
                    {t('href', 'Learn more')}
                    <img src={arrowRightGreen} />
                </a>
            </div>

            <div className={classNames([s.container, s.formContianer])}>
                <div className={s.toggleCard}>
                    <h5 className={s.title}>{t('toggleSolarCharging')}</h5>
                    <Toggle checked={Boolean(localSolarState?.solarModeEnabled)} onChange={() => handleSolarToggle()} disable={localSolarState?.sampleWiringValue === ELECTRIC_METER} />
                </div>
                {Boolean(localSolarState?.solarModeEnabled) &&
                    <div className={s.toggleSelect}>
                        <h5 className={s.title}>{t('solarMode')}</h5>
                        <Select initialValue={localSolarState?.solarModeValue} onChange={value => handleSolarMode(value)}>
                            {/* <Option value="0">{t('solarModeOptions.fast')}</Option> */}
                            <Option value="1">{t('solarModeOptions.eco')}</Option>
                            <Option value="2">{t('solarModeOptions.ecoPlus')}</Option>
                        </Select>
                    </div>
                }
                {Boolean(localSolarState?.solarModeValue === ONLY_SOLAR) && 
                    <div className={s.rowClass}>
                        <img src={infoDiamond} width="16" height="16" className={s.infoIconClass} />
                        <p className={s.infoTextStyle}>
                        {t("onlySolar")}
                        </p>
                    </div>
                    }
            </div>

            <div className={s.footer}>
                <button onClick={() => handleGoBack()} className={s.cancel}>
                    {t('Cancel', 'Cancel')}
                </button>
                <button
                    onClick={() => !saveDisabled && handleSubmit()}
                    className={classNames([s.save, saveDisabled && s.disabled])}
                >
                    {t('Save', 'Save')}
                </button>
            </div>
        </IonContent>
    );
};

export default observer(Solar);
