import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../'
import s from '../../FirstScreen.module.scss'

const FaultsCard = ({
    value
}) => {
    const {t} = useTranslation("misc");
    return (
        <Card
            title={t("faults", "Faults & connectivity loss")}
        >
            <h3 className={s.cardValue}>{value}</h3>
        </Card>
    );
};

export default FaultsCard;