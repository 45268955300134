import { IonDatetime } from '@ionic/react';
import classNames from 'classnames';
import { useForCypress } from 'hooks';
import React, { useRef } from 'react';
import s from './DatePicker.module.scss';

function DatePicker({
    placeholder,
    onChange,
    name,
    className,
    disabled,
    value,
    min,
    max
}) {
    const hiddenInput = useRef();

    useForCypress(hiddenInput.current, (value) => {
        onChange(value);
    });

    const timePickerHandle = (e) => {
        onChange(e.detail.value, name);
    };

    return (
        <div className={classNames(s.main, className)}>
            <input
                ref={hiddenInput}
                type="text"
                className={s.hiddenInput}
                id={name}
                name={name}
            />
            <IonDatetime
                displayFormat="DD / MM / YYYY"
                value={value}
                onIonChange={timePickerHandle}
                id="ionicDatePicker"
                placeholder={placeholder}
                disabled={disabled}
                mode="ios"
                min={min}
                max={max}
            ></IonDatetime>
        </div>
    );
}

export default DatePicker;
