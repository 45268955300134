const rules = {
    ROLE_PERSONAL_USER: {
        static: [
            'view:all',
            'add:OneCharger',
            'add:OneSupplier',
            'add:XCar',
            'view:home',
            'view:personalHome',
            'login:automatically'
        ]
    },
    ROLE_FLEET_MANAGER: {
        static: [
            'view:all',
            'add:XCharger',
            'add:XSupplier',
            'add:XCar',
            'view:home',
            'view:fleetDashboard',
            'view:homeStation',
            'login:automatically'
        ]
    },
    ROLE_FLEET_DRIVER: {
        static: ['view:all']
    }
};

export default rules;
