import { analyticsManager } from 'analytics';
import ProfileStoreMobx from 'pages/Profile/ProfileStore.mobx';
import analyticsKey from 'analytics/analytics.keys';
import { cognitoApi } from 'api';

class SignInAnalytics {
    sendSignInEvent = (email: string) => {
        const isAlreadySignIn = localStorage.getItem('firstSignIn');
        analyticsManager.logEvent(analyticsKey.pressSignInButton.eventName, {
            email,
            pageName: analyticsKey.pressSignInButton.pageName,
            signInFirst: isAlreadySignIn ? false : true
        });
        localStorage.setItem('firstSignIn', 'true');
    };

    sendLogoutEvent = (email: string) => {
        analyticsManager.logEvent(analyticsKey.pressLogoutButton.eventName, {
            email: ProfileStoreMobx?.email || email,
            pageName: analyticsKey.pressLogoutButton.pageName
        });
    };

    sendContinueEvent = ({
        name,
        surname
    }: {
        name: string;
        surname: string;
    }) => {
        analyticsManager.logEvent(analyticsKey.pressContinueButton.eventName, {
            name,
            surname,
            pageName: analyticsKey.pressContinueButton.pageName
        });
    };
    sendChangeAddressEvent = (email: string) => {
        analyticsManager.logEvent(analyticsKey.pressChangeAddress.eventName, {
            email: ProfileStoreMobx?.email || email,
            pageName: analyticsKey.pressChangeAddress.pageName
        });
    };
    sendChargerAddressEvent = ({
        postCode,
        address
    }: {
        postCode: string;
        address: string;
    }) => {
        analyticsManager.logEvent(analyticsKey.pressChargerAddress.eventName, {
            postCode,
            address,
            pageName: analyticsKey.pressChargerAddress.pageName
        });
    };
    sendWelcomeEvent = (emailFromURL:string) => {
        analyticsManager.logEvent(analyticsKey.pressWelcome.eventName, {
            email: emailFromURL || ProfileStoreMobx?.email,
            pageName: analyticsKey.pressWelcome.pageName
        });
    };
    sendSignUpEvent = () => {
        analyticsManager.logEvent(analyticsKey.pressSignUp.eventName, {
            pageName: analyticsKey.pressSignUp.pageName
        });
    };
    sendSuccessNotificationEvent = (email: string) => {
        analyticsManager.logEvent(
            analyticsKey.pressSuccessNotification.eventName,
            {
                email: email,
                pageName: analyticsKey.pressSuccessNotification.pageName
            }
        );
    };

    sendSignUpContinueEvent = (email: string) => {
        analyticsManager.logEvent(analyticsKey.pressSignUpContinue.eventName, {
            email: email,
            pageName: analyticsKey.pressSignUpContinue.pageName
        });
    };

    sendFailureAccountExistEvent = (email: string, error: any) => {
        analyticsManager.logEvent(analyticsKey.accountExist.eventName, {
            email: email,
            error,
            pageName: analyticsKey.accountExist.pageName
        });
    };
    sendResendEmailEvent = (email: string) => {
        analyticsManager.logEvent(analyticsKey.resendEmail.eventName, {
            email: email,
            pageName: analyticsKey.resendEmail.pageName
        });
    };
}
export const siginInAnalytics = new SignInAnalytics();
