import { AuthApi } from 'api'

class ChargeApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}ev/charge/${path}`;
    }

    status(body: object) {
        return this.post('status', body)
    }

    start(body: object) {
        return this.post('start', body)
    }

    stop(body: object) {
        return this.post('stop', body)
    }


}

const chargeApi = new ChargeApi()
export default chargeApi;