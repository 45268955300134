import React from 'react';
import s from './Card.module.scss'

const Card = ({
    title,
    children,
    rightTitle
}) => {
    return (
        <div className={s.main}>
            <header>
                <h6>
                    {title}
                </h6>
                <div>
                    {rightTitle}
                </div>
            </header>
            <div className={s.content} >
                {children}
            </div>
        </div>
    );
};

export default Card;