import { AuthApi } from 'api'

class SupplierApi extends AuthApi {
    makeUrl(path: string) {
        return `${this.baseUrl}supplier/${path}`;
    }

    list() {
        return this.get('list')
    }

    meter() {
        return this.get('meter')
    }

    tariff() {
        return this.get('tariff')
    }

    add(body: object) {
        return this.post('add', body)
    }

    addManual(body: object) {
        return this.post('add-manual', body)
    }

    remove() {
        return this.delete(`remove`)
    }

    search(id: string | number, search: string | number) {
        return this.get(`search?supplierId=${id}&search=${search}`)
    }

    searchById(id: string | number) {
        return this.get(`tariff/rate?tariffId=${id}`)
    }

}

const supplierApi = new SupplierApi()
export default supplierApi;