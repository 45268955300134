import { useEffect } from 'react';
import { SplitFactory as SplitFactoryCreateComponent } from '@splitsoftware/splitio-react';
import SplitWrapperStore from './SplitWrapperStore.mobx';
import { observer } from 'mobx-react-lite';
import { envObject } from 'api/envSwithcer';

export default observer(function ({ children }) {
    useEffect(() => {
        SplitWrapperStore.getUser();
    }, []);

    if (!SplitWrapperStore.isUserLoaded) return null;

    return SplitWrapperStore.currentUserName ? (
        <Authorized currentUserName={SplitWrapperStore.currentUserName}>
            {children}
        </Authorized>
    ) : (
        <UnAuthorized>{children}</UnAuthorized>
    );
});

const Authorized = ({ children, currentUserName }) => {
    const configLogin = {
        core: {
            authorizationKey: envObject.react_app_split_api_key,
            key: currentUserName
        },
        startup: {
            readyTimeout: 1.5 // 1.5 sec
        }
    };
    return (
        <SplitFactoryCreateComponent config={configLogin}>
            {children}
        </SplitFactoryCreateComponent>
    );
};
const UnAuthorized = ({ children }) => {
    const configUnLogin = {
        core: {
            authorizationKey: envObject.react_app_split_api_key,
            key: 'notLoggedIn'
        },
        startup: {
            readyTimeout: 1.5
        }
    };

    return (
        <SplitFactoryCreateComponent config={configUnLogin}>
            {children}
        </SplitFactoryCreateComponent>
    );
};
