import {
    connectCar,
    addCharger,
    addSupplier,
    home
} from 'constants/routes';

const signUpSetupSteps = [
    connectCar,
    addCharger,
    addSupplier,
    home
];

export default signUpSetupSteps;
