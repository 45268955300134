import {
    ResetPassword,
    SignIn,
    SignUp,
    Home,
    Redirect,
    Test,
    // ChangeOpinion,
    ChangePassword,
    DeleteAccount,
    AddCharger,
    AuthorizeСar,
    ConnectCar,
    AddSupplier,
    AddTrip,
    PrivacyPolicy,
    NoFleet,
    Registered,
    Welcome,
    OptimiseCharge,
    SocialPage,
    EmailVerification,
    SettingResetPassword,
    Initial,
    WaitingList,
    Confirmation,
    EarlyAccess,
    EarlyAccessSignUp,
    NotFound,
    RestrictedHours,
    Solar
} from 'pages';

import {
    home,
    registered,
    resetPassword,
    signIn,
    signUp,
    test,
    changePassword,
    authCar,
    connectCar,
    addCharger,
    addSupplier,
    root,
    addTrip,
    // changeOpinion,
    deleteAccount,
    privacyPolicy,
    noFleet,
    socialPage,
    welcomePage,
    optimiseChargePage,
    emailVerification,
    settingResetPassword,
    initial,
    waitingList,
    confirmation,
    earlyAccess,
    earlyAccessSignUp,
    notFound,
    restrictedHours,
    solar
} from 'constants/routes';
import { earlyAccess as earlyAccessSplit } from 'constants/treatments';

const routes = [
    {
        path: registered,
        exact: true,
        component: Registered,
        split: ''
    },
    {
        path: confirmation,
        exact: true,
        component: Confirmation,
        split: earlyAccessSplit
    },
    {
        path: earlyAccessSignUp,
        exact: true,
        component: EarlyAccessSignUp,
        split: earlyAccessSplit
    },
    {
        path: earlyAccess,
        exact: true,
        component: EarlyAccess,
        split: earlyAccessSplit
    },
    {
        path: initial,
        exact: true,
        component: Initial,
        split: earlyAccessSplit
    },
    {
        path: waitingList,
        exact: true,
        component: WaitingList,
        split: earlyAccessSplit
    },
    {
        path: connectCar,
        exact: true,
        component: ConnectCar,
        split: ''
    },
    {
        path: addCharger,
        exact: false,
        component: AddCharger,
        split: ''
    },
    {
        path: optimiseChargePage,
        exact: false,
        component: OptimiseCharge,
        split: ''
    },
    {
        path: resetPassword,
        exact: true,
        component: ResetPassword,
        split: ''
    },
    {
        path: restrictedHours,
        exact: true,
        component: RestrictedHours,
        split: ''
    },
    {
        path: solar,
        exact: true,
        component: Solar,
        split: ''
    },
    {
        path: welcomePage,
        exact: false,
        component: Welcome,
        split: ''
    },
    {
        path: emailVerification,
        exact: false,
        component: EmailVerification,
        split: ''
    },
    {
        path: settingResetPassword,
        exact: false,
        component: SettingResetPassword,
        split: ''
    },
    {
        path: signIn,
        exact: true,
        component: SignIn,
        split: ''
    },
    {
        path: signUp,
        exact: true,
        component: SignUp,
        split: ''
    },
    {
        path: home,
        exact: false,
        component: Home,
        split: ''
    },
    {
        path: test,
        exact: true,
        component: Test,
        split: ''
    },
    // {
    //     path: changeOpinion,
    //     exact: true,
    //     component: ChangeOpinion,
    // },
    {
        path: changePassword,
        exact: false,
        component: ChangePassword,
        split: ''
    },
    {
        path: addSupplier,
        exact: false,
        component: AddSupplier,
        split: ''
    },
    {
        path: deleteAccount,
        exact: true,
        component: DeleteAccount,
        split: ''
    },
    {
        path: root,
        exact: true,
        component: Redirect,
        split: ''
    },
    {
        path: authCar,
        exact: false,
        component: AuthorizeСar,
        split: ''
    },
    {
        path: addTrip,
        exact: true,
        component: AddTrip,
        split: ''
    },
    {
        path: privacyPolicy,
        exact: false,
        component: PrivacyPolicy,
        split: ''
    },
    {
        path: socialPage,
        exact: true,
        component: SocialPage,
        split: ''
    },
    {
        path: noFleet,
        exact: false,
        component: NoFleet,
        split: ''
    },
    {
        path: notFound,
        exact: false,
        component: NotFound,
        split: ''
    }
];

export default routes;
