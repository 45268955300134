import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import s from './CircleChart.module.scss';
import variables from 'assets/styles/variables.module.scss';

const CircleChart = ({ data }) => {
    const options = {
        title: null,
        chart: {
            type: 'pie',
            margin: 0,
            height: '100%',
            backgroundColor: variables.background
        },

        plotOptions: {
            pie: {
                slicedOffset: 0,
                size: '100%',
                height: '100%',
                dataLabels: {
                    enabled: false
                }
            }
        },

        series: [
            {
                innerSize: '85%',
                data
            }
        ]
    };

    return (
        <div className={s.main}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={'chart'}
            />
        </div>
    );
};

export default CircleChart;
