import { makeAutoObservable } from 'mobx';

class RequestLoadingStore {
    isVisible = false;

    constructor() {
        makeAutoObservable(this);
    }

    showLoading = () => {
        this.isVisible = true;
    };

    hideLoading = () => {
        this.isVisible = false;
    };
}

export default new RequestLoadingStore();
