import { AuthApi } from 'api';

class ChargePointsApi extends AuthApi {
    makeUrl(path) {
        return `${this.baseUrl}chargepoints${path}`;
    }

    getCharger({
        connectorType,
        country,
        deviceId,
        dist,
        id,
        lat,
        limit,
        long,
        postcode,
        postTown,
        ratedOutputKw,
        units
    }) {
        const urlParams = new URLSearchParams();
        connectorType && urlParams.set('connector-type', connectorType);
        country && urlParams.set('country', country);
        deviceId && urlParams.set('device-id', deviceId);
        dist && urlParams.set('dist', dist);
        id && urlParams.set('id', id);
        lat && urlParams.set('lat', lat);
        limit && urlParams.set('limit', limit);
        long && urlParams.set('long', long);
        postcode && urlParams.set('postcode', postcode);
        postTown && urlParams.set('post-town', postTown);
        ratedOutputKw && urlParams.set('rated-output-kw', ratedOutputKw);
        units && urlParams.set('units', units);

        return this.get('?' + urlParams);
    }

    getChargePointMe() {
        return this.get('/me');
    }
    getRestrictedHours(chargerSerialNumber) {
        return this.get(`/restricted-hours?chargeBoxIdentity=${chargerSerialNumber}`);
    }
    postRestrictedHours(payload){
        return this.post('/restricted-hours', payload)
    }
    getSolar(chargerSerialNumber) {
        return this.get(`/solar-configuration?chargeBoxIdentity=${chargerSerialNumber}`);
    }
    postSolar(payload){
        return this.put('/solar-configuration', payload);
    }
}

const chargePointsApi = new ChargePointsApi();
export default chargePointsApi;
